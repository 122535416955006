import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { Service } from '../../types';
import { useServices } from '../../hooks/useServices';
import { Close12 } from '../Icon';
import Button from '../Button';
import Switch from '../Switch';

interface AddServiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  serviceToEdit?: Service | null;
  onServiceCreated?: (newService: Service) => void;
}

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
  padding: 40px;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
  margin-bottom: 12px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input, select {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 4px;
`;

const ButtonFooter = styled.div`
  display: flex;
  position: fixed;
  margin-top: 20px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F8F7F6;
  border-radius: 0 0 8px 8px;
  gap: 10px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const AddServiceDrawer: React.FC<AddServiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  serviceToEdit,
  onServiceCreated,
}) => {
  const { createService, updateService, deleteService } = useServices();
  const [service, setService] = useState<Omit<Service, 'id'>>({
    name: '',
    description: '',
    price: 0,
    taxable: false,
    created_at: new Date().toISOString(),
  });
  const [error, setError] = useState<string | null>(null);

  const drawerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setError(null); // Clear any errors when closing
    setService({ // Reset form
      name: '',
      description: '',
      price: 0,
      taxable: false,
      created_at: new Date().toISOString(),
    });
    handleOpenChange(false);
  }, [handleOpenChange]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);

      if (!isClickInsideDrawer) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleOpenChange]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (serviceToEdit) {
      setService(serviceToEdit);
    } else {
      setService({
        name: '',
        description: '',
        price: 0,
        taxable: false,
        created_at: new Date().toISOString(),
      });
    }
  }, [serviceToEdit]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    // Validate required fields
    if (!service.name.trim()) {
      setError('Service name is required');
      return;
    }
    
    if (service.price < 0) {
      setError('Price cannot be negative');
      return;
    }

    if (serviceToEdit) {
      updateService.mutate({ ...service, id: serviceToEdit.id }, {
        onSuccess: () => {
          handleClose(); // Use handleClose instead of setIsOpen
        },
        onError: (error) => {
          setError(error.message);
        },
      });
    } else {
      createService.mutate(service, {
        onSuccess: (newService) => {
          setService({ name: '', description: '', price: 0, taxable: false, created_at: new Date().toISOString() });
          setIsOpen(false);
          if (onServiceCreated) {
            onServiceCreated(newService);
          }
        },
        onError: (error) => {
          setError(error.message);
        },
      });
    }
  };

  const handleDelete = () => {
    if (serviceToEdit && window.confirm("Are you sure you want to delete this service?")) {
      setError(null);
      deleteService.mutate(serviceToEdit.id, {
        onSuccess: () => {
          setIsOpen(false);
        },
        onError: (error) => {
          setError(error.message);
        },
      });
    }
  };

  const serviceNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      // Use a short timeout to ensure the drawer is fully opened before focusing
      const timeoutId = setTimeout(() => {
        serviceNameInputRef.current?.focus();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction={isMobile ? "bottom" : "right"}
      modal={true}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <DrawerTitle>{serviceToEdit ? "Edit Service" : "New Service"}</DrawerTitle>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <InputFieldsContainer>
              <InputField>
                <label htmlFor="serviceName">Service Name</label>
                <input
                  id="serviceName"
                  type="text"
                  value={service.name}
                  onChange={(e) => setService({ ...service, name: e.target.value })}
                  placeholder="Service Name"
                  required
                  ref={serviceNameInputRef}
                />
              </InputField>
              <InputField>
                <label htmlFor="description">Description</label>
                <input
                  id="description"
                  type="text"
                  value={service.description}
                  onChange={(e) => setService({ ...service, description: e.target.value })}
                  placeholder="Description"
                />
              </InputField>
              <InputField>
                <label htmlFor="price">Price</label>
                <input
                  id="price"
                  type="number"
                  value={service.price}
                  onChange={(e) => setService({ ...service, price: parseFloat(e.target.value) })}
                  placeholder="Price"
                  required
                />
              </InputField>
              <InputField>
                <label>Taxable</label>
                <ToggleWrapper>
                  <Switch
                    checked={service.taxable}
                    onChange={(checked) => setService({ ...service, taxable: checked })}
                    label={service.taxable ? "Taxable" : "Non-taxable"}
                    labelPosition="right"
                  />
                </ToggleWrapper>
              </InputField>
            </InputFieldsContainer>
            <ButtonFooter>
              <Button onClick={handleSubmit}>{serviceToEdit ? "Update Service" : "Create Service"}</Button>
              {serviceToEdit && (
                <Button onClick={handleDelete}>Delete Service</Button>
              )}
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </ButtonFooter>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default AddServiceDrawer;
