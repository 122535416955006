import React from "react";
import styled from "styled-components";
import InvoiceItems from "./components/Invoices/InvoiceItems";
import InvoiceTotals from "./components/Invoices/InvoiceTotals";
import { InvoiceData, Service, InvoiceItem } from "./types";

interface InvoicePreviewProps {
  font: string;
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  bodyTextColor: string;
  invoice: InvoiceData;
  services: Service[];
  handleServiceSelect: (service: Service, itemId: string) => void;
  handleDescriptionChange: (value: string, itemId: string) => void;
  updateItem: (itemId: string, field: keyof InvoiceItem, value: string | number | boolean) => void;
  deleteItem: (itemId: string) => void;
  onCreateNewService: () => void;
}

const InvoicePreviewWrapper = styled.div<{ $font: string; $backgroundColor: string; $bodyTextColor: string }>`
  font-family: ${props => props.$font}, sans-serif;
  background-color: ${props => props.$backgroundColor};
  color: ${props => props.$bodyTextColor};
  padding: 48px 64px;
`;

const InvoicePreview: React.FC<InvoicePreviewProps> = ({
  font,
  backgroundColor,
  bodyTextColor,
  invoice,
  services,
  handleServiceSelect,
  handleDescriptionChange,
  updateItem,
  deleteItem,
  onCreateNewService,
}) => {
  return (
    <InvoicePreviewWrapper $font={font} $backgroundColor={backgroundColor} $bodyTextColor={bodyTextColor}>
      <InvoiceItems
        invoice={invoice}
        services={services}
        handleServiceSelect={handleServiceSelect}
        handleDescriptionChange={handleDescriptionChange}
        updateItem={updateItem}
        deleteItem={deleteItem}
        onCreateNewService={onCreateNewService}
        backgroundColor={backgroundColor}
      />
      <InvoiceTotals invoice={invoice} bodyTextColor={bodyTextColor} />
    </InvoicePreviewWrapper>
  );
};

export default InvoicePreview;