import { useQuery } from "@tanstack/react-query";
import { supabase } from "../supabaseClient";

export const useLogoUrl = (organizationId: string | undefined) => {
  return useQuery({
    queryKey: ['logoUrl', organizationId],
    queryFn: async () => {
      if (!organizationId) return null;

      const { data, error } = await supabase
        .from('organizations')
        .select('logo_url')
        .eq('id', organizationId)
        .single();

      if (error) {
        console.error('Error fetching logo URL:', error);
        throw error;
      }

      return data?.logo_url || null;
    },
    enabled: !!organizationId,
  });
};