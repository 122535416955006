import React from 'react';
import EntityPicker from '../../EntityPicker';
import { More12 } from '../../Icon';
import { TimeTrackerStyle } from '../../../types';
import styled from 'styled-components';

const StyledEntityPicker = styled(EntityPicker)`
`;

interface LayoutPickerProps {
  selectedLayout: TimeTrackerStyle;
  onLayoutChange: (layout: TimeTrackerStyle) => void;
  dark?: boolean;
}

// Update the layouts array to match the TimeTrackerStyle type
const layouts: { id: TimeTrackerStyle; name: string }[] = [
  { id: 'default', name: 'Default' },
  { id: 'modern', name: 'Basic' },  // Changed from 'Stripes' to 'Basic' to match time entry layout
  { id: 'sunrise', name: 'Sunrise' },
  { id: 'evening', name: 'Evening' },
  { id: 'retro', name: 'Retro' },
];

const LayoutPicker: React.FC<LayoutPickerProps> = ({ selectedLayout, onLayoutChange, dark }) => {
  return (
    <StyledEntityPicker
      selectedId={selectedLayout}
      onChange={(id) => onLayoutChange(id as TimeTrackerStyle)}
      entities={layouts}
      label="Layout"
      icon={<More12 />}
      placement="top-end"
      iconOnly
      enableSearch={false}
      dark={dark}
    />
  );
};

export default LayoutPicker;
