import { useMutation } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';
import { useNavigate } from '@tanstack/react-router';

interface AIInvoiceResponse {
  client?: {
    id: string;
    name: string;
  };
  items: {
    description: string;
    quantity: number;
    price: number;
    taxable: boolean;
  }[];
  dueDate?: string;
  notes?: string;
  currency: string;
  taxRate: number;
  template: string;
}

export const useAIInvoiceMutation = () => {
  const { data: organizationId } = useOrganization();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (prompt: string) => {
      if (!organizationId) {
        throw new Error('No organization ID found');
      }

      // Get brand settings first
      const { data: brandSettings } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();

      console.log('Brand settings template:', brandSettings?.default_template);

      // Call the AI service to generate invoice structure
      const response = await fetch('/api/ai/generate-invoice', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({ prompt, organizationId })
      });

      if (!response.ok) {
        const errorText = await response.text();
        let errorMessage;
        try {
          const errorJson = JSON.parse(errorText);
          errorMessage = errorJson.message || errorJson.error || 'Failed to generate invoice structure';
        } catch {
          errorMessage = errorText || 'Failed to generate invoice structure';
        }
        throw new Error(errorMessage);
      }

      const aiResponse: AIInvoiceResponse = await response.json();
      console.log('AI response template:', aiResponse.template);

      // Validate AI response
      if (!Array.isArray(aiResponse.items) || aiResponse.items.length === 0) {
        console.error('Invalid AI response:', aiResponse);
        throw new Error('Invalid AI response structure - no items found');
      }

      // Calculate totals including tax
      const subtotal = aiResponse.items.reduce((sum, item) => sum + (item.quantity * item.price), 0);
      const taxableAmount = aiResponse.items
        .filter(item => item.taxable)
        .reduce((sum, item) => sum + (item.quantity * item.price), 0);
      const taxAmount = (taxableAmount * (aiResponse.taxRate || 0)) / 100;
      const total = subtotal + taxAmount;
      
      const now = new Date();
      const dueDate = aiResponse.dueDate ? new Date(aiResponse.dueDate) : new Date(now.getTime() + (brandSettings?.default_due_days || 30) * 24 * 60 * 60 * 1000);

      // Create invoice with all required fields
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .insert([{
          client_id: aiResponse.client?.id,
          organization_id: organizationId,
          subtotal: subtotal,
          due_date: dueDate.toISOString(),
          invoice_date: now.toISOString(),
          invoice_created_at: now.toISOString(),
          amount_due: total,
          total: total,
          tax_rate: aiResponse.taxRate || brandSettings?.default_tax_rate || 0,
          status: 'draft',
          notes: aiResponse.notes || '',
          currency: aiResponse.currency || brandSettings?.default_currency || 'USD',
          // Use the template from brand settings directly, fallback to AI response, then to 'simple'
          invoice_template: brandSettings?.default_template || aiResponse.template || 'simple',
          header_color: brandSettings?.default_header_color || '#ffffff',
          header_text_color: brandSettings?.default_header_text_color || '#000000',
          background_color: brandSettings?.default_background_color || '#ffffff',
          body_text_color: brandSettings?.default_body_text_color || '#000000',
          font: brandSettings?.default_font || 'Inter',
          due_days: brandSettings?.default_due_days || 30,
          show_logo: brandSettings?.show_logo ?? true,
          collect_stripe_payments: brandSettings?.collect_stripe_payments ?? false
        }])
        .select()
        .single();

      console.log('Created invoice template:', invoiceData?.invoice_template);

      if (invoiceError) {
        console.error('Invoice creation error:', invoiceError);
        throw new Error(`Failed to create invoice: ${invoiceError.message}`);
      }

      if (!invoiceData) {
        throw new Error('No invoice data returned after creation');
      }

      // Insert invoice items
      const { error: itemsError } = await supabase
        .from('invoice_items')
        .insert(
          aiResponse.items.map((item, index) => ({
            invoice_id: invoiceData.id,
            description: item.description,
            quantity: item.quantity,
            price: item.price,
            taxable: item.taxable,
            order: index
          }))
        );

      if (itemsError) {
        console.error('Invoice items creation error:', itemsError);
        // Clean up the created invoice since items failed
        await supabase
          .from('invoices')
          .delete()
          .eq('id', invoiceData.id);
        throw new Error(`Failed to create invoice items: ${itemsError.message}`);
      }

      // Navigate to the new invoice
      navigate({ to: '/invoice/$id', params: { id: invoiceData.id } });

      return invoiceData;
    }
  });
};
