import { useQuery } from "@tanstack/react-query";
import { supabase } from "../supabaseClient";
import { Tax } from "../types";

export const useTaxes = (expenseId: string | undefined) => {
  return useQuery<Tax[], Error>({
    queryKey: ["taxes", expenseId],
    queryFn: async () => {
      if (!expenseId) return [];
      const { data, error } = await supabase
        .from("taxes")
        .select("*")
        .eq("expense_id", expenseId);
      if (error) throw error;
      return data;
    },
    enabled: !!expenseId,
  });
};