import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { Client } from '../../types';
import { useClients } from '../../hooks/useClients';
import { Toggle } from '../Toggle';
import Button from '../Button';
import { Close12, Plus12 } from '../Icon';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
  padding: 40px;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
  margin-bottom: 12px;
`

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputField = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.fullWidth ? '1 / -1' : 'auto'};

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input, select {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);

  label {
    margin: 0;
  }
`;

const AddressGroup = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: 10px;
`;

const AddressGroupHeader = styled.div`
  grid-column: 1 / -1;
  margin: 12px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
  font-weight: 500;
`;

const ButtonFooter = styled.div`
  display: flex;
  position: fixed;
  margin-top: 20px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F8F7F6;
  border-radius: 0 0 8px 8px;
  gap: 10px; // Add gap between buttons
  box-sizing: border-box;

`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const AddAddressButton = styled(Button)`
  margin: 24px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  background-color: transparent;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
  grid-column: 1 / -1;
  padding: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

interface NewClientDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClientAdded: (newClient: Client) => void;
  editingClient?: Client | null;
}

const AddClientDrawer: React.FC<NewClientDrawerProps> = ({ isOpen, setIsOpen, onClientAdded, editingClient }) => {
  const { createClient, updateClient } = useClients();
  const [newClient, setNewClient] = useState<Omit<Client, 'id' | 'organization_id'>>({
    full_name: '',
    email: '',
    phone: '',
    company: '',
    default_currency: 'USD',
    is_active: true,
    created_at: new Date().toISOString(),
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    notes: ''
  });

  const drawerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showAddressFields, setShowAddressFields] = useState(false);

  useEffect(() => {
    if (isOpen && editingClient) {
      setNewClient(editingClient);
      setShowAddressFields(!!editingClient.address_line1);
    } else if (!isOpen) {
      // Reset to initial state when closing
      setNewClient({
        full_name: '',
        email: '',
        phone: '',
        company: '',
        default_currency: 'USD',
        is_active: true,
        created_at: new Date().toISOString(),
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        notes: ''
      });
      setShowAddressFields(false);
    }
  }, [isOpen, editingClient]);

  const handleInputChange = (field: keyof Client, value: string | boolean) => {
    setNewClient(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveClient = async () => {
    if (newClient.full_name) {
      try {
        let data;
        if (editingClient) {
          if (updateClient && updateClient.mutateAsync) {
            data = await updateClient.mutateAsync({ id: editingClient.id, ...newClient });
          } else {
            throw new Error('Update client mutation is not available');
          }
        } else {
          if (createClient && createClient.mutateAsync) {
            data = await createClient.mutateAsync(newClient);
          } else {
            throw new Error('Create client mutation is not available');
          }
        }
        if (data && data.id) {
          onClientAdded(data);
          setIsOpen(false);
        } else {
          console.error('Invalid client data received:', data);
        }
      } catch (error) {
        console.error('Error adding/updating client:', error);
        // You might want to show an error message to the user here
      }
    } else {
      console.error('Please enter a client name.');
      // You might want to show an error message to the user here
    }
  };

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);

      if (!isClickInsideDrawer) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleOpenChange]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction={isMobile ? "bottom" : "right"}
      modal={true}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <DrawerTitle>{editingClient ? 'Edit Client' : 'New Client'}</DrawerTitle>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <InputFieldsContainer>
              <InputField fullWidth>
                <label htmlFor="fullName">Full Name</label>
                <input
                  id="fullName"
                  type="text"
                  value={newClient.full_name}
                  onChange={(e) => handleInputChange("full_name", e.target.value)}
                  placeholder="Full Name"
                  required
                />
              </InputField>
              <InputField>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={newClient.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  placeholder="Email"
                />
              </InputField>
              <InputField>
                <label htmlFor="phone">Phone</label>
                <input
                  id="phone"
                  type="tel"
                  value={newClient.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                  placeholder="Phone"
                />
              </InputField>
              <InputField fullWidth>
                <label htmlFor="company">Company</label>
                <input
                  id="company"
                  type="text"
                  value={newClient.company}
                  onChange={(e) => handleInputChange("company", e.target.value)}
                  placeholder="Company"
                />
              </InputField>
              {!showAddressFields && (
                <AddAddressButton onClick={() => setShowAddressFields(true)}>
                  <Plus12 />
                  Add Address
                </AddAddressButton>
              )}
              {showAddressFields && (
                <AddressGroup>
                  <AddressGroupHeader>Address</AddressGroupHeader>
                  <InputField fullWidth>
                    <label htmlFor="addressLine1">Address Line 1</label>
                    <input
                      id="addressLine1"
                      type="text"
                      value={newClient.address_line1}
                      onChange={(e) => handleInputChange("address_line1", e.target.value)}
                      placeholder="Address Line 1"
                    />
                  </InputField>
                  <InputField fullWidth>
                    <label htmlFor="addressLine2">Address Line 2</label>
                    <input
                      id="addressLine2"
                      type="text"
                      value={newClient.address_line2}
                      onChange={(e) => handleInputChange("address_line2", e.target.value)}
                      placeholder="Address Line 2"
                    />
                  </InputField>
                  <InputField>
                    <label htmlFor="city">City</label>
                    <input
                      id="city"
                      type="text"
                      value={newClient.city}
                      onChange={(e) => handleInputChange("city", e.target.value)}
                      placeholder="City"
                    />
                  </InputField>
                  <InputField>
                    <label htmlFor="state">State/Province</label>
                    <input
                      id="state"
                      type="text"
                      value={newClient.state}
                      onChange={(e) => handleInputChange("state", e.target.value)}
                      placeholder="State/Province"
                    />
                  </InputField>
                  <InputField>
                    <label htmlFor="postalCode">Postal Code</label>
                    <input
                      id="postalCode"
                      type="text"
                      value={newClient.postal_code}
                      onChange={(e) => handleInputChange("postal_code", e.target.value)}
                      placeholder="Postal Code"
                    />
                  </InputField>
                  <InputField>
                    <label htmlFor="country">Country</label>
                    <input
                      id="country"
                      type="text"
                      value={newClient.country}
                      onChange={(e) => handleInputChange("country", e.target.value)}
                      placeholder="Country"
                    />
                  </InputField>
                </AddressGroup>
              )}
              <InputField fullWidth>
                <label htmlFor="defaultCurrency">Default Currency</label>
                <select
                  id="defaultCurrency"
                  value={newClient.default_currency}
                  onChange={(e) => handleInputChange("default_currency", e.target.value)}
                >
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="CAD">CAD</option>
                </select>
              </InputField>
              <InputField>
                <label>Status</label>
                <ToggleWrapper>
                  <Toggle
                    checked={newClient.is_active}
                    onChange={(checked) => handleInputChange("is_active", checked)}
                  />
                  {newClient.is_active ? "Active" : "Inactive"}
                </ToggleWrapper>
              </InputField>
            </InputFieldsContainer>
            <ButtonFooter>
              <Button onClick={handleSaveClient}>
                {editingClient ? 'Update Client' : 'Create Client'}
              </Button>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </ButtonFooter>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default AddClientDrawer;