import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "./supabaseClient";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HeaderPreview from "./components/Invoices/HeaderPreview";
import InvoicePreview from "./InvoicePreview";
import ColorPickerWithPopover from "./components/ColorPicker";
import FontPicker from "./components/FontPicker";
import HeaderStyleSelector from "./components/Invoices/HeaderStyleSelector";
import Switch from "./components/Switch";
import chroma from "chroma-js";
import { useOrganization } from "./hooks/useOrganization";
import { debounce } from "lodash";
import ImageUploader from "./components/ImageUploader";
import { usePageContext } from "./hooks/usePageContext";
import { useBrandSettings } from "./hooks/useBrandSettings";
import { useLogoUrl } from "./hooks/useLogoUrl";
import { Client } from "./types"; // Make sure to import the Client type
import ColorPresets, { ColorPreset } from './components/ColorPresets';

const BrandContainer = styled.div`
  display: flex;
`;

const BrandPreview = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 0 80px 0;
`;

const InvoicePreviewContainer = styled.div.attrs<{ $backgroundColor: string }>(
  (props) => ({
    style: {
      backgroundColor: props.$backgroundColor,
    },
  })
)`
  transform: scale(0.85);
  max-width: 880px;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  box-shadow: 
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 24px 32px rgba(0, 0, 0, 0.05),
  0 40px 64px rgba(0, 0, 0, 0.05),
  0 64px 80px rgba(0, 0, 0, 0.05);
  transform-origin: top center;
`;

const BrandSidebar = styled.div`
  padding: 24px 40px 40px 0;
  min-width: 264px;
  box-sizing: border-box;
`;

const StatusUpdate = styled.div`
  position: absolute;
  opacity: 0.5;
  bottom: 16px;
`;

const GroupHeader = styled.h3`
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.8);
  padding: 12px 0;
  margin: 0 0 12px 0;
  font-weight: 500;
  user-select: none;
`;

const PickerGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

const GroupWrapper = styled.div`
  margin-bottom: 16px;
`;

const SwitchContainer = styled.div`
  margin: 12px 0 32px 0;
`;

const debouncedUpdateSettings = debounce((callback: () => void) => {
  callback();
}, 500);

function isValidTemplate(template: string): template is "simple" | "detailed" | "modern" | "boxed" {
  return ["simple", "detailed", "modern"].includes(template);
}

const Brand: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: brandSettings, isLoading: isLoadingBrandSettings } = useBrandSettings();
  const { data: logoUrl } = useLogoUrl(organizationId);

  const [brandState, setBrandState] = useState({
    defaultTemplate: "",
    defaultFont: "",
    defaultHeaderColor: "",
    defaultBackgroundColor: "",
    defaultHeaderTextColor: "",
    defaultBodyTextColor: "",
    autoColorText: false,
    showLogo: true,
  });
  const [shouldUpdateSettings, setShouldUpdateSettings] = useState(false);

  const fonts = [
    "Inter",
    "Montserrat",
    "IBM Plex Mono",
    "Merriweather",
    "Playfair Display",
    "Lora",
    "Barlow",
    "PT Serif",
    "Noto Serif",
    "Space Grotesk",
    "GeistVF",
    "GeistMonoVF",
  ];

  const updateBrandSettingsMutation = useMutation({
    mutationFn: async (newSettings: {
      default_template: string;
      default_font: string;
      default_header_color: string;
      default_background_color: string;
      default_header_text_color: string;
      default_body_text_color: string;
      auto_color_text: boolean;
      show_logo: boolean;
    }) => {
      if (!organizationId) throw new Error("Organization not found");

      const { data: updatedData, error: updateError } = await supabase
        .from("brand_settings")
        .upsert(
          { organization_id: organizationId, ...newSettings },
          { onConflict: "organization_id", ignoreDuplicates: false }
        )
        .select()
        .single();

      if (updateError) {
        console.error("Supabase error:", updateError);
        throw new Error(
          `Failed to update brand settings: ${updateError.message}`
        );
      }
      return updatedData;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['brandSettings', organizationId],
      });
    },
    onError: (error: Error) => {
      console.error("Mutation error:", error.message);
    },
  });

  const handleLogoUpload = useCallback(async (uploadedLogoUrl: string) => {
    try {
      const { error } = await supabase
        .from('organizations')
        .update({ logo_url: uploadedLogoUrl })
        .eq('id', organizationId);

      if (error) throw error;

      // Invalidate the logo query to refetch the updated data
      queryClient.invalidateQueries({
        queryKey: ['logoUrl', organizationId],
      });
    } catch (error) {
      console.error('Error updating logo:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  }, [organizationId, queryClient]);

  const handleLogoRemove = useCallback(async (fileName: string | null) => {
    try {
      // Update the organization table
      const { error: updateError } = await supabase
        .from('organizations')
        .update({ logo_url: null })
        .eq('id', organizationId);

      if (updateError) throw updateError;

      // Remove the file from storage if fileName is provided
      if (fileName) {
        const { error: storageError } = await supabase.storage
          .from('logos')
          .remove([fileName]);

        if (storageError) throw storageError;
      }

      // Invalidate the logo query to refetch the updated data
      queryClient.invalidateQueries({
        queryKey: ['logoUrl', organizationId],
      });

    } catch (error) {
      console.error('Error removing logo:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  }, [organizationId, queryClient]);

  useEffect(() => {
    if (brandSettings) {
      setBrandState({
        defaultTemplate: brandSettings.default_template,
        defaultFont: brandSettings.default_font,
        defaultHeaderColor: brandSettings.default_header_color,
        defaultBackgroundColor: brandSettings.default_background_color,
        defaultHeaderTextColor: brandSettings.default_header_text_color,
        defaultBodyTextColor: brandSettings.default_body_text_color,
        autoColorText: brandSettings.auto_color_text,
        showLogo: brandSettings.show_logo,
      });
    }
  }, [brandSettings]);

  useEffect(() => {
    if (shouldUpdateSettings && organizationId) {
      updateBrandSettingsMutation.mutate({
        default_template: brandState.defaultTemplate,
        default_font: brandState.defaultFont,
        default_header_color: brandState.defaultHeaderColor,
        default_background_color: brandState.defaultBackgroundColor,
        default_header_text_color: brandState.defaultHeaderTextColor,
        default_body_text_color: brandState.defaultBodyTextColor,
        auto_color_text: brandState.autoColorText,
        show_logo: brandState.showLogo,
      });
      setShouldUpdateSettings(false);
    }
  }, [
    shouldUpdateSettings,
    brandState,
    updateBrandSettingsMutation,
    organizationId,
  ]);

  const handleTemplateChange = useCallback((newTemplate: string) => {
    setBrandState(prevState => ({ ...prevState, defaultTemplate: newTemplate }));
    setShouldUpdateSettings(true);
  }, []);

  const calculateTextColor = useCallback((backgroundColor: string): string => {
    const bgColor = chroma(backgroundColor);
    const isDarkBg = bgColor.luminance() < 0.5;

    let textColor = bgColor
      .set("hsl.h", (bgColor.get("hsl.h") + 30) % 360)
      .set("hsl.s", Math.min(bgColor.get("hsl.s") * 1.5, 1));

    textColor = textColor.set("hsl.l", isDarkBg ? 0.8 : 0.2);

    const minContrast = 3.5;
    let contrast = chroma.contrast(bgColor, textColor);

    const step = isDarkBg ? -0.05 : 0.05;
    while (
      contrast < minContrast &&
      textColor.get("hsl.l") > 0 &&
      textColor.get("hsl.l") < 1
    ) {
      textColor = textColor.set("hsl.l", textColor.get("hsl.l") + step);
      contrast = chroma.contrast(bgColor, textColor);
    }

    return textColor.hex();
  }, []);

  const handleColorChange = useCallback(
    (colorType: 'header' | 'background', newColor: string) => {
      setBrandState(prev => ({
        ...prev,
        [colorType === 'header' ? 'defaultHeaderColor' : 'defaultBackgroundColor']: newColor,
      }));
      if (brandState.autoColorText) {
        const newTextColor = calculateTextColor(newColor);
        setBrandState(prev => ({
          ...prev,
          [colorType === 'header' ? 'defaultHeaderTextColor' : 'defaultBodyTextColor']: newTextColor,
        }));
      }
      debouncedUpdateSettings(() => setShouldUpdateSettings(true));
    },
    [brandState.autoColorText, calculateTextColor]
  );

  const handleTextColorChange = useCallback(
    (colorType: 'header' | 'body', newColor: string) => {
      if (!brandState.autoColorText) {
        setBrandState(prev => ({
          ...prev,
          [colorType === 'header' ? 'defaultHeaderTextColor' : 'defaultBodyTextColor']: newColor,
        }));
        debouncedUpdateSettings(() => setShouldUpdateSettings(true));
      }
    },
    [brandState.autoColorText]
  );

  useEffect(() => {
    if (brandState.autoColorText) {
      const newHeaderTextColor = calculateTextColor(brandState.defaultHeaderColor);
      const newBodyTextColor = calculateTextColor(brandState.defaultBackgroundColor);

      if (
        newHeaderTextColor !== brandState.defaultHeaderTextColor ||
        newBodyTextColor !== brandState.defaultBodyTextColor
      ) {
        setBrandState(prevState => ({
          ...prevState,
          defaultHeaderTextColor: newHeaderTextColor,
          defaultBodyTextColor: newBodyTextColor
        }));
        debouncedUpdateSettings(() => setShouldUpdateSettings(true));
      }
    }
  }, [
    brandState.autoColorText,
    brandState.defaultHeaderColor,
    brandState.defaultBackgroundColor,
    calculateTextColor,
    brandState.defaultHeaderTextColor,
    brandState.defaultBodyTextColor,
  ]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Brand",
    });
  }, [setPageHeaderProps]);

  const demoClient: Client = {
    id: 'demo-client-id',
    full_name: 'Demo Client',
    email: 'demo@example.com',
    phone: '(555) 123-4567',
    address_line1: '123 Demo Street',
    address_line2: 'Suite 456',
    city: 'Demo City',
    state: 'DS',
    postal_code: '12345',
    country: 'Demoland',
    organization_id: 'demo-org-id',
    created_at: new Date().toISOString(),
    is_active: true,
  };

  const exampleInvoiceItems = [
    {
      id: 'example-item-1',
      invoice_id: 'preview-invoice',
      description: 'Web Design Services',
      quantity: 1,
      price: 1500,
      taxable: true,
      order: 0,
    },
    {
      id: 'example-item-2',
      invoice_id: 'preview-invoice',
      description: 'Logo Design',
      quantity: 1,
      price: 500,
      taxable: true,
      order: 1,
    },
    {
      id: 'example-item-3',
      invoice_id: 'preview-invoice',
      description: 'Hosting (per month)',
      quantity: 12,
      price: 20,
      taxable: false,
      order: 2,
    },
  ];

  const handlePresetSelect = useCallback((preset: ColorPreset) => {
    setBrandState(prevState => ({
      ...prevState,
      defaultHeaderColor: preset.headerColor,
      defaultHeaderTextColor: preset.headerTextColor,
      defaultBackgroundColor: preset.backgroundColor,
      defaultBodyTextColor: preset.bodyTextColor,
      autoColorText: false, // Turn off auto color when selecting a preset
    }));
    setShouldUpdateSettings(true);
  }, []);

  if (isLoadingBrandSettings) return null;

  return (
    <BrandContainer>
      <BrandPreview>
        <InvoicePreviewContainer $backgroundColor={brandState.defaultBackgroundColor}>
          <HeaderPreview
            template={brandState.defaultTemplate}
            font={brandState.defaultFont}
            headerColor={brandState.defaultHeaderColor}
            headerTextColor={brandState.defaultHeaderTextColor}
            logoUrl={logoUrl}
            showLogo={brandState.showLogo}
          />
          <InvoicePreview
            font={brandState.defaultFont}
            headerColor={brandState.defaultHeaderColor}
            headerTextColor={brandState.defaultHeaderTextColor}
            backgroundColor={brandState.defaultBackgroundColor}
            bodyTextColor={brandState.defaultBodyTextColor}
            invoice={{
              id: 'preview-invoice',
              invoice_number: 'PREVIEW',
              client_id: demoClient.id,
              subtotal: 2240,
              total: 2352,
              amount_due: 2352,
              tax_rate: 5,
              currency: 'USD',
              invoice_date: new Date().toISOString(),
              due_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
              notes: 'Thank you for your business!',
              items: exampleInvoiceItems,
              payments: [],
              status: 'draft',
              invoice_template: isValidTemplate(brandState.defaultTemplate) ? brandState.defaultTemplate : "simple",
              invoice_created_at: new Date().toISOString(),
              header_color: brandState.defaultHeaderColor,
              background_color: brandState.defaultBackgroundColor,
              header_text_color: brandState.defaultHeaderTextColor,
              body_text_color: brandState.defaultBodyTextColor,
              font: brandState.defaultFont,
              public_id: '',
              due_days: 30,
              logo_url: logoUrl,
              show_logo: brandState.showLogo,
            }}
            services={[]}
            handleServiceSelect={() => {}}
            handleDescriptionChange={() => {}}
            updateItem={() => {}}
            deleteItem={() => {}}
            onCreateNewService={() => {}}
          />
        </InvoicePreviewContainer>
        <StatusUpdate>
          {updateBrandSettingsMutation.isPending && <p>Saving...</p>}
          {updateBrandSettingsMutation.isError && <p>Error saving settings</p>}
          {updateBrandSettingsMutation.isSuccess && (
            <p>Settings saved successfully</p>
          )}
        </StatusUpdate>
      </BrandPreview>
      <BrandSidebar>
        <GroupWrapper>
          <ImageUploader
            currentImage={logoUrl || null}
            onUpload={handleLogoUpload}
            onRemove={handleLogoRemove}
          />
          {logoUrl && (
            <SwitchContainer>
              <Switch
                checked={brandState.showLogo}
                onChange={(checked) => {
                  setBrandState(prevState => ({ ...prevState, showLogo: checked }));
                  setShouldUpdateSettings(true);
                }}
                label="Show Logo"
              />
            </SwitchContainer>
          )}
        </GroupWrapper>
        <HeaderStyleSelector
          selectedTemplate={brandState.defaultTemplate}
          onTemplateChange={handleTemplateChange}
        />
        <GroupWrapper>
        <GroupHeader>Header Colors</GroupHeader>
        <PickerGroup>
          <ColorPickerWithPopover
            color={brandState.defaultHeaderColor}
            onChange={(newColor) => handleColorChange('header', newColor)}
            label="Background"
            id="default-header-color"
            onImmediateChange={(newColor) => handleColorChange('header', newColor)}
          />
          <ColorPickerWithPopover
            color={brandState.defaultHeaderTextColor}
            onChange={(newColor) => handleTextColorChange('header', newColor)}
            label="Text"
            id="default-header-text-color"
            disabled={brandState.autoColorText}
            onImmediateChange={(newColor) => handleColorChange('header', newColor)}
          />
        </PickerGroup>
        <GroupHeader>Body Colors</GroupHeader>
        <PickerGroup>
          <ColorPickerWithPopover
            color={brandState.defaultBackgroundColor}
            onChange={(newColor) => handleColorChange('background', newColor)}
            label="Background"
            id="default-background-color"
            onImmediateChange={(newColor) => handleColorChange('background', newColor)}
          />
          <ColorPickerWithPopover
            color={brandState.defaultBodyTextColor}
            onChange={(newColor) => handleTextColorChange('body', newColor)}
            label="Text"
            id="default-body-text-color"
            disabled={brandState.autoColorText}
            onImmediateChange={(newColor) => handleTextColorChange('body', newColor)}
          />
        </PickerGroup>
        </GroupWrapper>
        <GroupWrapper>
          <Switch
          checked={brandState.autoColorText}
          onChange={(checked) => {
            setBrandState(prevState => ({ ...prevState, autoColorText: checked }));
            setShouldUpdateSettings(true);
          }}
            label="Auto color"
          />
        </GroupWrapper>
        <GroupWrapper>
          <GroupHeader>Font</GroupHeader>
          <FontPicker
          id="defaultInvoiceFont"
          font={brandState.defaultFont}
          onChange={(font) => {
            setBrandState(prevState => ({ ...prevState, defaultFont: font }));
            setShouldUpdateSettings(true);
          }}
          label="Font"
          fonts={fonts}
        />
        </GroupWrapper>
        <GroupWrapper>
          <GroupHeader>Color Presets</GroupHeader>
          <ColorPresets onSelectPreset={handlePresetSelect} />
        </GroupWrapper>
      </BrandSidebar>
    </BrandContainer>
  );
};

export default Brand;
