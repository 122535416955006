import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Task } from '../types';
import { useOrganization } from './useOrganization';

export const useFetchTasks = (projectId?: string) => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const localUpdatesRef = useRef<Map<string, boolean>>(new Map());

  // Add subscription ref to prevent multiple subscriptions

  const fetchTasks = async (): Promise<Task[]> => {
    if (!organizationId) throw new Error('Organization ID is required');
    let query = supabase
      .from('tasks')
      .select('*')
      .eq('organization_id', organizationId);
    
    if (projectId) {
      query = query.eq('project_id', projectId);
    }
    
    const { data, error } = await query;
    if (error) throw error;
    return data;
  };

  // Change the query key to match what we use in mutations
  const queryKey = projectId 
    ? ['tasks', organizationId, projectId]
    : ['tasks', organizationId];

  const query = useQuery<Task[], Error>({
    queryKey,
    queryFn: fetchTasks,
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (!organizationId) return;

    // Set up Supabase realtime subscription
    const channel = supabase.channel(`tasks-${organizationId}-${projectId || 'all'}`, {
      config: {
        broadcast: { self: true },
        presence: { key: '' },
      },
    });

    channel
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'tasks',
          filter: projectId 
            ? `organization_id=eq.${organizationId} AND project_id=eq.${projectId}`
            : `organization_id=eq.${organizationId}`,
        },
        (payload) => {
          console.log('Received realtime update:', payload);
          // Create a custom event that matches our existing event handler
          const customEvent = new CustomEvent('TASK_UPDATED', {
            detail: {
              eventType: payload.eventType,
              new: payload.new,
              old: payload.old,
            },
          });
          window.dispatchEvent(customEvent);
        }
      )
      .subscribe((status) => {
        console.log(`Realtime subscription status: ${status}`);
        if (status === 'SUBSCRIBED') {
          console.log('Successfully subscribed to tasks changes');
        }
      });

    // Cleanup subscription on unmount or when dependencies change
    return () => {
      console.log('Cleaning up realtime subscription');
      supabase.removeChannel(channel);
    };
  }, [organizationId, projectId]);

  useEffect(() => {
    if (!organizationId) return;

    const handleTaskUpdate = (event: CustomEvent) => {
      const payload = event.detail;
      
      // Don't update if we're currently editing this task
      if (payload.new?.id && localUpdatesRef.current.get(payload.new.id)) {
        return;
      }

      // Update both the project-specific and general tasks cache
      const updateCache = (cacheKey: readonly unknown[]) => {
        queryClient.setQueryData(cacheKey, (oldData: Task[] | undefined) => {
          if (!oldData) return oldData;
          
          let updatedData: Task[] | undefined;
          
          if (payload.eventType === 'INSERT') {
            // Check if task already exists to prevent duplicates
            if (!oldData.some(task => task.id === payload.new.id)) {
              // Only add if it matches our project filter
              if (!projectId || payload.new.project_id === projectId) {
                updatedData = sortTasks([...oldData, payload.new as Task]);
              }
            }
          }
          if (payload.eventType === 'UPDATE') {
            updatedData = sortTasks(oldData.map(task => 
              task.id === payload.new.id ? payload.new as Task : task
            ));
          }
          if (payload.eventType === 'DELETE') {
            updatedData = sortTasks(oldData.filter(task => task.id !== payload.old.id));
          }
          
          // Emit a custom event for components to update their local state
          if (updatedData) {
            window.dispatchEvent(new CustomEvent('TASKS_CACHE_UPDATED', {
              detail: {
                tasks: updatedData,
                projectId: projectId || payload.new?.project_id,
              }
            }));
            return updatedData;
          }
          
          return oldData;
        });
      };

      // Update the main tasks cache
      updateCache(['tasks', organizationId]);

      // If this is a project-specific task, update that cache too
      if (projectId) {
        updateCache(['tasks', organizationId, projectId]);
      }

      // If the task has a project_id, update that project's cache
      if (payload.new?.project_id) {
        updateCache(['tasks', organizationId, payload.new.project_id]);
      }

      // Invalidate queries to ensure consistency
      queryClient.invalidateQueries({ 
        queryKey: ['tasks', organizationId],
        exact: false,
        refetchType: 'none' // Prevent immediate refetch
      });
    };

    window.addEventListener('TASK_UPDATED', handleTaskUpdate as EventListener);

    return () => {
      window.removeEventListener('TASK_UPDATED', handleTaskUpdate as EventListener);
    };
  }, [organizationId, projectId, queryClient]);

  // Add effect to handle initial data and updates
  useEffect(() => {
    if (query.data) {
      window.dispatchEvent(new CustomEvent('TASKS_CACHE_UPDATED', {
        detail: {
          tasks: query.data,
          projectId,
        }
      }));
    }
  }, [query.data, projectId]);

  return {
    ...query,
    localUpdatesRef,
  };
};

const sortTasks = (tasks: Task[]): Task[] => {
  return [...tasks].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
};
