import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Payment, InvoiceData, Client, EmailTrackingData } from '../../types';
import Button from '../Button';
import { Calendar12, Client12, DueDate12, Money12, Number12, Status12, Invoice12, Eye12, Copy12, Mail12 } from '../Icon';
import { StatusBadge } from '../StatusBadge';
import { format, isThisYear, differenceInCalendarDays } from 'date-fns';
import { QueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { supabase } from '../../supabaseClient';

const DetailsContainer = styled.div<{ $status: string }>`
  display: flex;
  flex-direction: column;
`;

const GridSection = styled.div`
  display: grid;
  row-gap: 16px;
  column-gap: 24px;
  padding: 32px 40px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  grid-template-columns: max-content 1fr;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  text-transform: capitalize;
`;

const ShareLinkContainer = styled.div`
  padding: 32px 40px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ShareLink = styled.div`
  color: rgba(0,0,0,0.5);
  word-break: break-all;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ShareLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.05);
  background: rgba(0,0,0,0.025);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 2px;
`;

const ShareLinkButton = styled(Button)`
  background-color: rgba(255,255,255,1);
  box-shadow: 0 1px 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 5px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const TitleAmount = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`;

const OpenInvoiceButton = styled(Button)`
  margin-bottom: 16px;
  padding: 8px 12px;
  text-align: center;
  justify-content: center;
  width: 100%;
  box-shadow: inset 0 0.5px 0 rgba(255, 255, 255, 0.75), 0 0.5px 0px rgba(0,0,0,0.15);
  transition: transform 0.1s ease-in-out;

  &:active {
    transform: scale(0.995);
  }
`;

const InvoiceStatus = styled(StatusBadge)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 10px;
  border-radius: 8px;
  width: fit-content;
  border: 2px solid;
  background: transparent;
  border-style: ${({ $status }) => $status === 'draft' ? 'dashed' : 'solid'};
  border-color: ${({ $status }) => {
    switch ($status) {
      case 'paid':
        return '#1e7e34';
      case 'unpaid':
        return '#f29d41';
      case 'overdue':
        return '#d73a49';
      case 'draft':
        return '#495057';
      default:
        return '#495057';
    }
  }};
`;

const TitleSection = styled.div<{ $status: string }>`
  padding: 40px 40px 24px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
`;

const HistorySection = styled.div`
  padding: 32px 40px;
`;

const HistoryItem = styled(motion.div)`
  margin-bottom: 8px;
`;

const HistoryIcon = styled.div`
  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const HistoryDate = styled.div<{ $isLast: boolean }>`
  font-size: 14px;
  border-left: 1px solid ${props => props.$isLast ? 'transparent' : 'rgba(0,0,0,0.1)'};
  padding: 0 0 24px 22px;
  margin: 2px 0 0 6px;
  color: rgba(0,0,0,0.5);
`;

const HistoryEvent = styled.div`
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const SectionHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
  color: rgba(0,0,0,0.5);
`;

interface InvoiceDetailsProps {
  invoice: InvoiceData;
  clients: Client[];
  navigate: (to: { to: string; params: { id: string } }) => void;
  payments: Payment[];
  emailTrackingData: EmailTrackingData[] | undefined;
  queryClient: QueryClient;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ 
  invoice, 
  clients, 
  navigate, 
  payments, 
  emailTrackingData,
  queryClient,
}) => {
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');

  // Move handleOpenInvoiceHover up here, before any conditional returns
  const handleOpenInvoiceHover = useCallback(() => {
    if (!invoice) return;

    // Prefetch invoice data
    queryClient.prefetchQuery({
      queryKey: ['invoice', invoice.id, invoice.organization_id],
      queryFn: async () => {
        const [
          { data: invoiceData, error: invoiceError },
          { data: itemsData, error: itemsError },
          { data: paymentsData, error: paymentsError }
        ] = await Promise.all([
          supabase
            .from('invoices')
            .select('*')
            .eq('id', invoice.id)
            .single(),
          supabase
            .from('invoice_items')
            .select('*')
            .eq('invoice_id', invoice.id)
            .order('order'),
          supabase
            .from('payments')
            .select('*')
            .eq('invoice_id', invoice.id)
        ]);

        if (invoiceError) throw invoiceError;
        if (itemsError) throw itemsError;
        if (paymentsError) throw paymentsError;

        return {
          ...invoiceData,
          items: itemsData || [],
          payments: paymentsData || []
        };
      },
      staleTime: 10000
    });

    // Prefetch organization data
    queryClient.prefetchQuery({
      queryKey: ['organizationDetails', invoice.organization_id],
      queryFn: async () => {
        const { data, error } = await supabase
          .from('organizations')
          .select('*, stripe_account_id, stripe_account_verified')
          .eq('id', invoice.organization_id)
          .single();
        
        if (error) throw error;
        return data;
      },
      staleTime: 60000
    });
  }, [invoice, queryClient]);

  // Move all useCallback hooks to the top of the component
  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const isCurrentYear = isThisYear(date);
    return isCurrentYear
      ? format(date, 'MMM d \'at\' h:mma')
      : format(date, 'MMM d, yyyy \'at\' h:mma');
  }, []);

  const formatCurrency = useCallback((amount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: invoice?.currency || 'USD' }).format(amount);
  }, [invoice?.currency]);

  const getInvoiceStatus = useCallback((invoiceData: InvoiceData) => {
    if (invoiceData.status === 'paid') return 'paid';
    if (invoiceData.status === 'draft') return 'draft';
    
    const dueDate = new Date(invoiceData.due_date);
    const today = new Date();
    
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    
    if (dueDate.getTime() < today.getTime()) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  const invoiceStatus = useMemo(() => invoice ? getInvoiceStatus(invoice) : 'default', [invoice, getInvoiceStatus]);

  const getDueDateDisplay = useCallback(() => {
    if (!invoice) return '';
    const dueDate = new Date(invoice.due_date);
    const today = new Date();
    const diffDays = differenceInCalendarDays(dueDate, today);

    if (diffDays < 0) {
      return `was due ${Math.abs(diffDays)} ${Math.abs(diffDays) === 1 ? 'day' : 'days'} ago`;
    } else if (diffDays === 0) {
      return 'is due today';
    } else if (diffDays === 1) {
      return 'is due tomorrow';
    } else {
      return `is due in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
    }
  }, [invoice]);

  const formatShortDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return format(date, 'MMM d');
  }, []);

  const getInvoiceTitle = useCallback(() => {
    if (!invoice) return '';
    if (invoiceStatus === 'paid' && payments && payments.length > 0) {
      const latestPayment = payments
        .filter(payment => payment.invoice_id === invoice.id)
        .sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime())[0];
      
      if (latestPayment) {
        return (
          <>
            Invoice for {formatCurrency(invoice.total || 0)}<br />
            was paid on {formatShortDate(latestPayment.payment_date)}
          </>
        );
      }
    }
    return `Invoice for ${formatCurrency(invoice.total || 0)}`;
  }, [invoice, invoiceStatus, payments, formatCurrency, formatShortDate]);

  const getClientName = useMemo(() => {
    const client = clients.find(client => client.id === invoice?.client_id);
    return client ? client.full_name : 'No Client';
  }, [clients, invoice?.client_id]);

  const historyItems = useMemo(() => {
    if (!invoice) return [];
    const creationItem = {
      date: new Date(invoice.invoice_created_at || invoice.invoice_date || new Date()),
      event: 'Invoice created',
      type: 'creation'
    };

    const paymentItems = (payments || [])
      .filter(payment => payment.invoice_id === invoice.id)
      .map(payment => ({
        date: new Date(payment.payment_date),
        event: `Payment received: ${formatCurrency(payment.amount)}`,
        type: 'payment'
      }));

    const emailItems = (emailTrackingData || []).flatMap(tracking => [
      {
        date: new Date(tracking.sent_at),
        event: `Invoice sent to ${tracking.recipient_email}`,
        type: 'email_sent'
      },
      ...(tracking.open_history || []).map(openEvent => ({
        date: new Date(openEvent),
        event: 'Invoice viewed',
        type: 'view'
      }))
    ]);

    const allItems = [creationItem, ...paymentItems, ...emailItems];

    // Sort all items by timestamp (oldest first)
    return allItems.sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [invoice, payments, emailTrackingData, formatCurrency]);

  if (!invoice) return <div>No invoice data available</div>;

  const amountDue = invoice.amount_due || 0;

  const formattedAmountDue = formatCurrency(amountDue);

  // Update the shareLink to use trydue.com
  const shareLink = `${window.location.origin}/share/${invoice.public_id}`;

  const handleOpenInvoice = () => {
    if (invoice) {
      navigate({ to: '/invoice/$id', params: { id: invoice.id } });
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setCopyButtonText('Copied');
    setTimeout(() => setCopyButtonText('Copy Link'), 2000);
  };

  const historyItemVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerDuration = 0.05;
  const animationDuration = 0.5;

  const getIconForType = (type: string) => {
    switch (type) {
      case 'creation':
        return <Invoice12 />;
      case 'payment':
        return <Money12 />;
      case 'view':
        return <Eye12 />;
      case 'email_sent':
        return <Mail12 />;
      default:
        return null;
    }
  };

  return (
    <DetailsContainer $status={invoiceStatus}>
      <TitleSection $status={invoiceStatus}>
        <InvoiceStatus $status={invoiceStatus}>
          {invoiceStatus}
        </InvoiceStatus>
        <Title>
          <TitleAmount>{getInvoiceTitle()}</TitleAmount>
          {invoiceStatus !== 'paid' && (
            <TitleAmount>
              {getDueDateDisplay()}
            </TitleAmount>
          )}
        </Title>
        <OpenInvoiceButton 
          onClick={handleOpenInvoice}
          onMouseEnter={handleOpenInvoiceHover}
        >
          Open Invoice
        </OpenInvoiceButton>
      </TitleSection>

      <GridSection>
          <DetailLabel><Number12 />Invoice #</DetailLabel>
          <DetailValue>{invoice.invoice_number}</DetailValue>
          <DetailLabel><Status12 />Status</DetailLabel>
          <DetailValue>{invoiceStatus}</DetailValue>

          <DetailLabel><Client12 />Client</DetailLabel>
          <DetailValue>{getClientName}</DetailValue>

          <DetailLabel><Calendar12 />Invoice Date</DetailLabel>
          <DetailValue>
            {invoice.invoice_date
              ? formatDate(invoice.invoice_date)
              : 'N/A'}
          </DetailValue>

          <DetailLabel><DueDate12 />Due Date</DetailLabel>
          <DetailValue>
            {invoice.due_date
              ? formatDate(invoice.due_date)
              : 'N/A'}
          </DetailValue>

          <DetailLabel><Money12 />Amount Due</DetailLabel>
          <DetailValue>{formattedAmountDue}</DetailValue>
      </GridSection>
      <ShareLinkContainer>
          <DetailLabel>Payment Link</DetailLabel>
          <ShareLinkWrapper>
          <ShareLink>
            {shareLink}
          </ShareLink>
            <ShareLinkButton onClick={handleCopyLink}><Copy12 />{copyButtonText}</ShareLinkButton>
          </ShareLinkWrapper>
        </ShareLinkContainer>

      <HistorySection>
        <SectionHeader>Invoice History</SectionHeader>
        {historyItems.map((item, index) => (
          <HistoryItem
            key={index}
            variants={historyItemVariants}
            initial={index === 0 ? "visible" : "hidden"}
            animate="visible"
            transition={index === 0 ? {} : {
              duration: animationDuration,
              delay: (index - 1) * staggerDuration,
              ease: [0.25, 0.1, 0.25, 1], // Custom easing function for a smoother animation
            }}
          >
            <HistoryEvent>
              <HistoryIcon>{getIconForType(item.type)}</HistoryIcon>
              {item.event}
            </HistoryEvent>
            <HistoryDate $isLast={index === historyItems.length - 1}>
              {formatDate(item.date.toISOString())}
            </HistoryDate>
          </HistoryItem>
        ))}
        {historyItems.length === 0 && (
          <HistoryItem>No history available</HistoryItem>
        )}
      </HistorySection>
    </DetailsContainer>
  );
};

export default InvoiceDetails;
