import React from 'react';
import styled from 'styled-components';
import NumberFlow from '@number-flow/react';

const EveningWrapper = styled.div`
  width: 100%;
  position: relative;
  background: radial-gradient(circle at 90% 110%, #FF9F69 -50%, #091024 80%);
  height: 288px;
  display: flex;
  flex-direction: column;
`;

const EveningTimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  padding: 24px 20px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
`;

const EveningColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  min-width: 96px;
`;

const EveningTimeInputField = styled.input`
  box-sizing: border-box;
  background: transparent;
  text-align: center;
  font-size: 64px;
  line-height: 96px;
  color: #F8F7F6;
  font-family: 'EB Garamond';
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-feature-settings: 'tnum';
  border: none;
  cursor: text;
  padding: 0;
  margin: 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: rgba(248, 247, 246, 0.3);
  }
`;

const EveningTimeInputLabel = styled.div`
  font-size: 12px;
  color: rgba(240, 244, 248, 0.75);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 8px;
`;

const Stars = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

interface EveningLayoutProps {
  durationInputs: { hours: string; minutes: string; seconds: string };
  isCurrentlyRunning: boolean;
  editingField: 'hours' | 'minutes' | 'seconds' | null;
  handleDurationClick: (field: 'hours' | 'minutes' | 'seconds') => void;
  handleDurationChange: (field: 'hours' | 'minutes' | 'seconds') => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDurationBlur: () => void;
}

const EveningLayout: React.FC<EveningLayoutProps> = ({
  durationInputs,
  isCurrentlyRunning,
  editingField,
  handleDurationClick,
  handleDurationChange,
  handleDurationBlur,
}) => {
  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds') => {
    if (isCurrentlyRunning || editingField !== field) {
      return (
        <EveningTimeInputField
          as={NumberFlow}
          value={durationInputs[field] === '' ? 0 : parseInt(durationInputs[field])}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 200, easing: 'ease-out' }}
          spinTiming={{ duration: 200, easing: 'ease-out' }}
          opacityTiming={{ duration: 200, easing: 'ease-out' }}
          onClick={() => handleDurationClick(field)}
        />
      );
    } else {
      return (
        <EveningTimeInputField
          type="number"
          value={durationInputs[field]}
          onChange={(e) => handleDurationChange(field)(e)}
          onBlur={handleDurationBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus
          placeholder="00"
        />
      );
    }
  };

  return (
    <EveningWrapper>
      <Stars>
        <svg width="430" height="199" viewBox="0 0 430 199" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="184.5" cy="15.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="216.5" cy="47.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="256.5" cy="23.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="312.5" cy="31.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="304.5" cy="55.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="385.5" cy="0.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="209.5" cy="112.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="346.5" cy="128.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="218.5" cy="192.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="145.5" cy="135.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="104.5" cy="78.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="40.5" cy="14.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="0.5" cy="94.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="88.5" cy="198.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="280.5" cy="118.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="352.5" cy="70.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="424.5" cy="38.5" r="0.5" fill="#D9D9D9"/>
          <circle cx="429.5" cy="60.5" r="0.5" fill="#D9D9D9"/>
        </svg>
      </Stars>
      <EveningTimeInputWrapper>
        <EveningColumn>
          {renderTimeInput('hours')}
          <EveningTimeInputLabel>Hrs</EveningTimeInputLabel>
        </EveningColumn>
        <EveningColumn>
          {renderTimeInput('minutes')}
          <EveningTimeInputLabel>Mins</EveningTimeInputLabel>
        </EveningColumn>
        <EveningColumn>
          {renderTimeInput('seconds')}
          <EveningTimeInputLabel>Secs</EveningTimeInputLabel>
        </EveningColumn>
      </EveningTimeInputWrapper>
    </EveningWrapper>
  );
};

export default EveningLayout;
