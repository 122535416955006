import React from 'react';
import styled from 'styled-components';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Task, TaskStatus } from '../../types';
import KanbanCard from './KanbanCard';
import { Plus12 } from '../Icon';
import Button from '../Button';

const ColumnContainer = styled.div`
  background: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  min-width: 280px;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const ColumnHeader = styled.div`
  padding: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TaskCount = styled.span`
  background: rgba(0, 0, 0, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
`;

const AddButton = styled(Button)`
  padding: 4px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 12px;
    height: 12px;
  }
  
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
  
  &:hover svg path {
    fill: rgba(0, 0, 0, 0.8);
  }
`;

const TaskList = styled.div<{ $isEmpty: boolean }>`
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: ${props => props.$isEmpty ? '100px' : 'auto'}; // Ensure empty columns have a drop target
`;

const EmptyColumnState = styled.div`
  padding: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 8px;
`;

interface KanbanColumnProps {
  status: TaskStatus;
  tasks: Task[];
  onTaskClick: (task: Task) => void;
  selectedTaskId: string | null;
  onCreateTask: (statusId: string) => void;
}

const KanbanColumn: React.FC<KanbanColumnProps> = ({
  status,
  tasks,
  onTaskClick,
  selectedTaskId,
  onCreateTask,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `column-${status.id}`,
  });

  const isEmpty = tasks.length === 0;

  const handleCreateTask = () => {
    onCreateTask(status.id);
  };

  return (
    <ColumnContainer>
      <ColumnHeader>
        <HeaderContent>
          {status.name}
          <TaskCount>{tasks.length}</TaskCount>
        </HeaderContent>
        <AddButton
          buttonType="secondary"
          onClick={handleCreateTask}
          title={`Add task to ${status.name}`}
        >
          <Plus12 />
        </AddButton>
      </ColumnHeader>
      <TaskList ref={setNodeRef} $isEmpty={isEmpty}>
        <SortableContext
          items={tasks.map(t => t.id)}
          strategy={verticalListSortingStrategy}
        >
          {isEmpty ? (
            <EmptyColumnState
              style={{
                backgroundColor: isOver ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
              }}
            >
              Drop tasks here
            </EmptyColumnState>
          ) : (
            tasks.map(task => (
              <KanbanCard
                key={task.id}
                task={task}
                onClick={() => onTaskClick(task)}
                isSelected={task.id === selectedTaskId}
              />
            ))
          )}
        </SortableContext>
      </TaskList>
    </ColumnContainer>
  );
};

export default KanbanColumn;
