import React from 'react';
import styled from 'styled-components';
import NumberFlow from '@number-flow/react';

const RetroWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #282020;
  height: 288px;
  display: flex;
  flex-direction: column;
`;

const RetroTimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
`;

const RetroColumn = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => props.color};
  text-align: center;
  flex: 1;
  width: 100%;
`;

const RetroTimeInputField = styled.input`
  box-sizing: border-box;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 40px;
  min-width: 128px;
  color: #F8F7F6;
  font-family: 'PP Mori';
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  font-feature-settings: 'tnum';
  padding: 5px 24px 0 40px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const RetroTimeInputLabel = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-right: 24px;
`;

const StyledNumberFlow = styled(NumberFlow)`
  box-sizing: border-box;
  background: transparent;
  border: none;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 40px;
  min-width: 128px;
  color: #F8F7F6;
  font-family: 'PP Mori';
  outline: none;
  padding: 5px 24px 0 40px;
  cursor: text;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  --number-flow-mask-height: 0.3em;
  --number-flow-char-height: 1em;
`;

interface RetroLayoutProps {
  durationInputs: { hours: string; minutes: string; seconds: string };
  isCurrentlyRunning: boolean;
  editingField: 'hours' | 'minutes' | 'seconds' | null;
  handleDurationClick: (field: 'hours' | 'minutes' | 'seconds') => void;
  handleDurationChange: (field: 'hours' | 'minutes' | 'seconds') => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDurationBlur: () => void;
}

const RetroLayout: React.FC<RetroLayoutProps> = ({
  durationInputs,
  isCurrentlyRunning,
  editingField,
  handleDurationClick,
  handleDurationChange,
  handleDurationBlur,
}) => {
  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds') => {
    const value = durationInputs[field];
    
    if (isCurrentlyRunning || editingField !== field) {
      return (
        <StyledNumberFlow
          value={value === '' ? 0 : parseInt(value, 10)}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 200, easing: 'ease-out' }}
          spinTiming={{ duration: 200, easing: 'ease-out' }}
          opacityTiming={{ duration: 200, easing: 'ease-out' }}
          onClick={() => handleDurationClick(field)}
        />
      );
    } else {
      return (
        <RetroTimeInputField
          type="number"
          value={value}
          onChange={(e) => handleDurationChange(field)(e)}
          onBlur={handleDurationBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus
        />
      );
    }
  };

  return (
    <RetroWrapper>
      <RetroTimeInputWrapper>
        <RetroColumn color="#FE8A18">
          {renderTimeInput('hours')}
          <RetroTimeInputLabel>Hrs</RetroTimeInputLabel>
        </RetroColumn>
        <RetroColumn color="#F75D18">
          {renderTimeInput('minutes')}
          <RetroTimeInputLabel>Mins</RetroTimeInputLabel>
        </RetroColumn>
        <RetroColumn color="#DD1A1E">
          {renderTimeInput('seconds')}
          <RetroTimeInputLabel>Secs</RetroTimeInputLabel>
        </RetroColumn>
      </RetroTimeInputWrapper>
    </RetroWrapper>
  );
};

export default RetroLayout;
