import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';

// Use constants outside the hook to prevent re-creation on each render
const HEARTBEAT_INTERVAL = 1800000; // 30 minutes
const SYNC_INTERVAL = 15000; // 15 seconds
const UPDATE_INTERVAL = 1000; // 1 second

interface TimeEntryUpdateData {
  client_id?: string | null;
  project_id?: string | null;
  service_id?: string | null;
  description?: string;
  start_time?: string;
  is_billable?: boolean;
}

interface StoredTimer {
  timeEntryId: string;
  startTime: string;
  description: string;
  isPaused: boolean;
  pausedAt?: number;
  elapsedTimeAtPause?: number;
  isBillable: boolean;
}

export const useTimeTracking = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [currentTimeEntryId, setCurrentTimeEntryId] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [isPaused, setIsPaused] = useState(false);
  const [isBillable, setIsBillable] = useState(true);
  const { data: organizationId } = useOrganization();
  const queryClient = useQueryClient();
  const startTimeRef = useRef<Date | null>(null);
  const pausedAtRef = useRef<number | null>(null);

  const broadcastChannelRef = useRef<BroadcastChannel | null>(null);

  // Memoize the clearLocalStorage function
  const clearLocalStorage = useCallback(() => {
    localStorage.removeItem('currentTimer');
  }, []);

  // Memoize the resetState function
  const resetState = useCallback(() => {
    setIsRunning(false);
    setIsPaused(false);
    setElapsedTime(0);
    setCurrentTimeEntryId(null);
    setDescription('');
    setIsBillable(true);
    startTimeRef.current = null;
    pausedAtRef.current = null;
    clearLocalStorage();
  }, [clearLocalStorage]);

  useEffect(() => {
    broadcastChannelRef.current = new BroadcastChannel('time-tracker');
    
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'TIMER_STOPPED') {
        resetState();
      }
    };

    broadcastChannelRef.current.addEventListener('message', handleMessage);

    return () => {
      broadcastChannelRef.current?.removeEventListener('message', handleMessage);
      broadcastChannelRef.current?.close();
    };
  }, [resetState]);

  // Memoize the saveToLocalStorage function
  const saveToLocalStorage = useCallback((data: StoredTimer) => {
    try {
      localStorage.setItem('currentTimer', JSON.stringify(data));
    } catch (error) {
      console.error('Error saving to local storage:', error);
    }
  }, []);

  // Memoize the loadFromLocalStorage function
  const loadFromLocalStorage = useCallback((): StoredTimer | null => {
    try {
      const storedTimer = localStorage.getItem('currentTimer');
      return storedTimer ? JSON.parse(storedTimer) : null;
    } catch (error) {
      console.error('Error loading from local storage:', error);
      return null;
    }
  }, []);

  // Optimize updateElapsedTime
  const updateElapsedTime = useCallback(() => {
    if (startTimeRef.current && !isPaused) {
      const now = new Date();
      setElapsedTime(Math.round((now.getTime() - startTimeRef.current.getTime()) / 1000));
    }
  }, [isPaused]);

  // Optimize sendHeartbeat
  const sendHeartbeat = useCallback(async () => {
    if (currentTimeEntryId && isRunning && !isPaused) {
      const now = new Date().toISOString();
      const { error } = await supabase
        .from('time_entries')
        .update({ last_heartbeat: now })
        .eq('id', currentTimeEntryId);

      if (error) {
        console.error('Error sending heartbeat:', error);
      }
    }
  }, [currentTimeEntryId, isRunning, isPaused]);

  // Optimize syncWithServer
  const syncWithServer = useCallback(async () => {
    if (currentTimeEntryId) {
      const { data, error } = await supabase
        .from('time_entries')
        .select('is_running, duration, end_time')
        .eq('id', currentTimeEntryId)
        .single();

      if (error) {
        console.error('Error syncing with server:', error);
      } else if (!data.is_running || data.end_time) {
        resetState();
        broadcastChannelRef.current?.postMessage({ type: 'TIMER_STOPPED' });
      } else if (isRunning && !isPaused) {
        await supabase
          .from('time_entries')
          .update({ duration: elapsedTime })
          .eq('id', currentTimeEntryId);
      }
    }
  }, [currentTimeEntryId, elapsedTime, isRunning, isPaused, resetState]);

  // Use refs for functions that don't need to trigger re-renders
  const sendHeartbeatRef = useRef(sendHeartbeat);
  const syncWithServerRef = useRef(syncWithServer);
  const updateElapsedTimeRef = useRef(updateElapsedTime);

  useEffect(() => {
    sendHeartbeatRef.current = sendHeartbeat;
    syncWithServerRef.current = syncWithServer;
    updateElapsedTimeRef.current = updateElapsedTime;
  }, [sendHeartbeat, syncWithServer, updateElapsedTime]);

  // Optimize interval management
  useEffect(() => {
    if (!isRunning || isPaused) return;

    const updateInterval = setInterval(() => updateElapsedTimeRef.current(), UPDATE_INTERVAL);
    const heartbeatInterval = setInterval(() => sendHeartbeatRef.current(), HEARTBEAT_INTERVAL);
    const syncInterval = setInterval(() => syncWithServerRef.current(), SYNC_INTERVAL);

    return () => {
      clearInterval(updateInterval);
      clearInterval(heartbeatInterval);
      clearInterval(syncInterval);
    };
  }, [isRunning, isPaused]);

  // Memoize callbacks to prevent unnecessary re-creations
  const stopTimer = useCallback(async () => {
    console.log('Stopping timer:', { currentTimeEntryId, elapsedTime });
    if (currentTimeEntryId) {
      const { data, error } = await supabase
        .from('time_entries')
        .update({ 
          end_time: new Date().toISOString(),
          duration: elapsedTime,
          is_running: false,
          last_heartbeat: null
        })
        .eq('id', currentTimeEntryId)
        .select()
        .single();

      if (error) {
        console.error('Error stopping time entry:', error);
      } else {
        console.log('Timer stopped successfully, database response:', data);
        resetState();
        queryClient.invalidateQueries({ queryKey: ['timeEntries', organizationId] });
        
        // Broadcast the stop event to other tabs
        broadcastChannelRef.current?.postMessage({ type: 'TIMER_STOPPED' });
      }
    } else {
      console.log('No current time entry to stop');
      resetState();
    }
  }, [currentTimeEntryId, elapsedTime, organizationId, queryClient, resetState]);

  // Optimize the useEffect for loading from local storage
  useEffect(() => {
    const storedTimer = loadFromLocalStorage();
    if (storedTimer) {
      const { timeEntryId, description, isBillable } = storedTimer;
      
      supabase
        .from('time_entries')
        .select('is_running, duration, start_time, pause_time, end_time')
        .eq('id', timeEntryId)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error('Error verifying time entry:', error);
            clearLocalStorage();
            return;
          }
          
          if (data && !data.end_time) {
            setCurrentTimeEntryId(timeEntryId);
            setDescription(description);
            setIsPaused(data.pause_time !== null);
            setIsRunning(data.is_running);
            setIsBillable(isBillable);
            
            if (data.pause_time) {
              setElapsedTime(data.duration || 0);
              pausedAtRef.current = new Date(data.pause_time).getTime();
              startTimeRef.current = new Date(data.start_time);
            } else if (data.is_running) {
              const now = new Date();
              const storedStartTime = new Date(data.start_time);
              const elapsedSeconds = Math.floor((now.getTime() - storedStartTime.getTime()) / 1000);
              setElapsedTime(elapsedSeconds);
              startTimeRef.current = storedStartTime;
            } else {
              setElapsedTime(data.duration || 0);
              startTimeRef.current = new Date(data.start_time);
            }
          } else {
            clearLocalStorage();
          }
        });
    }
  }, [loadFromLocalStorage, clearLocalStorage]);

  const startTimer = useCallback(async (clientId: string | null, projectId: string | null, serviceId: string | null, description: string, initialElapsedTime: number = 0, isBillable: boolean = true) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user || !organizationId) {
      console.error('No user or organization found');
      return;
    }

    const startTime = new Date();
    if (isNaN(startTime.getTime())) {
      console.error('Invalid start time');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('time_entries')
        .insert({
          organization_id: organizationId,
          user_id: user.id,
          client_id: clientId || undefined,
          project_id: projectId || undefined,
          service_id: serviceId || undefined,
          description: description || undefined,
          start_time: startTime.toISOString(),
          is_running: true,
          is_billable: isBillable,
          duration: initialElapsedTime,
          last_heartbeat: startTime.toISOString() // Add initial heartbeat
        })
        .select()
        .single();

      if (error) {
        console.error('Error starting time entry:', error);
        return;
      }

      if (data) {
        setCurrentTimeEntryId(data.id);
        setDescription(description);
        setIsRunning(true);
        setIsPaused(false);
        setElapsedTime(initialElapsedTime);
        startTimeRef.current = new Date(startTime.getTime() - initialElapsedTime * 1000);
        saveToLocalStorage({
          timeEntryId: data.id,
          startTime: startTimeRef.current.toISOString(),
          description,
          isPaused: false,
          isBillable
        });
        setIsBillable(isBillable);

        queryClient.invalidateQueries({ queryKey: ['timeEntries', organizationId] });
      }
    } catch (error) {
      console.error('Error in startTimer:', error);
    }
  }, [organizationId, queryClient, saveToLocalStorage]);

  const pauseTimer = useCallback(async () => {
    if (!currentTimeEntryId) {
      console.error('Unable to pause timer: currentTimeEntryId is missing');
      return;
    }
    if (!startTimeRef.current) {
      console.error('Unable to pause timer: startTimeRef.current is missing');
      return;
    }

    const pauseTime = new Date();
    const { error } = await supabase
      .from('time_entries')
      .update({ 
        is_running: false,
        duration: elapsedTime,
        pause_time: pauseTime.toISOString()
      })
      .eq('id', currentTimeEntryId);

    if (error) {
      console.error('Error pausing time entry:', error);
    } else {
      setIsPaused(true);
      setIsRunning(false);
      pausedAtRef.current = pauseTime.getTime();
      saveToLocalStorage({
        timeEntryId: currentTimeEntryId,
        startTime: startTimeRef.current.toISOString(),
        description,
        isPaused: true,
        pausedAt: pauseTime.getTime(),
        elapsedTimeAtPause: elapsedTime,
        isBillable
      });
    }
  }, [currentTimeEntryId, elapsedTime, description, isBillable, saveToLocalStorage]);

  const resumeTimer = useCallback(async () => {
    if (!currentTimeEntryId) {
      console.error('Unable to resume timer: currentTimeEntryId is missing');
      return;
    }
    if (!startTimeRef.current) {
      console.error('Unable to resume timer: startTimeRef.current is missing');
      return;
    }

    const resumeTime = new Date();
    let newStartTime: Date;

    if (!pausedAtRef.current) {
      console.warn('pausedAtRef.current is missing, using current elapsed time to calculate new start time');
      newStartTime = new Date(resumeTime.getTime() - Math.round(elapsedTime * 1000));
    } else {
      const pauseDuration = (resumeTime.getTime() - pausedAtRef.current);
      newStartTime = new Date(startTimeRef.current.getTime() + pauseDuration);
    }

    if (isNaN(newStartTime.getTime())) {
      console.error('Invalid new start time calculated');
      newStartTime = new Date();
    }

    try {
      const { data, error } = await supabase
        .from('time_entries')
        .update({ 
          is_running: true,
          start_time: newStartTime.toISOString(),
          pause_time: null
        })
        .eq('id', currentTimeEntryId)
        .select();

      if (error) {
        console.error('Error resuming time entry:', error);
        console.error('Current time entry ID:', currentTimeEntryId);
        console.error('New start time:', newStartTime.toISOString());
        setIsPaused(true);
        setIsRunning(false);
      } else {
        console.log('Time entry resumed successfully:', data);
        setIsPaused(false);
        setIsRunning(true);
        startTimeRef.current = newStartTime;
        pausedAtRef.current = null;
        saveToLocalStorage({
          timeEntryId: currentTimeEntryId,
          startTime: newStartTime.toISOString(),
          description,
          isPaused: false,
          isBillable
        });
      }
    } catch (error) {
      console.error('Unexpected error in resumeTimer:', error);
      setIsPaused(true);
      setIsRunning(false);
    }
  }, [currentTimeEntryId, description, isBillable, elapsedTime, saveToLocalStorage]);

  const updateTimer = useCallback(async (clientId: string | null, projectId: string | null, serviceId: string | null, newDescription: string, manualSeconds?: number, newIsBillable?: boolean) => {
    if (currentTimeEntryId) {
      const updateData: TimeEntryUpdateData = {
        client_id: clientId || null,
        project_id: projectId || null,
        service_id: serviceId || null,
        description: newDescription || undefined,
        is_billable: newIsBillable !== undefined ? newIsBillable : isBillable,
      };

      // Only update start_time if manualSeconds is provided and the timer is not running
      if (manualSeconds !== undefined && !isRunning) {
        const newStartTime = new Date(Date.now() - manualSeconds * 1000);
        updateData.start_time = newStartTime.toISOString();
        startTimeRef.current = newStartTime;
      }

      const { error } = await supabase
        .from('time_entries')
        .update(updateData)
        .eq('id', currentTimeEntryId);

      if (error) {
        console.error('Error updating time entry:', error);
      } else {
        setDescription(newDescription);
        if (manualSeconds !== undefined && !isRunning) {
          setElapsedTime(manualSeconds);
        }
        if (newIsBillable !== undefined) {
          setIsBillable(newIsBillable);
        }
        saveToLocalStorage({
          timeEntryId: currentTimeEntryId,
          startTime: startTimeRef.current?.toISOString() || new Date().toISOString(),
          description: newDescription,
          isPaused,
          isBillable: newIsBillable !== undefined ? newIsBillable : isBillable
        });
      }
    }
  }, [currentTimeEntryId, isPaused, isBillable, isRunning, saveToLocalStorage]);

  const deleteTimeEntry = useCallback(async () => {
    if (currentTimeEntryId) {
      const { error } = await supabase
        .from('time_entries')
        .delete()
        .eq('id', currentTimeEntryId);

      if (error) {
        console.error('Error deleting time entry:', error);
      } else {
        setIsRunning(false);
        setIsPaused(false);
        setElapsedTime(0);
        setCurrentTimeEntryId(null);
        setDescription('');
        localStorage.removeItem('currentTimer');
        queryClient.invalidateQueries({ queryKey: ['timeEntries', organizationId] });
      }
    }
  }, [currentTimeEntryId, organizationId, queryClient]);

  const cancelTimer = useCallback(async () => {
    if (currentTimeEntryId) {
      const { error } = await supabase
        .from('time_entries')
        .delete()
        .eq('id', currentTimeEntryId);

      if (error) {
        console.error('Error canceling time entry:', error);
      } else {
        setIsRunning(false);
        setIsPaused(false);
        setElapsedTime(0);
        setCurrentTimeEntryId(null);
        setDescription('');
        localStorage.removeItem('currentTimer');
        queryClient.invalidateQueries({ queryKey: ['timeEntries', organizationId] });
      }
    }
  }, [currentTimeEntryId, organizationId, queryClient]);

  // Optimize checkConsistency
  const checkConsistency = useCallback(async () => {
    if (currentTimeEntryId) {
      const { data, error } = await supabase
        .from('time_entries')
        .select('duration, is_running')
        .eq('id', currentTimeEntryId)
        .single();

      if (error || !data) {
        console.error('Inconsistency detected:', error);
        stopTimer();
      } else {
        setElapsedTime(data.duration);
        setIsRunning(data.is_running);
      }
    }
  }, [currentTimeEntryId, stopTimer]);

  // Reduce the frequency of consistency checks
  useEffect(() => {
    const consistencyInterval = setInterval(checkConsistency, 10 * 60 * 1000); // Check every 10 minutes
    return () => clearInterval(consistencyInterval);
  }, [checkConsistency]);

  // Add this useEffect to listen for storage events and synchronize state across tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'currentTimer' && event.newValue) {
        try {
          const newTimer: StoredTimer = JSON.parse(event.newValue);
          
          // Update local state based on the new timer data
          setCurrentTimeEntryId(newTimer.timeEntryId);
          setDescription(newTimer.description);
          setIsPaused(newTimer.isPaused);
          setIsBillable(newTimer.isBillable);

          if (newTimer.isPaused && newTimer.elapsedTimeAtPause !== undefined) {
            setElapsedTime(newTimer.elapsedTimeAtPause);
            pausedAtRef.current = newTimer.pausedAt || null;
          } else if (!newTimer.isPaused && newTimer.startTime) {
            const startTime = new Date(newTimer.startTime);
            setElapsedTime(Math.floor((Date.now() - startTime.getTime()) / 1000));
            startTimeRef.current = startTime;
          } else {
            setElapsedTime(0);
            setCurrentTimeEntryId(null);
            setDescription('');
            startTimeRef.current = null;
            pausedAtRef.current = null;
          }

          setIsRunning(!newTimer.isPaused && newTimer.timeEntryId !== null);
        } catch (error) {
          console.error('Error parsing currentTimer from storage:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Use useMemo for complex calculations or objects that don't need to be recreated on every render
  const timerState = useMemo(() => ({
    isRunning,
    isPaused,
    elapsedTime,
    description,
    isBillable,
    currentTimeEntryId
  }), [isRunning, isPaused, elapsedTime, description, isBillable, currentTimeEntryId]);

  return { 
    ...timerState,
    startTimer, 
    stopTimer, 
    updateTimer, 
    setDescription, 
    deleteTimeEntry, 
    pauseTimer, 
    resumeTimer,
    cancelTimer,
    setIsBillable,
    resetState
  };
};
