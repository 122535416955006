import { GroupedTimeEntry } from '../types';

export function formatDuration(durationInSeconds: number): string {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;
  
  if (hours === 0 && minutes === 0) {
    return `${seconds} Secs`;
  } else if (hours === 0) {
    return `${minutes} Mins`;
  } else if (minutes === 0 && seconds === 0) {
    return `${hours} Hrs`;
  } else {
    return `${hours} Hrs ${minutes} Mins`;
  }
}

export function sumDurations(durations: number[]): number {
  return durations.reduce((sum, duration) => sum + duration, 0);
}

export function groupTimeEntriesByProjectAndService(timeEntries: GroupedTimeEntry[]): GroupedTimeEntry[] {
  const groupedEntries: { [key: string]: GroupedTimeEntry } = {};

  timeEntries.forEach(entry => {
    const key = `${entry.project_id}-${entry.service_id}`;
    if (!groupedEntries[key]) {
      groupedEntries[key] = {
        ...entry,
        duration: 0,
        description: '',
        start_time: entry.start_time,
        end_time: entry.end_time,
      };
    }

    const group = groupedEntries[key];
    group.duration += entry.duration;
    group.description += (group.description ? '\n' : '') + (entry.description || '');
    group.start_time = new Date(Math.min(new Date(group.start_time).getTime(), new Date(entry.start_time).getTime())).toISOString();
    
    if (entry.end_time && group.end_time) {
      group.end_time = new Date(Math.max(new Date(group.end_time).getTime(), new Date(entry.end_time).getTime())).toISOString();
    } else {
      group.end_time = entry.end_time || group.end_time;
    }
  });

  return Object.values(groupedEntries);
}