import React, { useState, useEffect, useCallback, useMemo, useRef, Suspense, lazy } from 'react';
import styled from 'styled-components';
import { useTimeEntries } from './hooks/useTimeEntries';
import { useOrganization } from './hooks/useOrganization';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { useClients } from './hooks/useClients';
import { useProjects } from './hooks/useProjects';
import { useCreateInvoiceMutation } from './hooks/useCreateInvoiceMutation';
import { useTimeTracking } from './hooks/useTimeTracking';
import { usePageContext } from './hooks/usePageContext';
import { TimeEntry, GroupedTimeEntry } from './types';
import { parseISO, format, isSameYear, startOfWeek, endOfWeek, subDays } from 'date-fns';
import { supabase } from './supabaseClient';
import { Link, useNavigate } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import Button from './components/Button';
import ErrorBoundary from './components/ErrorBoundary';
import Tabs from './components/Tabs';
import SelectedTimeEntriesOverlay from './components/Time/SelectedTimeEntriesOverlay';
import { formatDuration, sumDurations } from './utils/timeUtils';
import { useCreateTimeEntryMutation } from './hooks/useCreateTimeEntryMutation';
import TimeEntryTable from './components/Time/TimeEntryTable';
import { groupTimeEntriesByProjectAndService } from './utils/timeUtils';
import InvoiceDetails from './components/Invoices/InvoiceDetails';
import { useInvoice } from './hooks/useInvoiceHooks';
import { usePayments } from './hooks/usePayments';
import SelectClientModal from './components/Time/SelectClientModal';
import { uniq } from 'lodash';
import { useInView } from 'react-intersection-observer';
import DateRangePicker from './components/DateRangePicker';
import FilterDrawer from './components/FilterDrawer';
import FilterBox from './components/FilterBox';
import FilterPicker from './components/FilterPicker';
import { Client12, Calendar12, Time32 } from './components/Icon';
import { useMediaQuery } from './hooks/useMediaQuery';
import MultipleEntityPicker from './components/MultipleEntityPicker';
import SummaryCards from './components/SummaryCards';
import { debounce } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';

// Add this interface near the top of the file, with your other type definitions
interface InvoiceItem {
  id: string;
  project_id: string;
  service_id: string;
  // Add other properties as needed
}

interface FilterOption {
  id: string;
  label: string;
  type: string;
  options?: string[];
}

interface Filter {
  id: string;
  label: string;
  value: string | string[] | { startDate: Date | null; endDate: Date | null };
}

type FilterId = 'client' | 'project' | 'date';

// Add this type definition near the top of your file, with other type definitions
type TimeEntriesQueryData = {
  pages: {
    data: TimeEntry[];
  }[];
};

const PageWrapper = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
`;

const FilterBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const AddTimeEntryDrawer = lazy(() => import('./components/Time/AddTimeEntryDrawer'));
const InvoiceDrawer = lazy(() => import('./components/Invoices/InvoiceDrawer'));

// Memoize child components
const MemoizedTimeEntryTable = React.memo(TimeEntryTable);
const MemoizedFilterBox = React.memo(FilterBox);
const MemoizedFilterPicker = React.memo(FilterPicker);

const TimeEntriesPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const { data: organizationId } = useOrganization();
  const { data: users, isLoading: isLoadingUsers, error: userError } = useOrganizationUsers();
  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const createInvoiceMutation = useCreateInvoiceMutation();
  const navigate = useNavigate();
  const { stopTimer, pauseTimer, resumeTimer, currentTimeEntryId } = useTimeTracking();
  const queryClient = useQueryClient();
  const createTimeEntryMutation = useCreateTimeEntryMutation();

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [selectedEntriesCount, setSelectedEntriesCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('all');
  const [defaultStartTime, setDefaultStartTime] = useState<string | undefined>(undefined);
  const [defaultEndTime, setDefaultEndTime] = useState<string | undefined>(undefined);
  const [localTimeEntries, setLocalTimeEntries] = useState<GroupedTimeEntry[]>([]);
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>(undefined);
  const [isSelectClientModalOpen, setIsSelectClientModalOpen] = useState(false);

  const [filters, setFilters] = useState<Filter[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const { data: selectedInvoice } = useInvoice(selectedInvoiceId || '');
  const { data: payments } = usePayments();
  const { data: emailTrackingData } = useEmailTrackingData(selectedInvoiceId || null);
  const { infiniteQuery, summaryQuery } = useTimeEntries({
    clientId: filters.find(f => f.id === 'client')?.value as string[] || undefined,
    projectId: filters.find(f => f.id === 'project')?.value as string[] || undefined,
    startDate: (filters.find(f => f.id === 'date')?.value as { startDate: Date | null; endDate: Date | null })?.startDate?.toISOString(),
    endDate: (filters.find(f => f.id === 'date')?.value as { startDate: Date | null; endDate: Date | null })?.endDate?.toISOString(),
  });
  const { ref: loadMoreRef, inView } = useInView();

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const filterOptions = useMemo<FilterOption[]>(() => [
    { id: 'client', label: 'Client', type: 'multipleEntity' },
    { id: 'project', label: 'Project', type: 'multipleEntity' },
    { 
      id: 'date', 
      label: 'Date', 
      type: 'dateRange',
      options: [
        'Last Week',
        'Last 30 Days',
        'This Month',
        'Last Month',
        'Last 6 Months',
        'Last Year'
      ]
    },
  ], []);

  const handleAddFilter = useCallback((filterId: FilterId) => {
    if (!filters.some(filter => filter.id === filterId)) {
      const filterOption = filterOptions.find(option => option.id === filterId);
      if (filterOption) {
        const newFilter: Filter = {
          id: filterId,
          label: filterOption.label,
          value: filterId === 'client' || filterId === 'project' ? [] : 
                 filterId === 'date' ? { startDate: null, endDate: null } : ''
        };
        setFilters(prevFilters => [...prevFilters, newFilter]);
      }
    }
  }, [filters, filterOptions]);

  const handleRemoveFilter = useCallback((filterId: FilterId) => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
  }, []);

  const handleFilterChange = useCallback((filterId: FilterId, value: Filter['value']) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, value } : filter
      )
    );
  }, []);

  const getFilterOptions = (filterId: string) => {
    const filter = filterOptions.find(f => f.id === filterId);
    return filter?.options;
  };

  const clientEntities = useMemo(() => {
    return clients?.map(client => ({
      id: client.id,
      name: client.full_name
    })) || [];
  }, [clients]);

  const projectEntities = useMemo(() => {
    return projects?.map(project => ({
      id: project.id,
      name: project.name
    })) || [];
  }, [projects]);

  const handleApplyFilters = () => {
    setIsFilterDrawerOpen(false);
  };

  // Flatten the pages of time entries
  const timeEntries = useMemo(() => {
    return infiniteQuery.data?.pages.flatMap(page => page.data) || [];
  }, [infiniteQuery.data]);

  // Update the filterTimeEntries function to return filtered entries instead of setting state
  const filterTimeEntries = useCallback((entries: GroupedTimeEntry[], tab: string) => {
    const now = new Date();
    const weekStart = startOfWeek(now);
    const weekEnd = endOfWeek(now);
    const thirtyDaysAgo = subDays(now, 30);

    switch (tab) {
      case 'this-week':
        return entries.filter(entry => {
          const entryDate = parseISO(entry.start_time);
          return entryDate >= weekStart && entryDate <= weekEnd;
        });
      case 'last-30-days':
        return entries.filter(entry => {
          const entryDate = parseISO(entry.start_time);
          return entryDate >= thirtyDaysAgo && entryDate <= now;
        });
      case 'not-invoiced':
        return entries.filter(entry => !entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id);
      case 'invoiced':
        return entries.filter(entry => entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id);
      default:
        return entries;
    }
  }, []);

  // Update the handleTabChange function
  const handleTabChange = useCallback((newTab: string) => {
    setActiveTab(newTab);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, []);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsDrawerOpen(true);
  }, []);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.time-entry-item')) {
      handleCloseDrawer();
    }
  }, [handleCloseDrawer]);

  const formatGroupDate = useCallback((date: Date) => {
    return isSameYear(date, new Date())
      ? format(date, 'EEE, MMM d')
      : format(date, 'EEE, MMM d, yyyy');
  }, []);

  // Add the `getRowKey` declaration inside the component
  const getRowKey = useCallback((entry: GroupedTimeEntry) => entry.id, []);

  // Memoize the createInvoice function to prevent unnecessary re-creations
  const createInvoice = useCallback(async (clientId: string) => {
    try {
      const selectedTimeEntries = timeEntries!.filter((entry: GroupedTimeEntry) => 
        selectedEntries.includes(getRowKey(entry))
      );

      // Update time entries to match the selected client
      const updatedTimeEntries = await Promise.all(selectedTimeEntries.map(async (entry) => {
        if (entry.client_id !== clientId) {
          const { data, error } = await supabase
            .from('time_entries')
            .update({ client_id: clientId })
            .eq('id', entry.id)
            .select()
            .single();

          if (error) throw error;
          return { ...entry, ...data };
        }
        return entry;
      }));

      // Group time entries by project and service
      const groupedTimeEntries = groupTimeEntriesByProjectAndService(updatedTimeEntries);
      console.log('Grouped Time Entries:', groupedTimeEntries);

      const newInvoice = await createInvoiceMutation.mutateAsync({
        organization_id: organizationId,
        client_id: clientId,
        timeEntries: groupedTimeEntries
      });

      console.log('New Invoice:', newInvoice);

      if (!newInvoice) {
        throw new Error('Failed to create invoice: newInvoice is undefined');
      }

      // Fetch invoice items for the newly created invoice
      const { data: invoiceItems, error: invoiceItemsError } = await supabase
        .from('invoice_items')
        .select('*')
        .eq('invoice_id', newInvoice.id);

      if (invoiceItemsError) {
        throw new Error(`Failed to fetch invoice items: ${invoiceItemsError.message}`);
      }

      if (!invoiceItems || invoiceItems.length === 0) {
        throw new Error('No invoice items created');
      }

      console.log('Invoice Items:', invoiceItems);

      // Create time_entry_invoice_items for all individual time entries
      const timeEntryInvoiceItemPromises = updatedTimeEntries.map((timeEntry) => {
        const invoiceItem = invoiceItems.find((item: InvoiceItem) => 
          (!item.project_id || item.project_id === timeEntry.project_id) &&
          (!item.service_id || item.service_id === timeEntry.service_id)
        );

        if (invoiceItem) {
          return supabase.from('time_entry_invoice_items').insert({
            time_entry_id: timeEntry.id,
            invoice_item_id: invoiceItem.id
          });
        } else {
          console.warn(`No matching invoice item found for time entry ${timeEntry.id} with project_id ${timeEntry.project_id} and service_id ${timeEntry.service_id}`);
          return Promise.resolve();
        }
      });

      await Promise.all(timeEntryInvoiceItemPromises);

      setSelectedEntries([]);
      queryClient.invalidateQueries({ queryKey: ['timeEntries'] });
      navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
    } catch (error: unknown) {
      console.error('Error creating invoice:', error);
      alert(`Failed to create invoice. Error: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }, [createInvoiceMutation, getRowKey, navigate, organizationId, queryClient, selectedEntries, timeEntries]);

  // Optimize the `columns` definition to include necessary dependencies
  const columns: ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }>[] = useMemo(() => [
    {
      id: 'duration',
      accessorKey: 'duration',
      header: 'Duration',
      cell: ({ row }) => {
        const entry = row.original as GroupedTimeEntry;
        const duration = entry.duration;
        const endTime = entry.end_time;
        if (!endTime) {
          // Calculate duration for running entries
          const startTime = parseISO(entry.start_time);
          const now = new Date();
          const runningDuration = Math.floor((now.getTime() - startTime.getTime()) / 1000);
          return formatDuration(runningDuration);
        }
        return formatDuration(duration);
      },
    },
    {
      id: 'time',
      accessorFn: (row) => {
        const entry = row as GroupedTimeEntry;
        return { start: entry.start_time, end: entry.end_time };
      },
      header: 'Time',
      cell: ({ getValue }) => {
        const { start, end } = getValue() as { start: string; end: string | null };
        const startTime = format(parseISO(start), 'h:mma');
        if (!end) {
          return <span>{startTime} - <span style={{ color: 'green' }}>Running</span></span>;
        }
        const endTime = format(parseISO(end), 'h:mma');
        return `${startTime} - ${endTime}`;
      },
    },
    {
      id: 'client',
      accessorKey: 'client',
      header: 'Client',
      cell: ({ row }) => {
        const entry = row.original as Partial<GroupedTimeEntry>;
        return entry.client ? entry.client.full_name : '';
      },
    },
    {
      id: 'project.name',
      accessorKey: 'project.name',
      header: 'Project',
      cell: ({ row }) => {
        const entry = row.original as Partial<GroupedTimeEntry>;
        return entry.project?.name || '';
      },
    },
    {
      id: 'service.name',
      accessorKey: 'service.name',
      header: 'Service',
      cell: ({ row }) => {
        const entry = row.original as Partial<GroupedTimeEntry>;
        return entry.service?.name || '';
      },
    },
    {
      id: 'user_full_name',
      accessorKey: 'user_full_name',
      header: 'User',
      cell: ({ getValue }) => getValue() || '',
    },
    {
      id: 'invoice',
      accessorKey: 'invoiceId',
      header: 'Invoice',
      align: 'right',
      cell: ({ getValue }) => {
        const invoiceId = getValue() as string | null;
        return invoiceId ? (
          <Link 
            to="/invoice/$id" 
            params={{ id: invoiceId }}
            search={{ from: '/time-entries' }}
          >
            View Invoice
          </Link>
        ) : (
          'Not Invoiced'
        );
      },
    },
  ], []);

  // Memoize the `handleCreateInvoice` to depend on memoized `createInvoice`
  const handleCreateInvoice = useCallback(async () => {
    if (selectedEntries.length === 0 || !timeEntries) {
      alert('Please select at least one time entry');
      return;
    }

    const selectedTimeEntries = timeEntries.filter((entry: GroupedTimeEntry) => 
      selectedEntries.includes(getRowKey(entry))
    );

    if (selectedTimeEntries.length === 0) {
      alert('No matching time entries found for selection');
      return;
    }

    const uniqueClientIds = uniq(selectedTimeEntries.map(entry => entry.client_id));

    if (uniqueClientIds.length > 1) {
      setIsSelectClientModalOpen(true);
      return;
    }

    await createInvoice(uniqueClientIds[0]);
  }, [createInvoice, getRowKey, selectedEntries, timeEntries]);

  // Update the tabCounts calculation
  const tabCounts = useMemo(() => {
    if (summaryQuery.isLoading || !summaryQuery.data || !Array.isArray(summaryQuery.data) || summaryQuery.data.length === 0) {
      return { all: 0, notInvoiced: 0, invoiced: 0 };
    }
    const summaryData = summaryQuery.data[0];
    return {
      all: summaryData.all_count || 0,
      notInvoiced: summaryData.not_invoiced_count || 0,
      invoiced: summaryData.invoiced_count || 0,
    };
  }, [summaryQuery.data, summaryQuery.isLoading]);

  const tabs = useMemo(() => [
    { id: 'all', label: 'All', count: tabCounts.all ?? undefined },
    { id: 'not-invoiced', label: 'Not Invoiced', count: tabCounts.notInvoiced ?? undefined },
    { id: 'invoiced', label: 'Invoiced', count: tabCounts.invoiced ?? undefined },
  ], [tabCounts]);

  const handleEntryClick = useCallback((entry: GroupedTimeEntry) => {
    setSelectedTimeEntryId(entry.id);
    setDefaultStartTime(entry.start_time);
    setDefaultEndTime(entry.end_time || undefined);
    setIsDrawerOpen(true);
  }, []);

  const handleInvoiceClick = useCallback((invoiceId: string) => {
    setSelectedInvoiceId(invoiceId!);
    setIsInvoiceDrawerOpen(true);
  }, []);

  

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['timeEntries'] });
      if (currentTimeEntryId && timeEntryId === currentTimeEntryId) {
        await stopTimer();
      }
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [currentTimeEntryId, queryClient, stopTimer]);

  const handleClientSelect = (clientId: string) => {
    setIsSelectClientModalOpen(false);
    createInvoice(clientId);
  };

  const handleSaveTimeEntry = async () => {
    await stopTimer();
    setIsDrawerOpen(false);
    setSelectedTimeEntryId(null);
  };

  const handleCreateTimeEntryMutation = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    if (!organizationId || !users) {
      throw new Error('Organization ID or user is not available');
    }

    const newTimeEntry: Partial<TimeEntry> = {
      ...timeEntryData,
      organization_id: organizationId,
      user_id: users[0].id,
      start_time: timeEntryData.start_time || new Date().toISOString(),
      end_time: timeEntryData.end_time || new Date(Date.now() + 3600000).toISOString(), // Default to 1 hour duration
      duration: timeEntryData.duration || 3600, // Default to 1 hour in seconds
      is_billable: timeEntryData.is_billable ?? true,
    };

    try {
      const createdTimeEntry = await createTimeEntryMutation.mutateAsync(newTimeEntry);
      
      // Convert the created TimeEntry to a GroupedTimeEntry
      const groupedCreatedEntry: GroupedTimeEntry = {
        ...createdTimeEntry,
        groupDate: formatGroupDate(new Date(createdTimeEntry.start_time)),
        user_full_name: users.find(u => u.id === createdTimeEntry.user_id)?.full_name || 'N/A',
        invoiceId: null // Assuming new entries are not invoiced
      };

      setLocalTimeEntries(prev => [...prev, groupedCreatedEntry]);
      return createdTimeEntry;
    } catch (error) {
      console.error('Error creating time entry:', error);
      throw error;
    }
  }, [organizationId, users, createTimeEntryMutation, formatGroupDate]);

  const isEditingRef = useRef(false);

  // Add this new mutation
  const updateTimeEntryMutation = useMutation({
    mutationFn: async (updatedTimeEntry: Partial<TimeEntry>) => {
      const { data, error } = await supabase
        .from("time_entries")
        .update(updatedTimeEntry)
        .eq("id", updatedTimeEntry.id)
        .select();
      if (error) throw error;
      return data[0] as TimeEntry;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["timeEntries"], (oldData: TimeEntriesQueryData | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((entry: TimeEntry) =>
              entry.id === data.id ? { ...entry, ...data } : entry
            ),
          })),
        };
      });
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  const debouncedUpdateTimeEntry = useRef(
    debounce(
      (id: string, changes: Partial<TimeEntry>, isEditing: boolean) => {
        updateTimeEntryMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  // Update the existing updateLocalTimeEntry function
  const updateLocalTimeEntry = useCallback((updatedEntry: Partial<GroupedTimeEntry>) => {
    isEditingRef.current = true;
    setLocalTimeEntries(prevEntries => 
      prevEntries.map(entry => 
        entry.id === updatedEntry.id ? { ...entry, ...updatedEntry } : entry
      )
    );
    
    queryClient.setQueryData(['timeEntries'], (oldData: { pages: { data: GroupedTimeEntry[] }[] } | undefined) => {
      if (!oldData) return oldData;
      const updatedPages = oldData.pages.map((page) => ({
        ...page,
        data: page.data.map((entry: GroupedTimeEntry) =>
          entry.id === updatedEntry.id ? { ...entry, ...updatedEntry } : entry
        ),
      }));
      return { ...oldData, pages: updatedPages };
    });

    // Call the debounced update function
    if (updatedEntry.id) {
      debouncedUpdateTimeEntry(updatedEntry.id, updatedEntry, isEditingRef.current);
    }
  }, [queryClient, debouncedUpdateTimeEntry]);

  const emptyState = useMemo(() => ({
    icon: <Time32 />,
    message: 'No time entries found',
    subMessage: 'Start tracking your time or adjust your filters to see entries.',
    action: {
      label: 'New Time Entry',
      onClick: handleAddNewTimeEntry
    }
  }), [handleAddNewTimeEntry]);

  const handleCloseInvoiceDrawer = useCallback(() => {
    setIsInvoiceDrawerOpen(false);
    setSelectedInvoiceId(undefined);
  }, []);

  // Effect for setting page header props
  useEffect(() => {
    setPageHeaderProps({
      title: "Time",
      right: (
        <Button buttonType="primary" onClick={handleAddNewTimeEntry}>
          {isSmallScreen ? "New" : "New Time Entry"}
        </Button>
      ),
    });
  }, [setPageHeaderProps, handleAddNewTimeEntry, isSmallScreen]);

  // Effect for infinite query
  useEffect(() => {
    if (inView && infiniteQuery.hasNextPage && !infiniteQuery.isFetchingNextPage) {
      infiniteQuery.fetchNextPage();
    }
  }, [inView, infiniteQuery.hasNextPage, infiniteQuery.isFetchingNextPage, infiniteQuery.fetchNextPage]);

  // Effect for updating local time entries
  useEffect(() => {
    if (infiniteQuery.data) {
      const allTimeEntries = infiniteQuery.data.pages.flatMap(page => page.data);
      setLocalTimeEntries(allTimeEntries);
    }
  }, [infiniteQuery.data]);

  // Memoize the groupTimeEntries function to prevent unnecessary recalculations
  const groupTimeEntries = useMemo(() => (entries: TimeEntry[]): (GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number })[] => {
    const grouped: { [key: string]: GroupedTimeEntry[] } = {};
    entries.forEach(entry => {
      const groupDate = format(parseISO(entry.start_time), 'yyyy-MM-dd');
      if (!grouped[groupDate]) {
        grouped[groupDate] = [];
      }
      grouped[groupDate].push({
        ...entry,
        groupDate,
        user_full_name: users?.find(u => u.id === entry.user_id)?.full_name || 'N/A',
        invoiceId: entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null
      });
    });
    return Object.entries(grouped).flatMap(([date, entries]) => [
      { id: date, isGroupHeader: true, totalDuration: sumDurations(entries.map(e => e.duration)) },
      ...entries
    ]);
  }, [users]); // Only recreate when users changes

  // Add dependency arrays to effects that use users data
  useEffect(() => {
    if (infiniteQuery.data && users) {
      const allTimeEntries = infiniteQuery.data.pages.flatMap(page => page.data);
      setLocalTimeEntries(allTimeEntries);
    }
  }, [infiniteQuery.data, users]);

  const handleSelectionChange = useCallback((selectedEntries: string[], count: number) => {
    setSelectedEntries(selectedEntries);
    setSelectedEntriesCount(count);
  }, []);

  const handleBulkClientChange = useCallback((clientId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, client_id: clientId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkProjectChange = useCallback((projectId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, project_id: projectId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkDelete = useCallback(() => {
    if (selectedEntries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedEntries.length} time entry(ies)?`)) {
      selectedEntries.forEach(id => handleDeleteTimeEntry(id));
      setSelectedEntries([]);
      setSelectedEntriesCount(0);
    }
  }, [selectedEntries, handleDeleteTimeEntry]);

  const summaries = useMemo(() => {
    if (!summaryQuery.data || !Array.isArray(summaryQuery.data) || summaryQuery.data.length === 0) {
      return { thisWeekHours: '0h 0m', last30DaysHours: '0h 0m' };
    }
    const summaryData = summaryQuery.data[0];
    return {
      thisWeekHours: formatDuration(summaryData.this_week_seconds || 0),
      last30DaysHours: formatDuration(summaryData.last_30_days_seconds || 0),
    };
  }, [summaryQuery.data]);

  const handleSummaryCardClick = useCallback((tabId: string) => {
    setActiveTab(prevTab => prevTab === tabId ? 'all' : tabId);
  }, []);

  // Create a new memoized variable for filtered entries
  const filteredTimeEntries = useMemo(() => {
    return filterTimeEntries(localTimeEntries, activeTab);
  }, [filterTimeEntries, localTimeEntries, activeTab]);

  const isInitialLoading = infiniteQuery.isInitialLoading || summaryQuery.isLoading;
  const isLoading = infiniteQuery.isLoading || infiniteQuery.isFetching || summaryQuery.isLoading;

  // Update the summaries calculation to return an array of card props
  const summaryCards = useMemo(() => [
    {
      title: "This Week",
      amount: summaries.thisWeekHours,
      onClick: () => handleSummaryCardClick('this-week'),
      isSelected: activeTab === 'this-week'
    },
    {
      title: "Last 30 Days",
      amount: summaries.last30DaysHours,
      onClick: () => handleSummaryCardClick('last-30-days'),
      isSelected: activeTab === 'last-30-days'
    }
  ], [summaries, handleSummaryCardClick, activeTab]);

  const [currentDurations, setCurrentDurations] = useState<{ [id: string]: number }>({});

  useEffect(() => {
    const timers: { [id: string]: NodeJS.Timeout } = {};

    localTimeEntries.forEach(entry => {
      if (entry.start_time && !entry.end_time) {
        if (!timers[entry.id]) {
          timers[entry.id] = setInterval(() => {
            setCurrentDurations(prev => ({
              ...prev,
              [entry.id]: prev[entry.id] ? prev[entry.id] + 1 : Math.floor((Date.now() - new Date(entry.start_time).getTime()) / 1000),
            }));
          }, 1000);
        }
      } else {
        if (timers[entry.id]) {
          clearInterval(timers[entry.id]);
          delete timers[entry.id];
        }
      }
    });

    return () => {
      Object.values(timers).forEach(clearInterval);
    };
  }, [localTimeEntries]);

  if (isLoadingUsers) return null;
  if (userError) return <div>Error loading users: {userError.message}</div>;

  return (
    <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
      <PageWrapper ref={containerRef}>
        <SummaryCards cards={summaryCards} />
        <Tabs 
          tabs={tabs} 
          activeTab={activeTab} 
          onTabChange={handleTabChange}
          actionButtons={
            <FilterContainer>
              <FilterBoxesWrapper>
                {filters.map(filter => (
                  <MemoizedFilterBox
                    key={filter.id}
                    filter={filter as import('./types').Filter}
                    onRemove={() => handleRemoveFilter(filter.id as FilterId)}
                    onChange={(value) => handleFilterChange(filter.id as FilterId, value)}
                    options={getFilterOptions(filter.id)}
                    entities={filter.id === 'client' ? clientEntities : 
                              filter.id === 'project' ? projectEntities : undefined}
                  />
                ))}
              </FilterBoxesWrapper>
              <MemoizedFilterPicker
                options={filterOptions as import('./types').FilterOption[]}
                onAddFilter={handleAddFilter as (filterId: import('./types').FilterId) => void}
                activeFilters={filters.map(filter => filter.id)}
              />
            </FilterContainer>
          }
        />
        <MemoizedTimeEntryTable
          timeEntries={groupTimeEntries(filteredTimeEntries)}
          columns={columns}
          isLoading={isLoading}
          isInitialLoading={isInitialLoading}
          onRowClick={handleEntryClick}
          onImmediateUpdate={updateLocalTimeEntry}
          getRowKey={getRowKey}
          clients={clients || []}
          projects={projects || []}
          organizationUsers={users || []}
          onEntriesUpdate={setLocalTimeEntries}
          containerRef={containerRef}
          emptyState={emptyState}
          onDeleteEntry={handleDeleteTimeEntry}
          onSelectionChange={handleSelectionChange}
          onInvoiceClick={handleInvoiceClick}
          hasNextPage={infiniteQuery.hasNextPage}
          isFetchingNextPage={infiniteQuery.isFetchingNextPage}
          fetchNextPage={infiniteQuery.fetchNextPage}
          timeFormat="decimal"
          currentDurations={currentDurations}
        />
        {infiniteQuery.hasNextPage && (
          <div ref={loadMoreRef}>
            {infiniteQuery.isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </div>
        )}
        <Suspense>
            <AddTimeEntryDrawer
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
              timeEntry={localTimeEntries.find((entry: TimeEntry) => entry.id === selectedTimeEntryId) || null}
              onSave={handleSaveTimeEntry}
              onDelete={handleDeleteTimeEntry}
              onCreate={handleCreateTimeEntryMutation}
              organizationId={organizationId}
              onOverlayClick={handleOverlayClick}
              stopTimer={stopTimer}
              pauseTimer={pauseTimer}
              resumeTimer={resumeTimer}
              defaultStartTime={defaultStartTime}
              defaultEndTime={defaultEndTime}
              updateLocalTimeEntry={updateLocalTimeEntry}
              currentTimeEntryId={currentTimeEntryId}
            />
          <InvoiceDrawer
            isOpen={isInvoiceDrawerOpen}
            setIsOpen={setIsInvoiceDrawerOpen}
            selectedInvoice={selectedInvoice || null}
            onOverlayClick={handleCloseInvoiceDrawer}
            onPopoverOpenChange={() => {}}
            title="Invoice Details"
          >
            {selectedInvoice && (
              <InvoiceDetails
                invoice={selectedInvoice}
                clients={clients || []}
                navigate={navigate}
                queryClient={queryClient}
                payments={payments || []}
                emailTrackingData={emailTrackingData}
              />
            )}
          </InvoiceDrawer>
        </Suspense>
        <SelectedTimeEntriesOverlay
          selectedCount={selectedEntriesCount}
          clients={clients || []}
          projects={projects || []}
          onClientChange={handleBulkClientChange}
          onProjectChange={handleBulkProjectChange}
          onDelete={handleBulkDelete}
          onClearSelection={() => {
            setSelectedEntries([]);
            setSelectedEntriesCount(0);
          }}
          onCreateInvoice={handleCreateInvoice}
        />
        <SelectClientModal
          isOpen={isSelectClientModalOpen}
          onClose={() => setIsSelectClientModalOpen(false)}
          clients={clients || []}
          onClientSelect={handleClientSelect}
        />
        {isSmallScreen && (
          <FilterDrawer
            isOpen={isFilterDrawerOpen}
            setIsOpen={setIsFilterDrawerOpen}
            onApply={handleApplyFilters}
          >
            <MultipleEntityPicker
              selectedIds={filters.find(filter => filter.id === 'client')?.value as string[] || []}
              onChange={(value) => handleFilterChange('client', value)}
              entities={clientEntities}
              label="Clients"
              icon={<Client12 />}
              placement="bottom-start"
            />
            <MultipleEntityPicker
              selectedIds={filters.find(filter => filter.id === 'project')?.value as string[] || []}
              onChange={(value) => handleFilterChange('project', value)}
              entities={projectEntities}
              label="Projects"
              icon={<Client12 />}
              placement="bottom-start"
            />
            <DateRangePicker
              selectedRange={filters.find(filter => filter.id === 'date')?.value as { startDate: Date | null; endDate: Date | null } || { startDate: null, endDate: null }}
              onChange={(newRange) => handleFilterChange('date', newRange)}
              label="Date Range"
              id="time-entries-date-range"
              variant="preview"
              icon={<Calendar12 />}
            />
          </FilterDrawer>
        )}
      </PageWrapper>
    </ErrorBoundary>
  );
};

export default TimeEntriesPage;
