import styled from 'styled-components';

export const StatusBadge = styled.span<{ $status: string }>`
  padding: 4px 8px;
  border-radius: 24px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
  user-select: none;
  
  ${({ $status }) => {
    switch ($status) {
      case 'paid':
        return `
          background-color: rgba(30, 126, 52, 0.1);
          color: #1e7e34;
        `;
      case 'unpaid':
        return `
          background-color: rgba(242, 157, 65, 0.2);
          color: rgba(228, 121, 6, 0.926);
        `;
      case 'overdue':
        return `
          background-color: #fde7e9;
          color: #d73a49;
        `;
      case 'draft':
        return `
          background-color: rgba(0,0,0,0.05);
          color: rgba(0,0,0,0.8);
        `;
      default:
        return `
          background-color: #e9ecef;
          color: #495057;
        `;
    }
  }}
`;