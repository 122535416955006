import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from '@floating-ui/react';

const ColorInputPreview = styled.div<{ disabled?: boolean }>`
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  border-radius: 8px;
  align-items: center;
  position: relative;
  width: 100px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  opacity: ${props => props.disabled ? 0.5 : 1};

  &:hover {
    background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)'};
  }

  &:active {
    transform: ${props => props.disabled ? 'none' : 'scale(0.99)'};
  }
`;

const ColorPreview = styled.div.attrs<{ color: string }>(props => ({
  style: {
    backgroundColor: props.color,
  },
}))`
  min-width: 18px;
  min-height: 18px;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.15);
  border-radius: 4px;
  margin: 6px;
  position: absolute;
  user-select: none;
  pointer-events: none;
`;

const ColorInput = styled.input`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  height: 20px;
  padding: 4px 4px 4px 32px;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
`;

const PopoverContainer = styled.div`
`;

const PopoverContent = styled.div.attrs<{ 
  $transformOrigin: string,
  style?: React.CSSProperties 
}>(props => ({
  style: {
    ...props.style,
    transformOrigin: props.$transformOrigin,
  },
}))`
  transition: transform 0.1s ease, opacity 0.1s ease;
  z-index: 1000;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
`;

const ColorTextInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  box-sizing: border-box;
`;

const Label = styled.label`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  user-select: none;
`;

const StyledColorPicker = styled(HexColorPicker)`
  width: 100%;
  height: 200px !important;

  .react-colorful__saturation {
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .react-colorful__hue {
    height: 12px;
    border-radius: 4px;
  }

  .react-colorful__pointer {
    width: 20px;
    height: 20px;
  }
`;

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

interface ColorPickerWithPopoverProps {
  color: string;
  onChange: (color: string) => void;
  onImmediateChange: (color: string) => void;
  label: string;
  id: string;
  disabled?: boolean;
}

const ColorPickerWithPopover: React.FC<ColorPickerWithPopoverProps> = ({
  color,
  onChange,
  onImmediateChange,
  label,
  id,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(color.replace('#', ''));
  const [isDragging, setIsDragging] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (!open && isDragging) {
        // Add a small delay before closing if we were dragging
        setTimeout(() => setIsOpen(false), 100);
      } else {
        setIsOpen(open);
      }
    },
    placement: 'left-start',
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
        return 'bottom end';
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return 'top end';
      case 'left':
      case 'left-end':
        return 'right end';
      case 'left-start':
        return 'top right';
      case 'right':
      case 'right-start':
      case 'right-end':
        return 'left end';
      default:
        return 'top left';
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: { transform: 'scale(0.9)', opacity: 0 },
    open: { transform: 'scale(1)', opacity: 1 },
    close: { transform: 'scale(0.9)', opacity: 0 },
    duration: 100,
  });

  useEffect(() => {
    if (!isOpen) {
      setInputValue(color.replace('#', ''));
    }
  }, [color, isOpen]);

  const handleTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.toUpperCase();
    setInputValue(newValue);

    if (/^[0-9A-F]{6}$/.test(newValue)) {
      const formattedColor = `#${newValue}`;
      if (formattedColor !== color) {
        onImmediateChange(formattedColor);
      }
    }
  }, [color, onImmediateChange]);

  const handleInputBlur = useCallback(() => {
    if (!/^[0-9A-F]{6}$/.test(inputValue)) {
      setInputValue(color.replace('#', ''));
    } else {
      const formattedColor = `#${inputValue}`;
      if (formattedColor !== color) {
        onImmediateChange(formattedColor);
        onChange(formattedColor);
      }
    }
  }, [inputValue, color, onImmediateChange, onChange]);

  const handleColorPickerChange = useCallback((newColor: string) => {
    const formattedColor = newColor.toUpperCase();
    setInputValue(formattedColor.replace('#', ''));
    if (formattedColor !== color) {
      onImmediateChange(formattedColor);
    }
  }, [color, onImmediateChange]);

  const handlePointerDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handlePointerUp = useCallback(() => {
    setIsDragging(false);
    const formattedColor = `#${inputValue}`;
    if (formattedColor !== color) {
      onChange(formattedColor);
    }
  }, [inputValue, color, onChange]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsOpen(true);
    }
  }, [disabled]);

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <PickerWrapper>
      <Label htmlFor={id}>{label}</Label>
      <ColorInputPreview
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={handleClick}
        disabled={disabled}
      >
        <ColorPreview color={`#${inputValue}`} />
        <ColorInput
          type="text"
          id={id}
          value={inputValue}
          onChange={handleTextInputChange}
          onBlur={handleInputBlur}
          disabled={disabled}
        />
      </ColorInputPreview>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PopoverContent 
              style={styles}
              $transformOrigin={transformOrigin}
              onPointerDown={handlePointerDown}
              onPointerUp={handlePointerUp}
            >
              <StyledColorPicker
                color={`#${inputValue}`}
                onChange={handleColorPickerChange}
              />
              <ColorTextInput
                type="text"
                value={inputValue}
                onChange={handleTextInputChange}
                onBlur={handleInputBlur}
                placeholder="RRGGBB"
              />
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </PickerWrapper>
  );
};

export default ColorPickerWithPopover;
