import React from "react";
import { RouterProvider } from "@tanstack/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { router } from "./router";
import { queryClient } from './queryClient';
import { Toaster } from 'sonner';

import "./App.css";

const App: React.FC = () => {
  return (
    <div>
      <Toaster 
        position="bottom-right" 
        richColors 
        theme="light"
        duration={3000}
        visibleToasts={6}
        expand={false}
      />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
};

export default App;