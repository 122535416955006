import React, { useRef, useLayoutEffect, useState, useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import { SimpleHeader } from '../../templates/SimpleHeader';
import { DetailedHeader } from '../../templates/DetailedHeader';
import { ModernHeader } from '../../templates/ModernHeader';
import { MinimalistHeader } from '../../templates/MinimalistHeader';
import { GradientHeader } from '../../templates/GradientHeader';
import { BoxedHeader } from '../../templates/BoxedHeader';
import { SplitHeader } from '../../templates/SplitHeader';
import { CircularHeader } from '../../templates/CircularHeader';
import { CompactHeader } from '../../templates/CompactHeader';
import { InvoiceTemplatesProps } from '../../templates/types';
import { Client, InvoiceData, InvoiceItem, Organization } from '../../types';
import { calculateInvoiceTotals } from '../../utils/invoiceCalculations';
import { ClientSelector } from '../Clients/ClientSelector';
import ResizeObserver from 'resize-observer-polyfill';

const PreviewContainer = styled.div.attrs<{ $headerColor: string; $height: number; $shouldAnimate: boolean }>(
  props => ({
    style: {
      height: `${props.$height}px`,
      transition: props.$shouldAnimate ? 'height 0.3s ease-in-out' : 'none',
    },
  })
)`
  overflow: hidden;
  min-height: 200px;
`;

interface HeaderPreviewProps {
  template: string;
  font: string;
  headerColor: string;
  headerTextColor: string;
  logoUrl: string | null;
  showLogo: boolean;
}

// Memoize individual header components
const MemoizedSimpleHeader = memo(SimpleHeader);
const MemoizedDetailedHeader = memo(DetailedHeader);
const MemoizedModernHeader = memo(ModernHeader);
const MemoizedMinimalistHeader = memo(MinimalistHeader);
const MemoizedGradientHeader = memo(GradientHeader);
const MemoizedBoxedHeader = memo(BoxedHeader);
const MemoizedSplitHeader = memo(SplitHeader);
const MemoizedCircularHeader = memo(CircularHeader);
const MemoizedCompactHeader = memo(CompactHeader);

// Modify the getMockData function to accept logoUrl as a parameter
const getMockData = (logoUrl: string | null) => {
  const exampleInvoiceItems: InvoiceItem[] = [
    {
      id: 'example-item-1',
      invoice_id: 'preview-invoice',
      description: 'Web Design Services',
      quantity: 1,
      price: 1500,
      taxable: true,
      order: 0,
    },
    {
      id: 'example-item-2',
      invoice_id: 'preview-invoice',
      description: 'Logo Design',
      quantity: 1,
      price: 500,
      taxable: true,
      order: 1,
    },
    {
      id: 'example-item-3',
      invoice_id: 'preview-invoice',
      description: 'Hosting (per month)',
      quantity: 12,
      price: 20,
      taxable: false,
      order: 2,
    },
  ];

  const demoClient: Client = {
    id: 'demo-client-id',
    full_name: 'Demo Client',
    email: 'demo@example.com',
    phone: '(555) 123-4567',
    address_line1: '123 Demo Street',
    address_line2: 'Suite 456',
    city: 'Demo City',
    state: 'DS',
    postal_code: '12345',
    country: 'Demoland',
    organization_id: 'demo-org-id',
    created_at: new Date().toISOString(),
    is_active: true,
  };

  const mockOrganization: Organization = {
    id: 'mock-org-id',
    name: 'Mock Organization',
    business_name: 'Mock Business',
    address_line1: '123 Mock Street',
    address_line2: 'Suite 456',
    city: 'Mockville',
    state: 'MK',
    postal_code: '12345',
    country: 'Mockland',
    logo_url: logoUrl,
    enable_email_tracking: false,
  };

  return { exampleInvoiceItems, demoClient, mockOrganization };
};

const HeaderPreview: React.FC<HeaderPreviewProps> = memo(({ 
  template, 
  font, 
  headerColor, 
  headerTextColor, 
  logoUrl, 
  showLogo 
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(200);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  // Modify the useMemo call to pass logoUrl
  const { exampleInvoiceItems, demoClient, mockOrganization } = useMemo(() => getMockData(logoUrl), [logoUrl]);

  // Use useMemo for mockInvoice
  const mockInvoice: InvoiceData = useMemo(() => ({
    id: 'mock-id',
    client_id: demoClient.id,
    font,
    header_color: headerColor,
    header_text_color: headerTextColor,
    invoice_date: '2025-11-05',
    due_date: '2025-12-05',
    invoice_number: 'PREVIEW',
    tax_rate: 5,
    due_days: 30,
    currency: 'USD',
    items: exampleInvoiceItems,
    notes: '',
    invoice_template: 'simple',
    public_id: 'mock-public-id',
    payments: [],
    status: 'draft',
    logo_url: logoUrl,
    show_logo: showLogo,
    invoice_created_at: '2025-12-05',
    background_color: '#ffffff',
    body_text_color: '#000000',
    subtotal: 2240,
    total: 2340,
    amount_due: 2340,
  }), [demoClient.id, font, headerColor, headerTextColor, exampleInvoiceItems, logoUrl, showLogo]);

  // Use useMemo for totals calculation
  const totals = useMemo(() => calculateInvoiceTotals(mockInvoice), [mockInvoice]);

  // Use useMemo for mockInvoiceWithTotals
  const mockInvoiceWithTotals: InvoiceData = useMemo(() => ({
    ...mockInvoice,
    subtotal: totals.subtotal,
    total: totals.total,
    amount_due: totals.amountDue,
  }), [mockInvoice, totals]);

  const mockUpdateInvoice: InvoiceTemplatesProps['updateInvoice'] = useCallback(() => {}, []);
  const mockClients: Client[] = useMemo(() => [demoClient], [demoClient]);
  const mockSelectedClient: Client = demoClient;

  const updateHeight = useCallback(() => {
    if (headerRef.current) {
      setHeight(headerRef.current.scrollHeight);
    }
  }, []);

  useLayoutEffect(() => {
    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
      const images = headerRef.current.getElementsByTagName('img');
      Array.from(images).forEach(img => resizeObserver.observe(img));
    }

    if (!shouldAnimate) {
      setShouldAnimate(true);
    }

    return () => resizeObserver.disconnect();
  }, [template, font, headerColor, headerTextColor, logoUrl, showLogo, updateHeight, shouldAnimate]);

  const renderHeader = useCallback(() => {
    const commonProps: Omit<InvoiceTemplatesProps, 'onCreateNewClient'> = {
      invoice: mockInvoiceWithTotals,
      updateInvoice: mockUpdateInvoice,
      isReadOnly: true,
      clients: mockClients,
      selectedClient: mockSelectedClient,
      onOpenUpdateOrganizationDrawer: () => {},
      ClientSelectorComponent: ({ clients, selectedClientId, onSelectClient, onCreateNewClient, headerTextColor }) => (
        <ClientSelector
          clients={clients}
          selectedClientId={selectedClientId}
          onSelectClient={onSelectClient}
          onCreateNewClient={onCreateNewClient}
          headerTextColor={headerTextColor}
          isPreview={true}
          onEditClient={() => {}}
        />
      ),
      organization: mockOrganization,
      showLogo,
    };

    const onCreateNewClient = () => {};
    const onInvoiceDateChange = () => {};
    const onDueDateChange = () => {};

    const HeaderComponent = (() => {
      switch (template) {
        case 'simple': return MemoizedSimpleHeader;
        case 'detailed': return MemoizedDetailedHeader;
        case 'modern': return MemoizedModernHeader;
        case 'minimalist': return MemoizedMinimalistHeader;
        case 'gradient': return MemoizedGradientHeader;
        case 'boxed': return MemoizedBoxedHeader;
        case 'split': return MemoizedSplitHeader;
        case 'circular': return MemoizedCircularHeader;
        case 'compact': return MemoizedCompactHeader;
        default: return MemoizedSimpleHeader;
      }
    })();

    return (
      <HeaderComponent 
        {...commonProps} 
        onCreateNewClient={onCreateNewClient}
        onInvoiceDateChange={onInvoiceDateChange}
        onDueDateChange={onDueDateChange}
      />
    );
  }, [
    template, 
    mockInvoiceWithTotals, 
    mockUpdateInvoice, 
    mockClients, 
    mockSelectedClient, 
    mockOrganization,
    showLogo
  ]);

  return (
    <PreviewContainer 
      $height={height} 
      $headerColor={headerColor}
      $shouldAnimate={shouldAnimate}
    >
      <div ref={headerRef}>
        {renderHeader()}
      </div>
    </PreviewContainer>
  );
});

export default HeaderPreview;
