import React from 'react';
import styled from 'styled-components';

const PresetContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  
`;

const PresetButton = styled.button<{ $colors: string[] }>`
  width: 100%;
  height: 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  background: linear-gradient(
    to right,
    ${props => props.$colors[0]} 0%,
    ${props => props.$colors[0]} 25%,
    ${props => props.$colors[1]} 25%,
    ${props => props.$colors[1]} 50%,
    ${props => props.$colors[2]} 50%,
    ${props => props.$colors[2]} 75%,
    ${props => props.$colors[3]} 75%,
    ${props => props.$colors[3]} 100%
  );
  &:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  &:active {
    transform: scale(0.99);
  }
`;

export interface ColorPreset {
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  bodyTextColor: string;
}

const defaultPresets: ColorPreset[] = [
  {
    headerColor: '#F3EED9',
    headerTextColor: '#151514',
    backgroundColor: '#FFFFFF',
    bodyTextColor: '#151514',
  },
  {
    headerColor: '#305D50',
    headerTextColor: '#6CEBC6',
    backgroundColor: '#D9EFE9',
    bodyTextColor: '#2C3E50',
  },
  {
    headerColor: '#E74C3C',
    headerTextColor: '#FFFFFF',
    backgroundColor: '#ECF0F1',
    bodyTextColor: '#2B5448',
  },
  {
    headerColor: '#27AE60',
    headerTextColor: '#FFFFFF',
    backgroundColor: '#F9F9F9',
    bodyTextColor: '#333333',
  },
  {
    headerColor: '#F39C12',
    headerTextColor: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    bodyTextColor: '#34495E',
  },
];

interface ColorPresetsProps {
  onSelectPreset: (preset: ColorPreset) => void;
  customPresets?: ColorPreset[];
}

const ColorPresets: React.FC<ColorPresetsProps> = ({ onSelectPreset, customPresets }) => {
  const presets = customPresets || defaultPresets;

  return (
    <PresetContainer>
      {presets.map((preset, index) => (
        <PresetButton
          key={index}
          $colors={[preset.headerColor, preset.headerTextColor, preset.backgroundColor, preset.bodyTextColor]}
          onClick={() => onSelectPreset(preset)}
          title={`Color Preset ${index + 1}`}
        />
      ))}
    </PresetContainer>
  );
};

export default ColorPresets;