import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useResizeObserver } from "../hooks/useResizeObserver";

const HeaderContainer = styled.div<{
  $isLoaded: boolean;
  $shouldAnimate: boolean;
}>`
  overflow: hidden;
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  min-height: 200px; // Add minimum height to prevent layout shift
`;

const HeaderContent = styled.div<{ 
  $height: number;
  $shouldAnimate: boolean;
  $isLoaded: boolean;
}>`
  height: ${props => props.$isLoaded ? `${props.$height}px` : '200px'};
  transition: ${props => props.$shouldAnimate ? 'height 0.3s ease-in-out' : 'none'};
  visibility: ${props => props.$isLoaded ? 'visible' : 'hidden'};
`;

interface AnimatedHeaderProps {
  headerColor: string;
  children: React.ReactNode;
}

const AnimatedHeader: React.FC<AnimatedHeaderProps> = ({
  headerColor,
  children,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(200); // Start with minimum height
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const updateHeight = useCallback(() => {
    if (headerRef.current) {
      const newHeight = headerRef.current.scrollHeight;
      if (newHeight > 0) {
        setHeight(newHeight);
        if (!isLoaded) {
          setIsLoaded(true);
          // Enable animations after initial height is set
          requestAnimationFrame(() => {
            setShouldAnimate(true);
          });
        }
      }
    }
  }, [isLoaded]);

  useResizeObserver(headerRef, updateHeight);

  useEffect(() => {
    // Initial height calculation
    updateHeight();

    const images = headerRef.current?.getElementsByTagName('img');
    if (images) {
      Array.from(images).forEach(img => {
        if (img.complete) {
          updateHeight();
        } else {
          img.addEventListener('load', updateHeight);
        }
      });
    }

    return () => {
      if (images) {
        Array.from(images).forEach(img => {
          img.removeEventListener('load', updateHeight);
        });
      }
    };
  }, [children, headerColor, updateHeight]);

  return (
    <HeaderContainer $isLoaded={isLoaded} $shouldAnimate={shouldAnimate}>
      <HeaderContent 
        $height={height} 
        $shouldAnimate={shouldAnimate} 
        $isLoaded={isLoaded}
      >
        <div ref={headerRef}>{children}</div>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default AnimatedHeader;
