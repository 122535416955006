import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
} from '@dnd-kit/core';
import type { KeyboardCoordinateGetter } from '@dnd-kit/core';
import { Task, TaskStatus, BatchTaskUpdate } from '../../types';
import KanbanColumn from './KanbanColumn';
import KanbanCard from './KanbanCard';

const KanbanContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  height: calc(100vh - 140px); // Account for header and padding
  overflow-x: auto;
  
  // Improve scrolling experience
  scroll-padding: 16px;
  -webkit-overflow-scrolling: touch;
  
  // Hide scrollbar on supported browsers
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
`;

interface KanbanViewProps {
  tasks: Task[];
  taskStatuses: TaskStatus[];
  onTaskUpdate: (task: Partial<Task> | BatchTaskUpdate) => void;
  onTaskClick: (task: Task) => void;
  selectedTaskId: string | null;
  onCreateTask: (statusId: string) => void;
}

const coordinateGetter: KeyboardCoordinateGetter = (_event, { context }) => {
  if (!context.active) return undefined;
  
  const element = document.getElementById(context.active.id.toString());
  if (!element) return undefined;

  const rect = element.getBoundingClientRect();
  return {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
};

const KanbanView: React.FC<KanbanViewProps> = ({
  tasks,
  taskStatuses,
  onTaskUpdate,
  onTaskClick,
  selectedTaskId,
  onCreateTask,
}) => {
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const [activeColumnId, setActiveColumnId] = React.useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id as string);
    
    const task = tasks.find(t => t.id === active.id);
    if (task?.status_id) {
      setActiveColumnId(task.status_id);
    }
  }, [tasks]);

  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { over } = event;
    
    if (!over) return;

    const overId = over.id.toString();
    const overColumnId = overId.startsWith('column-') 
      ? overId.split('-')[1]
      : tasks.find(t => t.id === overId)?.status_id;

    if (overColumnId && overColumnId !== activeColumnId) {
      setActiveColumnId(overColumnId);
    }
  }, [tasks, activeColumnId]);

  // Memoize the sorted tasks for each status
  const columnTasksMap = useMemo(() => {
    const map = new Map<string, Task[]>();
    
    taskStatuses.forEach(status => {
      const statusTasks = tasks
        .filter(task => task.status_id === status.id)
        .sort((a, b) => a.position - b.position);
      map.set(status.id, statusTasks);
    });
    
    return map;
  }, [tasks, taskStatuses]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) return;

    const activeTask = tasks.find(task => task.id === active.id);
    if (!activeTask) return;

    const overId = over.id.toString();
    const overTask = overId.startsWith('column-') 
      ? null 
      : tasks.find(t => t.id === overId);

    const targetStatusId = overId.startsWith('column-')
      ? overId.replace('column-', '')
      : overTask?.status_id;

    if (!targetStatusId) return;

    // Get all tasks in target column
    const columnTasks = tasks
      .filter(t => t.status_id === targetStatusId && t.id !== activeTask.id)
      .sort((a, b) => a.position - b.position);

    // Calculate new position based on relative positioning
    let newPosition: number;
    if (!overTask) {
      // Dropping at the end of the column
      newPosition = columnTasks.length > 0 
        ? columnTasks[columnTasks.length - 1].position + 1024
        : 1024;
    } else {
      // Dropping between tasks
      const overIndex = columnTasks.findIndex(t => t.id === overTask.id);
      const prevTask = columnTasks[overIndex - 1];
      const nextTask = columnTasks[overIndex];

      newPosition = prevTask
        ? (prevTask.position + nextTask.position) / 2
        : nextTask.position / 2;
    }

    // Only update if position or status changed
    if (activeTask.status_id !== targetStatusId || activeTask.position !== newPosition) {
      onTaskUpdate({
        type: 'batch',
        updates: [{
          id: activeTask.id,
          status_id: targetStatusId,
          position: newPosition
        }],
        optimisticUpdates: [
          { ...activeTask, status_id: targetStatusId, position: newPosition }
        ]
      } as BatchTaskUpdate);
    }

    setActiveId(null);
    setActiveColumnId(null);
  }, [tasks, onTaskUpdate]);

  const activeTask = activeId ? tasks.find(task => task.id === activeId) : null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <KanbanContainer>
        {taskStatuses.map(status => (
          <KanbanColumn
            key={status.id}
            status={status}
            tasks={columnTasksMap.get(status.id) || []}
            onTaskClick={onTaskClick}
            selectedTaskId={selectedTaskId}
            onCreateTask={onCreateTask}
          />
        ))}
      </KanbanContainer>
      <DragOverlay dropAnimation={{
        duration: 200,
        easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
      }}>
        {activeTask ? (
          <KanbanCard
            task={activeTask}
            onClick={() => {}}
            isSelected={false}
            isDragging={true}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default KanbanView;
