import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../Button';
import { Close8 } from '../Icon';
import { Client, Project } from '../../types';

const OverlayWrapper = styled.div`
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

const OverlayContainer = styled(motion.div)`
  max-width: calc(100vw - 40px);
  padding: 8px 8px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
  user-select: none;
  background: #FBFAF8;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.05), inset 0px 1px 0px rgba(255, 255, 255, 0.5), inset 0px 1px 0px rgba(255, 255, 255, 1);
  border-radius: 12px;

`;

const OverlayContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectedCount = styled.div`
  font-weight: 500;
  margin-right: 4px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
`;

const SelectedCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled(Button)`
  background-color: transparent;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const CreateInvoiceButton = styled(Button)`
  svg path {
    fill: #fff;
  }
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0 16px;
`;

interface SelectedTimeEntriesOverlayProps {
  selectedCount: number;
  clients: Client[];
  projects: Project[];
  onClientChange: (clientId: string | null) => void;
  onProjectChange: (projectId: string | null) => void;
  onDelete: () => void;
  onClearSelection: () => void;
  onCreateInvoice: () => void;
}

const SelectedTimeEntriesOverlay: React.FC<SelectedTimeEntriesOverlayProps> = ({
  selectedCount,
  onDelete,
  onClearSelection,
  onCreateInvoice,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (selectedCount > 0) {
      setIsVisible(true);
    } else {
      // Animate out when selectedCount becomes 0
      setIsVisible(false);
    }
  }, [selectedCount]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClearSelection, 100); // Delay to allow animation to complete
  };

  return (
    <AnimatePresence>
      {(isVisible || selectedCount > 0) && (
        <OverlayWrapper
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{
            duration: 0.1,
            ease: "easeInOut"
          }}
        >
          <OverlayContainer>
            <OverlayContent>
              <SelectedCountWrapper>
                <SelectedCount>{selectedCount} selected</SelectedCount>
                <CloseButton onClick={handleClose}>
                  <Close8 />
                </CloseButton>
              </SelectedCountWrapper>
              <Divider />
              <Button buttonType='secondary' onClick={onDelete}>Delete</Button>
              <CreateInvoiceButton buttonType='secondary' onClick={onCreateInvoice}>
                Create Invoice
              </CreateInvoiceButton>
            </OverlayContent>
          </OverlayContainer>
        </OverlayWrapper>
      )}
    </AnimatePresence>
  );
};

export default SelectedTimeEntriesOverlay;