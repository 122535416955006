import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { InvoiceData, Client, BrandSettings, Organization } from '../types';
import { useOrganization } from './useOrganization';

export const useInvoice = (id?: string, options?: { 
  initialData?: InvoiceData,
  organization?: Organization 
}) => {
  const { data: organizationId } = useOrganization();

  return useQuery({
    queryKey: ['invoice', id, organizationId],
    queryFn: async () => {
      if (!id) return null;
      if (!options?.organization) throw new Error('Organization data is required');
      
      const [
        { data: invoiceData, error: invoiceError },
        { data: itemsData, error: itemsError },
        { data: paymentsData, error: paymentsError }
      ] = await Promise.all([
        supabase
          .from('invoices')
          .select('*')
          .eq('id', id)
          .single(),
        supabase
          .from('invoice_items')
          .select('*')
          .eq('invoice_id', id)
          .order('order'),
        supabase
          .from('payments')
          .select('*')
          .eq('invoice_id', id)
      ]);

      if (invoiceError) throw invoiceError;
      if (itemsError) throw itemsError;
      if (paymentsError) throw paymentsError;

      return {
        ...invoiceData,
        items: itemsData || [],
        payments: paymentsData || [],
        logo_url: options.organization.logo_url, // Add this line to ensure logo_url is included
      };
    },
    enabled: !!id && !!organizationId && !!options?.organization,
    initialData: options?.initialData,
    staleTime: 10000
  });
};

export const useClients = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Client[]>({
    queryKey: ['clients', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });
};

export const useBrandSettings = () => {
  const { data: organizationId } = useOrganization();
  const { data: orgDetails } = useOrganizationDetails(organizationId);

  return useQuery<BrandSettings>({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      // Return brand settings from the organization details
      return orgDetails?.brand_settings || {
        default_template: 'simple',
        default_font: 'Arial',
        default_header_color: '#ffffff',
        default_header_text_color: '#000000',
        default_background_color: '#ffffff',
        default_body_text_color: '#000000',
      };
    },
    enabled: !!orgDetails,
    staleTime: 60000
  });
};

export const useOrganizationDetails = (organizationId: string | undefined) => {
  return useQuery({
    queryKey: ['organizationDetails', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');
      
      // Comprehensive organization query that includes all needed fields
      const { data, error } = await supabase
        .from('organizations')
        .select(`
          *,
          stripe_account_id,
          stripe_account_verified,
          brand_settings (*)
        `)
        .eq('id', organizationId)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 60000 // Consider data fresh for 1 minute
  });
};
