import React from 'react';
import styled from 'styled-components';
import NumberFlow from '@number-flow/react';

const EveningWrapper = styled.div`
  width: 100%;
  position: relative;
  background: radial-gradient(150% 150% at 29.75% 131.87%, #ff511c 0%, #FCCDA1 49.52%, #98A0C4 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  height: 288px;
  display: flex;
  flex-direction: column;
`;

const EveningTimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  padding: 24px 20px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  //gap: 8px; // Add this line to create space between columns
`;

const EveningColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  min-width: 96px;
`;

const EveningTimeInputField = styled.input`
  box-sizing: border-box;
  background: transparent;
  text-align: center;
  font-size: 56px;
  line-height: 96px;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'PP Mori';
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-feature-settings: 'tnum';
  border: none;
  cursor: text;
  padding: 0;
  margin: 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: rgba(248, 247, 246, 0.3);
  }
`;

const EveningTimeInputLabel = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 0;
  text-shadow: 0px 1px 0 rgba(0, 0, 0, 0.1); // Add this line for the drop shadow

`;

const Colon = styled.span`
  font-size: 32px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 96px;
  padding: 0 0 28px 0;
`;

interface SunriseLayoutProps {
  durationInputs: { hours: string; minutes: string; seconds: string };
  isCurrentlyRunning: boolean;
  editingField: 'hours' | 'minutes' | 'seconds' | null;
  handleDurationClick: (field: 'hours' | 'minutes' | 'seconds') => void;
  handleDurationChange: (field: 'hours' | 'minutes' | 'seconds') => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDurationBlur: () => void;
}

const SunriseLayout: React.FC<SunriseLayoutProps> = ({
  durationInputs,
  isCurrentlyRunning,
  editingField,
  handleDurationClick,
  handleDurationChange,
  handleDurationBlur,
}) => {
  const renderTimeInput = (field: 'hours' | 'minutes' | 'seconds') => {
    if (isCurrentlyRunning || editingField !== field) {
      return (
        <EveningTimeInputField
          as={NumberFlow}
          value={durationInputs[field] === '' ? 0 : parseInt(durationInputs[field])}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 200, easing: 'ease-out' }}
          spinTiming={{ duration: 200, easing: 'ease-out' }}
          opacityTiming={{ duration: 200, easing: 'ease-out' }}
          onClick={() => handleDurationClick(field)}
        />
      );
    } else {
      return (
        <EveningTimeInputField
          type="number"
          value={durationInputs[field]}
          onChange={(e) => handleDurationChange(field)(e)}
          onBlur={handleDurationBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus
          placeholder="00"
        />
      );
    }
  };

  return (
    <EveningWrapper>
      <EveningTimeInputWrapper>
        <EveningColumn>
          {renderTimeInput('hours')}
          <EveningTimeInputLabel>Hrs</EveningTimeInputLabel>
        </EveningColumn>
        <Colon>:</Colon>
        <EveningColumn>
          {renderTimeInput('minutes')}
          <EveningTimeInputLabel>Mins</EveningTimeInputLabel>
        </EveningColumn>
        <Colon>:</Colon>
        <EveningColumn>
          {renderTimeInput('seconds')}
          <EveningTimeInputLabel>Secs</EveningTimeInputLabel>
        </EveningColumn>
      </EveningTimeInputWrapper>
    </EveningWrapper>
  );
};

export default SunriseLayout;
