import React from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Task } from '../../types';

interface CardProps {
  $isDragging?: boolean;
  $isSelected?: boolean;
}

const Card = styled.div<CardProps>`
  background: white;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
  opacity: ${props => props.$isDragging ? 0.5 : 1};
  border: 1px solid ${props => props.$isSelected ? '#0066FF' : 'rgba(0, 0, 0, 0.1)'};

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const TaskDescription = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

interface KanbanCardProps {
  task: Task;
  onClick: () => void;
  isSelected: boolean;
  isDragging?: boolean;
}

const KanbanCard: React.FC<KanbanCardProps> = ({
  task,
  onClick,
  isSelected,
  isDragging = false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: task.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      onClick={onClick}
      $isSelected={isSelected}
      $isDragging={isDragging}
      {...attributes}
      {...listeners}
    >
      <TaskDescription>{task.description || 'Untitled Task'}</TaskDescription>
    </Card>
  );
};

export default KanbanCard;
