import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';

interface CreateOrganizationParams {
  name: string;
}

const defaultBrandSettings = {
  default_template: "simple",
  default_font: "Inter",
  default_header_color: "#ffffff",
  default_background_color: "#ffffff",
  default_header_text_color: "#000000",
  default_body_text_color: "#000000",
  auto_color_text: false,
  show_logo: true,
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CreateOrganizationParams) => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data: organization, error: organizationError } = await supabase
        .from('organizations')
        .insert([{ name: params.name }])
        .select()
        .single();

      if (organizationError) throw organizationError;

      // Add user as a member of the organization and mark as owner
      const { error: memberError } = await supabase
        .from('organization_members')
        .insert([{ 
          organization_id: organization.id, 
          user_id: user.id, 
          is_owner: true,
          role: 'admin'
        }]);

      if (memberError) {
        console.error('Error adding member to organization:', memberError);
        throw memberError;
      }

      // Create brand settings for the new organization
      const { error: brandSettingsError } = await supabase
        .from('brand_settings')
        .insert([{ 
          organization_id: organization.id,
          ...defaultBrandSettings
        }]);

      if (brandSettingsError) {
        console.error('Error creating brand settings:', brandSettingsError);
        throw brandSettingsError;
      }

      // Set the new organization as the current organization
      const { error: updateError } = await supabase.auth.updateUser({
        data: { current_organization_id: organization.id }
      });

      if (updateError) {
        console.error('Error updating current organization:', updateError);
        throw updateError;
      }

      return organization;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization'] });
      queryClient.invalidateQueries({ queryKey: ['userOrganizations'] });
      queryClient.invalidateQueries({ queryKey: ['brandSettings'] });
    },
  });
};