import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from './Switch';
import Button from './Button';
import StripeDrawer from './Stripe/StripeDrawer';

const DetailRow = styled.div`
  margin: 24px 0 0 8px;
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
`;

const StripeCTA = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.02);
  gap: 8px;
  padding: 12px;
  margin: 12px 8px;
  border-radius: 8px;
`;

interface StripePaymentsSectionProps {
  isStripeConnected: boolean;
  collectStripePayments: boolean;
  onToggleCollectStripePayments: (enabled: boolean) => void;
}

const StripePaymentsSection: React.FC<StripePaymentsSectionProps> = ({
  isStripeConnected,
  collectStripePayments,
  onToggleCollectStripePayments,
}) => {
  const [isStripeDrawerOpen, setIsStripeDrawerOpen] = useState(false);

  return (
    <DetailRow>
      {isStripeConnected ? (
        <Switch
          checked={collectStripePayments}
          onChange={(checked) => onToggleCollectStripePayments(checked)}
          label="Accept Payments"
          labelPosition="right"
        />
      ) : (
        <StripeCTA>
          <DetailValue>Set up your Stripe account to start accepting payments.</DetailValue>
          <DetailValue>Learn More</DetailValue>
          <Button onClick={() => setIsStripeDrawerOpen(true)}>
            Connect Stripe Account
          </Button>
        </StripeCTA>
      )}

      <StripeDrawer 
        isOpen={isStripeDrawerOpen}
        setIsOpen={setIsStripeDrawerOpen}
      />
    </DetailRow>
  );
};

export default StripePaymentsSection;
