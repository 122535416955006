import React from 'react';
import styled from 'styled-components';
import NumberFlow from '@number-flow/react';

const RadialWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 288px;
  background: #E1F1E2;
  overflow: hidden;
`;

const RadialDialWrapper = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RadialDial = styled.div<{ $rotation: number }>`
  position: absolute;
  top: 288px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 0.1s linear;
  transform: rotate(${props => props.$rotation}deg);
  background: #305D50;
`;

const RadialDialMarker = styled.div<{ $isHour: boolean; $color: string }>`
  position: absolute;
  left: 50%;
  width: ${props => props.$isHour ? '2px' : '1px'};
  height: ${props => props.$isHour ? '36px' : '24px'};
  background: ${props => props.$color};
  transform-origin: bottom center;
  transition: transform 0.2s ease-out;
`;

const RadialTimeDisplay = styled.div`
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const RadialTimeUnit = styled.div`
  font-size: 48px;
  font-family: 'PP Mori';
  font-feature-settings: 'tnum';
  font-weight: bold;
  color: #FFFFFF;
  min-width: 64px;
  text-align: center;
  cursor: text;
`;

const RadialTimeSeparator = styled.div`
  font-size: 32px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0 8px 12px 8px;
`;

const DurationInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  color: #FFFFFF;
  font-family: 'PP Mori';
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-feature-settings: 'tnum';
  border: none;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

interface RadialLayoutProps {
  durationInputs: { hours: string; minutes: string; seconds: string };
  isCurrentlyRunning: boolean;
  editingField: 'hours' | 'minutes' | 'seconds' | null;
  handleDurationClick: (field: 'hours' | 'minutes' | 'seconds') => void;
  handleDurationChange: (field: 'hours' | 'minutes' | 'seconds') => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDurationBlur: () => void;
}

const RadialLayout: React.FC<RadialLayoutProps> = ({
  durationInputs,
  isCurrentlyRunning,
  editingField,
  handleDurationClick,
  handleDurationChange,
  handleDurationBlur,
}) => {
  const minutes = parseInt(durationInputs.minutes) || 0;
  const seconds = parseInt(durationInputs.seconds) || 0;
  const minutesRotation = ((minutes + seconds / 60) / 60) * 360;

  const renderDurationInput = (field: 'hours' | 'minutes' | 'seconds') => {
    const value = durationInputs[field];

    if (isCurrentlyRunning || editingField !== field) {
      return (
        <NumberFlow
          value={value === '' ? 0 : parseInt(value, 10)}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 200, easing: 'ease-out' }}
          spinTiming={{ duration: 200, easing: 'ease-out' }}
          opacityTiming={{ duration: 200, easing: 'ease-out' }}
          onClick={() => handleDurationClick(field)}
        />
      );
    } else {
      return (
        <DurationInput
          type="number"
          value={value}
          onChange={(e) => handleDurationChange(field)(e)}
          onBlur={handleDurationBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus
          placeholder="00"
        />
      );
    }
  };

  return (
    <RadialWrapper>
      <RadialDialWrapper>
        <RadialDial $rotation={minutesRotation}>
          {Array.from({ length: 60 }).map((_, i) => (
            <RadialDialMarker
              key={`minute-${i}`}
              $isHour={i % 5 === 0}
              $color={i % 5 === 0 ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.3)"}
              style={{
                transform: `rotate(${i * 6}deg) translateX(-50%)`,
                transformOrigin: '50% 400px',
              }}
            />
          ))}
        </RadialDial>
      </RadialDialWrapper>
      <RadialTimeDisplay>
        <RadialTimeUnit>{renderDurationInput('hours')}</RadialTimeUnit>
        <RadialTimeSeparator>:</RadialTimeSeparator>
        <RadialTimeUnit>{renderDurationInput('minutes')}</RadialTimeUnit>
        <RadialTimeSeparator>:</RadialTimeSeparator>
        <RadialTimeUnit>{renderDurationInput('seconds')}</RadialTimeUnit>
      </RadialTimeDisplay>
    </RadialWrapper>
  );
};

export default RadialLayout;
