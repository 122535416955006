import React from 'react';
import styled from 'styled-components';

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  labelPosition?: 'left' | 'right';
  sliderColor?: string;
  sliderUncheckedColor?: string;
  knobColor?: string;
  dark?: boolean;
}

const SwitchContainer = styled.label<{ $labelPosition: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
`;

const SwitchSlider = styled.span<{ 
  checked: boolean; 
  sliderColor?: string; 
  sliderUncheckedColor?: string;
  knobColor?: string 
}>`
  display: flex;
  align-items: center;
  width: 28px;
  height: 16px;
  background: ${props => 
    props.checked 
      ? (props.sliderColor || '#4F846A') 
      : (props.sliderUncheckedColor || '#ccc')};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: ${props => props.checked ? 'calc(100% - 2px)' : '2px'};
    width: 12px;
    height: 12px;
    background: ${props => props.knobColor || '#fff'};
    border-radius: 50%;
    transition: 0.2s;
    transform: ${props => props.checked ? 'translateX(-100%)' : 'translateX(0)'};
  }
`;

const SwitchLabel = styled.span<{ $labelPosition: 'left' | 'right'; $dark?: boolean }>`
  order: ${props => props.$labelPosition === 'left' ? 0 : 2};
  font-size: 14px;
  font-weight: 500;
  margin-left: ${props => props.$labelPosition === 'right' ? '8px' : '0'};
  margin-right: ${props => props.$labelPosition === 'left' ? '8px' : '0'};
  color: ${props => props.$dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};

  &:hover {
    color: ${props => props.$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
  }
`;

const Switch: React.FC<SwitchProps> = ({ 
  checked, 
  onChange, 
  label, 
  labelPosition = 'right', 
  sliderColor,
  sliderUncheckedColor,
  knobColor,
  dark = false
}) => {
  return (
    <SwitchContainer $labelPosition={labelPosition}>
      {label && <SwitchLabel $labelPosition={labelPosition} $dark={dark}>{label}</SwitchLabel>}
      <SwitchInput
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <SwitchSlider 
        checked={checked} 
        sliderColor={sliderColor} 
        sliderUncheckedColor={sliderUncheckedColor}
        knobColor={knobColor} 
      />
    </SwitchContainer>
  );
};

export default Switch;
