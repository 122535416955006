import React from 'react';
import styled from 'styled-components';
import NumberFlow from '@number-flow/react';

const RunningDurationWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 288px;
`;

const TimeColumn = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => props.color};
  text-align: center;
  flex: 1;
  height: 100%;
  font-size: 48px;
  line-height: 56px;
  font-family: 'PP Mori';
  font-feature-settings: 'tnum';
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
`;

const TimeUnitLabel = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  bottom: 16px;
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const DurationInput = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: transparent;
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  color: #F8F7F6;
  font-family: 'PP Mori';
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-feature-settings: 'tnum';
  border: none;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: rgba(248, 247, 246, 0.3);
  }
`;

const StyledNumberFlow = styled(NumberFlow)`
  font-size: 48px;
  line-height: 56px;
  font-family: 'PP Mori', sans-serif;
  font-feature-settings: 'tnum';
  font-weight: bold;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: text;
  padding: 0;
  margin: 0;
  border: none;

`;

interface DefaultLayoutProps {
  durationInputs: { hours: string; minutes: string; seconds: string };
  isCurrentlyRunning: boolean;
  editingField: 'hours' | 'minutes' | 'seconds' | null;
  handleDurationClick: (field: 'hours' | 'minutes' | 'seconds') => void;
  handleDurationChange: (field: 'hours' | 'minutes' | 'seconds') => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDurationBlur: () => void;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  durationInputs,
  isCurrentlyRunning,
  editingField,
  handleDurationClick,
  handleDurationChange,
  handleDurationBlur,
}) => {
  const renderDurationInput = (field: 'hours' | 'minutes' | 'seconds') => {
    const value = durationInputs[field];

    if (isCurrentlyRunning || editingField !== field) {
      return (
        <StyledNumberFlow
          value={value === '' ? 0 : parseInt(value, 10)}
          format={{ minimumIntegerDigits: 2 }}
          transformTiming={{ duration: 200, easing: 'ease-out' }}
          spinTiming={{ duration: 200, easing: 'ease-out' }}
          opacityTiming={{ duration: 200, easing: 'ease-out' }}
          onClick={() => handleDurationClick(field)}
        />
      );
    } else {
      return (
        <DurationInput
          type="number"
          value={value}
          onChange={(e) => handleDurationChange(field)(e)}
          onBlur={handleDurationBlur}
          min={0}
          max={field === 'hours' ? 23 : 59}
          autoFocus
          placeholder="00"
        />
      );
    }
  };

  return (
    <RunningDurationWrapper>
      <TimeColumn color="#D04085">
        {renderDurationInput('hours')}
        <TimeUnitLabel>Hrs</TimeUnitLabel>
      </TimeColumn>
      <TimeColumn color="#E61111">
        {renderDurationInput('minutes')}
        <TimeUnitLabel>Mins</TimeUnitLabel>
      </TimeColumn>
      <TimeColumn color="#E96329">
        {renderDurationInput('seconds')}
        <TimeUnitLabel>Secs</TimeUnitLabel>
      </TimeColumn>
    </RunningDurationWrapper>
  );
};

export default DefaultLayout;
