import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
  font-weight: 500;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const AuthForm = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  background-color: rgba(0,0,0,0.05);
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s;
  box-sizing: border-box;

  &:focus {
    border-color: #0066cc;
  }
`;

const Button = styled.button<{ variant?: 'primary' | 'secondary' | 'google' }>`
  width: 100%;
  padding: 0.75rem;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.2s;
  background-color: ${props => props.variant === 'google' ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.05)'};
  color: ${props => props.variant === 'google' ? '#fff' : '#000'};

  &:hover {
    background-color: ${props => props.variant === 'google' ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0.1)'};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: #333;
  }
`;

interface SearchParams {
  email?: string;
  organizationId?: string;
  action?: string;
}

const Auth: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const search = useSearch({ from: '__root__' }) as SearchParams;

  useEffect(() => {
    // Check if there's an invitation in the URL
    if (search.email && search.organizationId && search.action === 'accept-invite') {
      localStorage.setItem('pendingInvitation', JSON.stringify({ 
        email: search.email, 
        organizationId: search.organizationId 
      }));
      setEmail(search.email);
    }
  }, [search]);

  const handleSignInSuccess = async () => {
    queryClient.clear(); // Clear cache on successful sign-in
    
    const { data: { user }, error } = await supabase.auth.getUser();
    
    if (error) {
      console.error('Error fetching user data:', error);
      return;
    }

    const pendingInvitation = localStorage.getItem('pendingInvitation');
    if (pendingInvitation) {
      const { email, organizationId } = JSON.parse(pendingInvitation);
      navigate({ 
        to: '/accept-invite', 
        search: { email, organizationId } 
      });
      localStorage.removeItem('pendingInvitation');
    } else if (user?.user_metadata?.current_organization_id) {
      navigate({ to: '/' });
    } else {
      navigate({ to: '/create-organization' });
    }
  };

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      alert(error.message);
    } else {
      await handleSignInSuccess();
    }
    setLoading(false);
  };

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await supabase.auth.signUp({ 
      email, 
      password,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/callback`
      }
    });
    if (error) {
      alert(error.message);
    } else if (data.user && data.user.identities && data.user.identities.length === 0) {
      alert('An account with this email already exists. Please sign in instead.');
    } else {
      await handleSignUpSuccess();
    }
    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`
      }
    });
    if (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleSignUpSuccess = async () => {
    queryClient.clear(); // Clear cache on successful sign-up
    
    const pendingInvitation = localStorage.getItem('pendingInvitation');
    if (pendingInvitation) {
      const { email } = JSON.parse(pendingInvitation);
      // Fetch and cache the pending invitation
      await queryClient.prefetchQuery({
        queryKey: ['pendingInvitations', email],
        queryFn: async () => {
          const { data, error } = await supabase
            .from('pending_invitations')
            .select(`
              id,
              organization_id,
              organizations (name),
              email
            `)
            .eq('email', email);
          if (error) throw error;
          return data;
        },
      });
      navigate({ to: '/pending-invitations' });
      localStorage.removeItem('pendingInvitation');
    } else {
      alert('Check your email for the confirmation link!');
      navigate({ to: '/create-organization' });
    }
  };

  return (
    <AuthContainer>
      <Title>Login to Due</Title>
      <AuthForm onSubmit={handleEmailSignIn}>
        {showEmailForm ? (
          <>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email"
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
            <ButtonGroup>
              <Button type="submit" variant="google" disabled={loading}>
                Sign In
              </Button>
              <Button 
                type="button"
                onClick={handleEmailSignUp}
                disabled={loading}
              >
                Sign Up
              </Button>
            </ButtonGroup>
            <BackButton 
              type="button" 
              onClick={() => setShowEmailForm(false)}
            >
              Go Back
            </BackButton>
          </>
        ) : (
          <>
            <Button 
              type="button"
              variant="google"
              onClick={handleGoogleSignIn}
              disabled={loading}
            >
              Continue with Google
            </Button>
            <Button 
              type="button"
              onClick={() => setShowEmailForm(true)}
              disabled={loading}
            >
              Continue with email
            </Button>
          </>
        )}
      </AuthForm>
    </AuthContainer>
  );
};

export default Auth;