import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';

export const useDeleteProjectMutation = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async (projectId: string) => {
      const { error } = await supabase
        .from('projects')
        .delete()
        .eq('id', projectId);

      if (error) throw error;
    },
    onSuccess: (_, deletedProjectId) => {
      queryClient.invalidateQueries({ queryKey: ['projects', organizationId] });
      queryClient.removeQueries({ queryKey: ['project', deletedProjectId, organizationId] });
    },
  });
};