import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { User } from '@supabase/supabase-js';

interface Profile {
  id: string;
  full_name: string | null;
  email: string | null;
  avatar_url: string | null;
  updated_at: string;
}

interface UserData {
  id: string;
  email: string;
  user_metadata: {
    full_name?: string;
    avatar_url?: string;
  };
  profile: Profile;
}

type UserQueryOptions = Partial<UseQueryOptions<UserData | null, Error, UserData | null>>;

const transformUserData = (user: User, profile: Profile): UserData => ({
  id: user.id,
  email: user.email ?? '',
  user_metadata: {
    full_name: user.user_metadata?.full_name,
    avatar_url: user.user_metadata?.avatar_url,
  },
  profile,
});

export const useUser = (options?: UserQueryOptions) => {
  return useQuery<UserData | null, Error, UserData | null>({
    queryKey: ['user'],
    queryFn: async () => {
      console.log('Fetching user data...');

      const { data: { user }, error: authError } = await supabase.auth.getUser();
      
      if (authError) {
        console.error('Auth error:', authError);
        throw authError;
      }
      
      if (!user) {
        console.log('No authenticated user found');
        return null;
      }

      try {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('id, full_name, email, avatar_url, updated_at')
          .eq('id', user.id)
          .single();

        const metadataAvatarUrl = user.user_metadata?.avatar_url;
        const metadataFullName = user.user_metadata?.full_name;

        if (!profile || profileError) {
          const newProfileData: Profile = {
            id: user.id,
            full_name: metadataFullName || '',
            email: user.email || '',
            avatar_url: metadataAvatarUrl || null,
            updated_at: new Date().toISOString(),
          };
          
          const { data: newProfile, error: createError } = await supabase
            .from('profiles')
            .upsert(newProfileData)
            .select()
            .single();

          if (createError) throw createError;

          return transformUserData(user, {
            ...newProfile,
            avatar_url: metadataAvatarUrl || newProfile.avatar_url,
            full_name: metadataFullName || newProfile.full_name,
          });
        }

        return transformUserData(user, {
          ...profile,
          full_name: profile.full_name || metadataFullName || '',
          avatar_url: metadataAvatarUrl || profile.avatar_url || null,
        });
      } catch (error) {
        console.error('Error in profile handling:', error);
        
        // Return user with metadata as fallback
        const fallbackProfile: Profile = {
          id: user.id,
          full_name: user.user_metadata?.full_name || '',
          email: user.email || '',
          avatar_url: user.user_metadata?.avatar_url || null,
          updated_at: new Date().toISOString(),
        };
        
        return transformUserData(user, fallbackProfile);
      }
    },
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    refetchOnWindowFocus: true,
    ...options,
  });
};
