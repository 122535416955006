import React, { useMemo } from 'react';
import { InvoiceData } from '../types';
import DataTable from './DataTable';
import { ColumnDef, SortingState, OnChangeFn } from '@tanstack/react-table';
import { StatusBadge } from './StatusBadge';
import { Invoice32 } from './Icon';
import { formatCurrency } from '../utils/formatCurrency';

interface InvoiceListProps {
  invoices: InvoiceData[];
  isLoading: boolean;
  onCreateInvoice: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  onSelectionChange?: (selectedIds: string[]) => void;
  onRowClick: (invoice: InvoiceData) => void;
  selectedInvoiceId: string | null;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
}

const InvoiceList: React.FC<InvoiceListProps> = ({
  invoices,
  isLoading,
  onCreateInvoice,
  containerRef,
  onSelectionChange,
  onRowClick,
  selectedInvoiceId,
  sorting,
  onSortingChange,
}) => {
  const invoiceColumns: ColumnDef<InvoiceData>[] = useMemo(
    () => [
      {
        accessorKey: 'invoice_number',
        header: 'Invoice Number',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ getValue }) => (
          <StatusBadge $status={getValue() as string}>{getValue() as string}</StatusBadge>
        ),
      },
      {
        accessorKey: 'invoice_date',
        header: 'Invoice Date',
        cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
      },
      {
        accessorKey: 'amount_due',
        header: 'Amount Due',
        cell: ({ getValue }) => formatCurrency(getValue() as number),
      },
    ],
    []
  );

  const invoiceEmptyState = useMemo(() => ({
    icon: <Invoice32 />,
    message: "No invoices found",
    subMessage: "Create a new invoice to get started",
    action: {
      label: "New Invoice",
      onClick: onCreateInvoice
    }
  }), [onCreateInvoice]);

  return (
    <DataTable<InvoiceData>
      columns={invoiceColumns}
      data={invoices}
      isLoading={isLoading}
      onRowClick={onRowClick}
      getRowKey={(invoice) => invoice.id}
      onSelectionChange={onSelectionChange || (() => {})}
      rowClassName="invoice-row"
      containerRef={containerRef}
      emptyState={invoiceEmptyState}
      selectedRows={selectedInvoiceId ? [selectedInvoiceId] : []}
      sorting={sorting}
      onSortingChange={onSortingChange}
    />
  );
};

export default InvoiceList;
