import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import { supabase } from "../../supabaseClient";
import { TimeEntry, GroupedTimeEntry, TimeEntryLayout, TimeTrackerStyle, TIME_TRACKER_TO_ENTRY_LAYOUT } from "../../types";
import Button from "../Button";
import { useClients } from "../../hooks/useClients";
import { useProjects } from "../../hooks/useProjects";
import { useServices } from "../../hooks/useServices";
import { debounce } from 'lodash';
import EntityPicker from "../EntityPicker";
import { Drawer } from 'vaul';
import { Close12, Client12, Project12, Calendar12, Clock12, ArrowRightLine12, Play12, Pause12, Stop12, More12, Service12 } from '../Icon';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Switch from '../Switch';
import DatePicker from "../DatePicker";
import { useTimeTracking } from '../../hooks/useTimeTracking';
import { DefaultLayout, EveningLayout, BasicLayout, SunriseLayout, RetroLayout } from './TimeEntryLayouts';
import { useUser } from '../../hooks/useUser';

// Add this new type for the More menu items
type MoreMenuItem = {
  id: string;
  name: string;
  action: () => void;
};

const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: remainingSeconds.toString().padStart(2, '0'),
  };
};

const parseDuration = (
  { hours, minutes, seconds }: { hours: string; minutes: string; seconds: string }
) => {
  return (
    (parseInt(hours || '0', 10) * 3600) +
    (parseInt(minutes || '0', 10) * 60) +
    parseInt(seconds || '0', 10)
  );
};

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 480px;
  outline: none;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 1;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  position: relative;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 40px 80px;
    border-top: 1px solid rgba(0,0,0,0.05);
    font-size: 16px;
    line-height: 24px;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  position: absolute;
  right: 32px;
  top: 24px;
  padding: 4px 8px;
  
`;

const ButtonFooter = styled.div`
  display: flex;
  position: fixed;
  margin-top: 20px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F8F7F6;
  border-radius: 0 0 8px 8px;
  gap: 10px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const TimeInputWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const TimeInput = styled.input`
  padding: 4px 8px 4px 27px;
  font-size: 14px;
  width: 80px;
  border-radius: 8px;
  border: 0;
  background-color: transparent;
  font-feature-settings: "tnum";
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
  }
  // Add these lines to remove default browser styling
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const DateTimeContainer = styled.div`
  padding: 24px 32px 20px 32px;
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.8);
  svg path {
    fill: rgba(0,0,0,0.8);
  }
`;

const InputLabel = styled.label`
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  margin-top: 4px;
  font-weight: 500;
  margin: 0 0 4px 8px;
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeArrowRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  svg path {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 24px 32px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
`;

const ActionButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const EntityPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MoreButtonWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 56px;
  z-index: 1;
`;

interface AddTimeEntryDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  timeEntry?: TimeEntry | null;
  onSave: (timeEntry: TimeEntry) => void;
  onDelete: (timeEntryId: string) => void;
  onCreate: (timeEntryData: Partial<TimeEntry>) => Promise<TimeEntry>; // Add this line
  organizationId?: string;
  defaultProjectId?: string;
  defaultClientId?: string;
  onOverlayClick: (event: React.MouseEvent) => void;
  stopTimer: () => Promise<void>;
  defaultStartTime?: string;
  defaultEndTime?: string;
  updateLocalTimeEntry: (updatedEntry: Partial<GroupedTimeEntry>) => void;
  currentTimeEntryId: string | null;
  pauseTimer: () => void;
  resumeTimer: () => void;
}

const AddTimeEntryDrawer: React.FC<AddTimeEntryDrawerProps> = (props) => {
  const {
    isOpen,
    setIsOpen,
    timeEntry,
    onDelete,
    organizationId,
    defaultProjectId,
    defaultClientId,
    onOverlayClick,
    stopTimer,
    defaultStartTime,
    defaultEndTime,
    updateLocalTimeEntry,
    currentTimeEntryId,
    pauseTimer,
    resumeTimer,
    onCreate,
  } = props;

  const drawerRef = useRef<HTMLDivElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const { isRunning, elapsedTime } = useTimeTracking();

  // Declare editingField before it's used in useEffect
  const [editingField, setEditingField] = useState<'hours' | 'minutes' | 'seconds' | null>(null);

  // Flag to determine if we're editing a running time entry
  const isEditingRunningEntry = timeEntry && isRunning && timeEntry.id === currentTimeEntryId;

  // Ref to track if durationInputs have been initialized in create mode
  const isInitializedRef = useRef(false);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isDatePickerOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnTimeEntryItem = (clickedElement as Element).closest('.time-entry-item') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;
      const isClickOnDatePicker = (clickedElement as Element).closest('.date-picker-element') !== null;

      if (!isClickInsideDrawer && !isClickOnTimeEntryItem && !isClickOnPopover && !isClickOnDatePicker) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isDatePickerOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  const [formData, setFormData] = useState<Omit<TimeEntry, "id" | "user_id">>({
    organization_id: organizationId || "",
    client_id: defaultClientId || null,
    project_id: defaultProjectId || null,
    service_id: null,
    start_time: new Date().toISOString(),
    end_time: null,
    duration: 0,
    description: "",
    is_billable: true,
  });

  // Initial formData is set here

  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const { data: services } = useServices();

  const isEditingRef = useRef(false);

  const [latestIsBillable, setLatestIsBillable] = useState(true);

  const updateTimeEntryMutation = useMutation({
    mutationFn: async (updatedTimeEntry: Partial<TimeEntry>) => {
      if (!updatedTimeEntry.id) throw new Error("No time entry ID provided");
      // Updating time entry in the database
      const { data, error } = await supabase
        .from("time_entries")
        .update(updatedTimeEntry)
        .eq("id", updatedTimeEntry.id)
        .select()
        .single();
      if (error) throw error;
      // Time entry updated successfully
      return data;
    },
    onSuccess: (data) => {
      // Mutation successful, updating local time entry
      updateLocalTimeEntry({ ...data, is_billable: latestIsBillable } as GroupedTimeEntry);
    },
  });

  const [isSaving, setIsSaving] = useState(false);

  // Modify the debouncedUpdateTimeEntry to show saving indicator
  const debouncedUpdateTimeEntry = useRef(
    debounce(
      (id: string, changes: Partial<TimeEntry>, isEditing: boolean) => {
        // Debounced update called
        setIsSaving(true);
        updateTimeEntryMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
            setIsSaving(false);
            // Debounced update settled
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add a description...',
      }),
    ],
    content: formData.description || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setFormData(prev => ({ ...prev, description: html }));
      
      // Use the debounced update for the description field
      if (timeEntry?.id) {
        isEditingRef.current = true;
        debouncedUpdateTimeEntry(timeEntry.id, { description: html }, isEditingRef.current);
      }
    },
  });

  // Replace direct auth call with useUser hook
  const { data: userData } = useUser();
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    if (userData) {
      setUserId(userData.id);
    }
  }, [userData]);

  useEffect(() => {
    if (isOpen) {
      if (timeEntry) {
        // Editing an existing entry
        setFormData({
          organization_id: timeEntry.organization_id,
          client_id: timeEntry.client_id || defaultClientId || null,
          project_id: timeEntry.project_id || defaultProjectId || null,
          service_id: timeEntry.service_id || null,
          start_time: timeEntry.start_time,
          end_time: isEditingRunningEntry ? null : timeEntry.end_time,
          duration: isEditingRunningEntry ? elapsedTime : timeEntry.duration,
          description: timeEntry.description || "",
          is_billable: timeEntry.is_billable,
        });
        // Set latestIsBillable based on the timeEntry data
        setLatestIsBillable(timeEntry.is_billable);
        if (editor) {
          editor.commands.setContent(timeEntry.description || "");
        }
        if (isEditingRunningEntry) {
          setDurationInputs(formatDuration(elapsedTime));
        } else {
          const { hours, minutes, seconds } = formatDuration(timeEntry.duration);
          setDurationInputs({ hours, minutes, seconds });
        }
      } else {
        // Creating a new entry
        setFormData({
          organization_id: organizationId || "",
          client_id: defaultClientId || null,
          project_id: defaultProjectId || null,
          service_id: null,
          start_time: defaultStartTime || new Date().toISOString(),
          end_time: defaultEndTime || null,
          duration: defaultEndTime
            ? Math.floor((new Date(defaultEndTime).getTime() - new Date(defaultStartTime || Date.now()).getTime()) / 1000)
            : 0,
          description: "",
          is_billable: true,
        });
        // Set latestIsBillable to true for new entries
        setLatestIsBillable(true);
        if (editor) {
          editor.commands.setContent("");
        }
        if (!isInitializedRef.current) {
          setDurationInputs({ hours: '', minutes: '', seconds: '' });
          isInitializedRef.current = true;
        }
      }
    } else {
      isInitializedRef.current = false;
    }
  }, [
    isOpen,
    timeEntry,
    defaultProjectId,
    defaultClientId,
    organizationId,
    editor,
    defaultStartTime,
    defaultEndTime,
    isRunning,
    elapsedTime,
    isEditingRunningEntry,
  ]);

  // Update the useEffect to prevent overriding when editing duration fields
  useEffect(() => {
    if (isEditingRunningEntry) {
      // Only update if no duration field is being edited
      if (!editingField) {
        const formattedDuration = formatDuration(elapsedTime);
        setDurationInputs(formattedDuration);

        const newDuration = parseDuration(formattedDuration);
        const startTime = new Date(formData.start_time);
        const endTime = new Date(startTime.getTime() + newDuration * 1000);

        setFormData(prev => ({
          ...prev,
          duration: newDuration,
          end_time: endTime.toISOString(),
        }));
      }
    }
  }, [elapsedTime, isEditingRunningEntry, editingField]);

  const handleEntityChange = (field: keyof TimeEntry, value: string | null) => {
    // Updating entity field
    const updatedData = { [field]: value } as Partial<Omit<TimeEntry, "id" | "user_id">>;
    setFormData((prev) => ({
      ...prev,
      ...updatedData,
    }));
    if (timeEntry?.id) {
      isEditingRef.current = true;
      debouncedUpdateTimeEntry(timeEntry.id, updatedData, isEditingRef.current);
      updateLocalTimeEntry({ ...formData, ...updatedData, id: timeEntry.id } as GroupedTimeEntry);
    }
  };

  const handleBillableToggle = (newIsBillable: boolean) => {
    // Billable toggle called
    
    // Update the latestIsBillable state
    setLatestIsBillable(newIsBillable);
    
    // Immediately update the local state
    setFormData((prev) => {
      const updatedData = { ...prev, is_billable: newIsBillable };
      // Updating formData with new billable status
      return updatedData;
    });

    // If editing an existing time entry, debounce the API update
    if (timeEntry?.id) {
      // Existing time entry, calling debouncedUpdateTimeEntry
      isEditingRef.current = true;
      debouncedUpdateTimeEntry(timeEntry.id, { is_billable: newIsBillable }, isEditingRef.current);
      
      // Immediately update the local time entry
      const updatedLocalEntry = { ...formData, is_billable: newIsBillable, id: timeEntry.id };
      // Updating local time entry
      updateLocalTimeEntry(updatedLocalEntry as GroupedTimeEntry);
    }
  };

  const handleCreate = async () => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    const timeEntryData: Partial<TimeEntry> = {
      organization_id: formData.organization_id,
      user_id: userId,
      client_id: formData.client_id,
      project_id: formData.project_id,
      service_id: formData.service_id,
      start_time: formData.start_time,
      end_time: formData.end_time,
      duration: formData.duration,
      description: formData.description,
      is_billable: formData.is_billable,
    };

    try {
      // Use the onCreate prop instead of createTimeEntryMutation
      await onCreate(timeEntryData);
      setIsOpen(false);
      // Ensure that creating a new entry does not affect the running timer
    } catch (error) {
      console.error('Error creating time entry:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (editor && formData.description !== editor.getHTML()) {
      editor.commands.setContent(formData.description || '');
    }
  }, [editor, formData.description]);

  const handleDateChange = (field: 'start_time' | 'end_time') => (date: Date | null) => {
    if (date) {
      const currentTime = new Date(formData[field] || new Date());
      const newDateTime = new Date(date);
      newDateTime.setHours(currentTime.getHours(), currentTime.getMinutes());
      
      let updatedData = { ...formData, [field]: newDateTime.toISOString() };

      if (field === 'start_time') {
        const endTime = new Date(newDateTime.getTime() + formData.duration * 1000);
        updatedData = { ...updatedData, end_time: endTime.toISOString() };
      } else if (field === 'end_time') {
        const startTime = new Date(formData.start_time);
        const duration = Math.floor((newDateTime.getTime() - startTime.getTime()) / 1000);
        updatedData = { ...updatedData, duration };
        const { hours, minutes, seconds } = formatDuration(duration);
        setDurationInputs({ hours, minutes, seconds });
      }

      setFormData((prev) => ({
        ...prev,
        ...updatedData,
      }));
      if (timeEntry?.id) {
        debouncedUpdateTimeEntry(timeEntry.id, updatedData, isEditingRef.current);
      }
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [hours, minutes] = value.split(':').map(Number);
    
    const currentDate = new Date(formData[name as 'start_time' | 'end_time'] || new Date());
    currentDate.setHours(hours, minutes);

    let updatedData = { ...formData, [name]: currentDate.toISOString() };

    if (name === 'start_time') {
      const endTime = new Date(currentDate.getTime() + formData.duration * 1000);
      updatedData = { ...updatedData, end_time: endTime.toISOString() };
    } else if (name === 'end_time') {
      const startTime = new Date(formData.start_time);
      const duration = Math.floor((currentDate.getTime() - startTime.getTime()) / 1000);
      updatedData = { ...updatedData, duration };
      const { hours, minutes, seconds } = formatDuration(duration);
      setDurationInputs({ hours, minutes, seconds });
    }

    setFormData((prev) => ({
      ...prev,
      ...updatedData,
    }));
    if (timeEntry?.id) {
      debouncedUpdateTimeEntry(timeEntry.id, updatedData, isEditingRef.current);
    }
  };

  const formatTime = (dateString: string | null) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const [durationInputs, setDurationInputs] = useState({ hours: '', minutes: '', seconds: '' });

  // Ensure handleDurationChange is defined only once
  const handleDurationChange = (field: 'hours' | 'minutes' | 'seconds') => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (value !== '' && isNaN(Number(value))) return;

    const newDurationInputs = { ...durationInputs, [field]: value };
    setDurationInputs(newDurationInputs);

    // Calculate the new duration in seconds
    const newDuration = parseDuration({
      hours: newDurationInputs.hours || '0',
      minutes: newDurationInputs.minutes || '0',
      seconds: newDurationInputs.seconds || '0',
    });

    const startTime = new Date(formData.start_time);
    const endTime = new Date(startTime.getTime() + newDuration * 1000);

    const updatedData: Partial<TimeEntry> = {
      ...formData,
      duration: newDuration,
      end_time: endTime.toISOString(), // Set end_time for both creating and editing
    };

    setFormData((prev) => ({
      ...prev,
      ...updatedData,
    }));

    if (timeEntry?.id) {
      // Only update existing entry if editing
      debouncedUpdateTimeEntry(timeEntry.id, updatedData, isEditingRef.current);
    }
    // No update call when creating to avoid interfering with running entries
  };

  const handlePauseResume = () => {
    if (isRunning) {
      pauseTimer();
    } else {
      resumeTimer();
    }
  };

  const handleLogTime = async () => {
    await stopTimer();
    handleClose();
  };

  const isCurrentlyRunning = isRunning && timeEntry?.id === currentTimeEntryId;

  const handleDurationClick = (field: 'hours' | 'minutes' | 'seconds') => {
    if (!isCurrentlyRunning) {
      setEditingField(field);
    }
  };

  const handleDurationBlur = () => {
    setEditingField(null);
  };

  // Update the useEffect for live timer updates:
  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (isEditingRunningEntry) {
      interval = setInterval(() => {
        const formattedDuration = formatDuration(elapsedTime);
        setDurationInputs(formattedDuration);

        const newDuration = parseDuration(formattedDuration);
        const startTime = new Date(formData.start_time);
        const endTime = new Date(startTime.getTime() + newDuration * 1000);

        setFormData(prev => ({
          ...prev,
          duration: newDuration,
          end_time: endTime.toISOString(),
        }));
      }, 1000); // Update every second
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isEditingRunningEntry, elapsedTime]);

  // Replace the existing handleDeleteTimeEntry function with this:
  const handleDeleteTimeEntry = useCallback(async () => {
    if (timeEntry?.id) {
      try {
        // First, delete related entries in time_entry_invoice_items
        const { error: timeEntryInvoiceItemsError } = await supabase
          .from('time_entry_invoice_items')
          .delete()
          .eq('time_entry_id', timeEntry.id);

        if (timeEntryInvoiceItemsError) {
          console.error('Error deleting related time_entry_invoice_items:', timeEntryInvoiceItemsError);
          throw timeEntryInvoiceItemsError;
        }

        // Then, delete the time entry
        const { error: deleteError } = await supabase
          .from('time_entries')
          .delete()
          .eq('id', timeEntry.id);

        if (deleteError) {
          console.error('Error deleting time entry:', deleteError);
          throw deleteError;
        }

        onDelete(timeEntry.id);
        setIsOpen(false);
      } catch (error) {
        console.error('Failed to delete time entry:', error);
        alert('Failed to delete time entry. Please try again.');
      }
    }
  }, [timeEntry, onDelete, setIsOpen]);

  // Replace the existing layout state with this:
  const [layout, setLayout] = useState<TimeEntryLayout>(() => {
    // First try to get the layout that matches the time tracker style
    const savedTrackerStyle = localStorage.getItem('timeTrackerStyle') as TimeTrackerStyle;
    if (savedTrackerStyle) {
      return TIME_TRACKER_TO_ENTRY_LAYOUT[savedTrackerStyle];
    }
    // Fall back to saved time entry layout or default
    return (localStorage.getItem('timeEntryLayout') as TimeEntryLayout) || 'Default';
  });

  // Update the layout saving logic
  const handleLayoutChange = useCallback((newLayout: TimeEntryLayout) => {
    setLayout(newLayout);
    localStorage.setItem('timeEntryLayout', newLayout);
    // Find the corresponding time tracker style
    const timeTrackerStyle = Object.entries(TIME_TRACKER_TO_ENTRY_LAYOUT).find(
      ([, entryLayout]) => entryLayout === newLayout // Removed unused '_' parameter
    )?.[0] as TimeTrackerStyle;
    
    if (timeTrackerStyle) {
      localStorage.setItem('timeTrackerStyle', timeTrackerStyle);
      // Dispatch a custom event to notify the popover
      window.dispatchEvent(new CustomEvent('timeEntryLayoutChange', { 
        detail: { timeTrackerStyle } 
      }));
    }
  }, []);

  // Listen for changes from the popover
  useEffect(() => {
    const handlePopoverLayoutChange = (event: CustomEvent<{ timeEntryLayout: TimeEntryLayout }>) => {
      setLayout(event.detail.timeEntryLayout);
      localStorage.setItem('timeEntryLayout', event.detail.timeEntryLayout);
    };

    window.addEventListener('timeTrackerLayoutChange', handlePopoverLayoutChange as EventListener);
    return () => {
      window.removeEventListener('timeTrackerLayoutChange', handlePopoverLayoutChange as EventListener);
    };
  }, []);

  // Create the More menu items
  const moreMenuItems: MoreMenuItem[] = [
    { id: 'layout-default', name: 'Default Layout', action: () => handleLayoutChange('Default') },
    { id: 'layout-evening', name: 'Evening Layout', action: () => handleLayoutChange('Evening') },
    { id: 'layout-basic', name: 'Basic Layout', action: () => handleLayoutChange('Basic') },
    { id: 'layout-sunrise', name: 'Sunrise Layout', action: () => handleLayoutChange('Sunrise') },
    { id: 'layout-retro', name: 'Retro Layout', action: () => handleLayoutChange('Retro') },
    { id: 'delete', name: 'Delete', action: handleDeleteTimeEntry },
  ];

  const renderDurationLayout = () => {
    const layoutProps = {
      durationInputs,
      isCurrentlyRunning,
      editingField,
      handleDurationClick,
      handleDurationChange,
      handleDurationBlur,
    };

    switch (layout) {
      case 'Evening':
        return <EveningLayout {...layoutProps} />;
      case 'Basic':
        return <BasicLayout {...layoutProps} />;
      case 'Sunrise':
        return <SunriseLayout {...layoutProps} />;
      case 'Retro':
        return <RetroLayout {...layoutProps} />;
      default:
        return <DefaultLayout {...layoutProps} />;
    }
  };

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={onOverlayClick} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            {timeEntry && (
              <MoreButtonWrapper>
                <EntityPicker
                  selectedId={null}
                  onChange={(id) => {
                    const selectedItem = moreMenuItems.find(item => item.id === id);
                    if (selectedItem) {
                      selectedItem.action();
                    }
                  }}
                  entities={moreMenuItems}
                  label="More"
                  icon={<More12 />}
                  iconOnly
                  placement="bottom-end"
                  enableSearch={false}
                />
              </MoreButtonWrapper>
            )}
            {renderDurationLayout()}
            {isCurrentlyRunning ? (
              <ActionButtonsContainer>
                <ActionButton onClick={handlePauseResume}>
                  {isRunning ? <><Pause12 /> Pause</> : <><Play12 /> Resume</>}
                </ActionButton>
                <ActionButton onClick={handleLogTime}>
                  <Stop12 /> Log Time
                </ActionButton>
              </ActionButtonsContainer>
            ) : (
              <DateTimeContainer>
                <InputField>
                  <InputLabel htmlFor="start_time">Start</InputLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      selectedDate={formData.start_time ? new Date(formData.start_time) : null}
                      onChange={handleDateChange('start_time')}
                      label="Start Date"
                      id="start_date"
                      placement="left-start"
                      icon={<Calendar12 />}
                      onOpenChange={(isOpen) => {
                        setIsDatePickerOpen(isOpen);
                        setIsAnyPopoverOpen(isOpen);
                      }}
                    />
                    <TimeInputWrapper>
                      <IconWrapper>
                        <Clock12 />
                      </IconWrapper>
                      <TimeInput
                        type="time"
                        id="start_time"
                        name="start_time"
                        value={formatTime(formData.start_time)}
                        onChange={handleTimeChange}
                        required
                      />
                    </TimeInputWrapper>
                  </DateTimeWrapper>
                </InputField>
                <TimeArrowRightWrapper>
                  <ArrowRightLine12 />
                </TimeArrowRightWrapper>
                <InputField>
                  <InputLabel htmlFor="end_time">End</InputLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      selectedDate={formData.end_time ? new Date(formData.end_time) : null}
                      onChange={handleDateChange('end_time')}
                      label="End Date"
                      id="end_date"
                      placement="left-start"
                      icon={<Calendar12 />}
                      onOpenChange={(isOpen) => {
                        setIsDatePickerOpen(isOpen);
                        setIsAnyPopoverOpen(isOpen);
                      }}
                    />
                    <TimeInputWrapper>
                      <IconWrapper>
                        <Clock12 />
                      </IconWrapper>
                      <TimeInput
                        type="time"
                        id="end_time"
                        name="end_time"
                        value={formatTime(formData.end_time)}
                        onChange={handleTimeChange}
                      />
                    </TimeInputWrapper>
                  </DateTimeWrapper>
                </InputField>
              </DateTimeContainer>
            )}
            <InputFieldsContainer>
              <EntityPickerWrapper>
                <EntityPicker
                  selectedId={formData.client_id}
                  onChange={(id) => handleEntityChange("client_id", id)}
                  entities={clients?.map(client => ({ id: client.id, name: client.full_name })) || []}
                  label="Client"
                  allowUnassigned
                  icon={<Client12 />}
                  onPopoverOpenChange={setIsAnyPopoverOpen}
                />
              </EntityPickerWrapper>
              <EntityPickerWrapper>
                <EntityPicker
                  selectedId={formData.project_id}
                  onChange={(id) => handleEntityChange("project_id", id)}
                  entities={projects?.map(project => ({ id: project.id, name: project.name })) || []}
                  label="Project"
                  allowUnassigned
                  icon={<Project12 />}
                  onPopoverOpenChange={setIsAnyPopoverOpen}
                />
               
              </EntityPickerWrapper>
              <EntityPickerWrapper>
                <EntityPicker
                  selectedId={formData.service_id}
                  onChange={(id) => handleEntityChange("service_id", id)}
                  entities={services?.map(service => ({ id: service.id, name: service.name })) || []}  
                  label="Service"
                  allowUnassigned
                  icon={<Service12 />}
                  onPopoverOpenChange={setIsAnyPopoverOpen}
                />
                
              </EntityPickerWrapper>
              <InputField>
                <ToggleWrapper>
                  <Switch
                    checked={formData.is_billable}
                    onChange={handleBillableToggle}
                    label={formData.is_billable ? "Billable" : "Non-billable"}
                    labelPosition="left"
                  />
                </ToggleWrapper>
              </InputField>
            </InputFieldsContainer>
            <EditorWrapper>
              <EditorContent editor={editor} />
            </EditorWrapper>
            {!isCurrentlyRunning && !timeEntry && (
              <ButtonFooter>
                <Button onClick={handleCreate}>Create Time Entry</Button>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
              </ButtonFooter>
            )}
            {isSaving && <SavingIndicator />}
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

// Add this new component
const SavingIndicator = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000000;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default AddTimeEntryDrawer;
