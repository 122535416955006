import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams, useNavigate } from '@tanstack/react-router';
import { projectRoute } from './router';
import { supabase } from "./supabaseClient";
import { Project, Invoice, Expense, Task, TimeEntry, InvoiceData } from "./types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import Button from "./components/Button";
import EntityPicker from "./components/EntityPicker";
import { Client12, Calendar12, Tasks32, Status12, More12, Time32 } from "./components/Icon";
import AddExpenseDrawer from "./components/Expenses/AddExpenseDrawer";
import Tabs from "./components/Tabs";
import DatePicker from "./components/DatePicker";
import TaskDrawer from './components/Tasks/TaskDrawer';
import TaskDetails from './components/Tasks/TaskDetails';
import { useProjects } from './hooks/useProjects';
import { useOrganization } from './hooks/useOrganization';
import { useClients } from './hooks/useClients';
import { useCreateInvoiceMutation } from "./hooks/useCreateInvoiceMutation";
import { useExpenseOperations } from './hooks/useExpenseOperations';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { useUser } from './hooks/useUser';
import { useTaskMutations } from './hooks/useTaskMutations';
import NotesEditor from "./components/NotesEditor";
import { useInvoices } from './hooks/useInvoices';
import { useTaskStatuses } from './hooks/useTaskStatuses';
import TaskTable from './components/Tasks/TaskTable';
import AddTimeEntryDrawer from "./components/Time/AddTimeEntryDrawer";
import { debounce } from "lodash";
import { useTimeTracking } from './hooks/useTimeTracking';
import InvoiceList from './components/InvoiceList';
import ExpenseList from './components/ExpenseList';
import InvoiceDrawer from './components/Invoices/InvoiceDrawer';
import InvoiceDetails from './components/Invoices/InvoiceDetails';
import { useFetchTasks } from './hooks/useFetchTasks';
import { usePageContext } from './hooks/usePageContext';
import SummaryCards from './components/SummaryCards';
import { formatDuration } from './utils/timeUtils';
import { useDeleteProjectMutation } from './hooks/useDeleteProjectMutation';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';
import TimeEntryTable from './components/Time/TimeEntryTable';
import type { GroupedTimeEntry } from './types';
import { Link } from '@tanstack/react-router';
import { formatCurrency } from './utils/formatCurrency';
import { ColumnDef } from '@tanstack/react-table';
import type { Row } from '@tanstack/react-table';
import SelectedTimeEntriesOverlay from './components/Time/SelectedTimeEntriesOverlay';

const ProjectPageWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const InputField = styled.div`

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }

  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    min-height: 100px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const PageContent = styled.div`
  height: 100%;
`;

const ProjectMetaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 16px 32px 8px;
  gap: 4px;
`;

const TabContent = styled.div`
  height: calc(100% - 48px); // Adjust this value based on your tabs height
`;

const EditableTitleWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: 24px;
  max-width: 100%;
  margin-left: -8px;
`;

const EditableTitle = styled.div`
  font-size: inherit;
  line-height: inherit;
  font-family: 'PP Mori', sans-serif;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0 8px;
  border-radius: 8px;
  white-space: pre;
  display: inline-block;
  min-width: 1px;
  color: rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const OverviewContent = styled.div`
  margin: 0 auto;
  max-width: 960px;
`;

const ProjectPage: React.FC = () => {
  const { id } = useParams({ from: projectRoute.id });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: projects } = useProjects();
  const { data: clients = [] } = useClients();
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationUsers } = useOrganizationUsers();
  const { data: user } = useUser();
  const { taskStatuses, getDefaultStatus } = useTaskStatuses();
  const { stopTimer, pauseTimer, resumeTimer, currentTimeEntryId } = useTimeTracking();
  const { updateTaskMutation, createTaskMutation, deleteTaskMutation } = useTaskMutations();
  const { setPageHeaderProps } = usePageContext();
  const deleteProjectMutation = useDeleteProjectMutation();

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [localProject, setLocalProject] = useState<Project | null>(null);
  const [localDescription, setLocalDescription] = useState<string>('');
  const [activeTab, setActiveTab] = useState<'overview' | 'invoices' | 'expenses' | 'tasks' | 'time-entries'>('overview');
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [, setIsDatePickerOpen] = useState(false);
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isTimeEntryDrawerOpen, setIsTimeEntryDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const { data: emailTrackingData } = useEmailTrackingData(selectedInvoiceId);

  const isEditingRef = useRef(false);

  const projectStatuses = [
    { id: "planning", name: "Planning" },
    { id: "in_progress", name: "In Progress" },
    { id: "on_hold", name: "On Hold" },
    { id: "completed", name: "Completed" },
  ];

  const { data: project  } = useQuery<Project>({
    queryKey: ["project", id, organizationId],
    queryFn: async () => {
      if (!id || !organizationId) throw new Error("Project ID or Organization ID not found");
      
      const { data, error } = await supabase
        .from("projects")
        .select("*")
        .eq("id", id)
        .eq("organization_id", organizationId)
        .single();
      if (error) throw error;
      return data as Project;
    },
    enabled: !!id && !!organizationId,
  });

  useEffect(() => {
    if (project) {
      setLocalProject(project);
      setLocalDescription(project.description || '');
    }
  }, [project]);

  const handleCreateInvoice = useCallback(async () => {
    if (project && organizationId) {
      try {
        const invoiceData = {
          project_id: project.id,
          client_id: project.client_id || undefined,
          organization_id: organizationId,
        };

        console.log("Creating invoice with data:", invoiceData);

        const newInvoice = await createInvoiceMutation.mutateAsync(invoiceData);

        console.log("New invoice created:", newInvoice);

        if (newInvoice) {
          queryClient.invalidateQueries({ queryKey: ["projectInvoices", id, organizationId] });
          navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
        }
      } catch (error) {
        console.error("Error creating invoice:", error);
      }
    }
  }, [project, organizationId, createInvoiceMutation, queryClient, id, navigate]);

  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();

  const projectInvoices = useMemo(() => {
    return invoices?.filter(invoice => invoice.project_id === id).map(invoice => ({
      ...invoice,
      items: [], // You might need to fetch invoice items separately
      invoice_template: 'default', // Set a default value or fetch from somewhere
      header_color: '#ffffff', // Set a default value or fetch from somewhere
      header_text_color: '#000000', // Set a default value or fetch from somewhere
      font: 'Arial', // Set a default value or fetch from somewhere
      due_days: 30, // Set a default value or calculate based on invoice_date and due_date
      currency: invoice.currency || 'USD', // Assuming currency exists in Invoice type, otherwise set a default
      payments: [], // Add an empty array for payments
    } as unknown as InvoiceData)) || [];
  }, [invoices, id]);


  const { data: expenses, isLoading: isLoadingExpenses } = useQuery<Expense[], Error>({
    queryKey: ["projectExpenses", id, organizationId],
    queryFn: async () => {
      if (!organizationId || !id) throw new Error("Organization or Project ID not found");
      
      // Check for prefetched data
      const cachedData = queryClient.getQueryData(["projectExpenses", id, organizationId]);
      if (cachedData) return cachedData as Expense[];

      // If not in cache, fetch from Supabase
      const { data, error } = await supabase
        .from("expenses")
        .select("*")
        .eq("project_id", id)
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Expense[];
    },
    enabled: !!id && !!organizationId,
  });

  const { data: tasks, isLoading: isLoadingTasks, localUpdatesRef } = useFetchTasks();

  // First, move sortTasks definition before its usage
  const sortTasks = useCallback((tasks: Task[]) => {
    return [...tasks].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, []);

  // Add this ref to track if we're in the initial mount
  const isInitialMount = useRef(true);
  const initializedFromCache = useRef(false);
  const [localTasks, setLocalTasks] = useState<Task[]>(() => {
    const cachedTasks = queryClient.getQueryData(['tasks', organizationId]) as Task[] | undefined;
    if (cachedTasks) {
      initializedFromCache.current = true;
      return sortTasks(cachedTasks.filter(task => task.project_id === id));
    }
    return [];
  });

  // Update the tasks effect to handle initial mount
  useEffect(() => {
    if (tasks) {
      if (isInitialMount.current || !initializedFromCache.current) {
        setLocalTasks(sortTasks(tasks.filter(task => task.project_id === id)));
        initializedFromCache.current = true;
      }
      isInitialMount.current = false;
    }
  }, [tasks, sortTasks, id]);

  const updateLocalTask = useCallback((updatedTask: Partial<Task>) => {
    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );
  }, []);

  const updateTask = useCallback((updatedTask: Partial<Task>, isEditing: boolean = false) => {
    if (!updatedTask.id) return;

    // Update localUpdatesRef with the editing state
    localUpdatesRef.current.set(updatedTask.id, isEditing);

    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );

    updateTaskMutation.mutate(updatedTask, {
      onSettled: () => {
        // Remove the task ID from the localUpdatesRef if not editing
        if (!isEditing) {
          localUpdatesRef.current.delete(updatedTask.id!);
        }
      },
    });
  }, [updateTaskMutation, localUpdatesRef]);

  const handleCreateTask = useCallback(() => {
    if (!organizationId || !user || !id) {
      console.error('Organization, user, or project not found');
      return;
    }

    const defaultStatus = getDefaultStatus();

    if (!defaultStatus) {
      console.error('No default status found');
      return;
    }

    // Get the highest position from existing tasks
    const maxPosition = localTasks.reduce((max, task) => 
      task.position > max ? task.position : max, 
      0
    );

    const newTask = {
      description: '',
      is_completed: false,
      due_date: null,
      notes: '',
      client_id: localProject?.client_id || null,
      project_id: id,
      user_id: user.id,
      organization_id: organizationId,
      status_id: defaultStatus.id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      position: maxPosition + 65536, // Add a large increment to allow for future insertions
    };

    createTaskMutation.mutate(newTask, {
      onSuccess: (createdTask) => {
        setLocalTasks(prev => sortTasks([...prev, createdTask]));
        setSelectedTaskId(createdTask.id);
        setIsTaskDrawerOpen(true);
      },
      onError: (error) => {
        console.error('Error creating task:', error);
      }
    });
  }, [
    createTaskMutation, 
    organizationId, 
    user, 
    id, 
    getDefaultStatus, 
    localProject, 
    sortTasks,
    localTasks // Add localTasks to dependencies
  ]);

  const handleDeleteTask = useCallback((taskId: string) => {
    deleteTaskMutation.mutate(taskId, {
      onSuccess: () => {
        setLocalTasks(prev => prev.filter(task => task.id !== taskId));
        setSelectedTaskId(null);
        setIsTaskDrawerOpen(false);
      },
      onError: (error) => {
        console.error('Failed to delete task:', error);
        alert('Failed to delete task. Please try again.');
      },
    });
  }, [deleteTaskMutation]);

  const { data: timeEntries, isLoading: isLoadingTimeEntries } = useQuery<TimeEntry[]>({
    queryKey: ['projectTimeEntries', id, organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('time_entries')
        .select('*')
        .eq('project_id', id)
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!id && !!organizationId,
  });

  const updateProjectMutation = useMutation<Project, Error, Partial<Project> & { id: string }>({
    mutationFn: async (updatedProject) => {
      const { id, ...updateData } = updatedProject;
      const { data, error } = await supabase
        .from("projects")
        .update(updateData)
        .eq("id", id)
        .select()
        .single();
      if (error) throw error;
      return data as Project;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["project", data.id, organizationId], data);
      queryClient.invalidateQueries({ queryKey: ["projects", organizationId] });
    },
  });

  const debouncedUpdateProject = useRef(
    debounce(
      (id: string, changes: Partial<Project>, isEditing: boolean) => {
        updateProjectMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleInputChange = (field: keyof Project, value: string | boolean | null) => {
    isEditingRef.current = true;
    setLocalProject(prev => prev ? { ...prev, [field]: value } : null);

    if (localProject?.id) {
      debouncedUpdateProject(localProject.id, { [field]: value }, isEditingRef.current);
    }
  };

  const handleStatusChange = (statusId: string | null) => {
    handleInputChange('status', statusId);
  };

  const handleClientChange = (clientId: string | null) => {
    handleInputChange('client_id', clientId);
  };

  const handleDueDateChange = (date: Date | null) => {
    handleInputChange('due_date', date ? date.toISOString().split('T')[0] : null);
  };

  // Update the handleDescriptionChange function
  const handleDescriptionChange = useCallback((content: string) => {
    isEditingRef.current = true;
    setLocalDescription(content);

    if (localProject?.id) {
      debouncedUpdateProject(localProject.id, { description: content }, isEditingRef.current);
    }
  }, [localProject, debouncedUpdateProject]);

  useEffect(() => {
    if (id && organizationId) {
      // Prefetch project data
      queryClient.prefetchQuery({
        queryKey: ["project", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("projects")
            .select("*")
            .eq("id", id)
            .eq("organization_id", organizationId)
            .single();
          if (error) throw error;
          return data as Project;
        },
      });

      // Prefetch invoices
      queryClient.prefetchQuery({
        queryKey: ["projectInvoices", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("invoices")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Invoice[];
        },
      });

      // Prefetch expenses
      queryClient.prefetchQuery({
        queryKey: ["projectExpenses", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("expenses")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Expense[];
        },
      });

      // Prefetch tasks
      queryClient.prefetchQuery({
        queryKey: ["projectTasks", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("tasks")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Task[];
        },
      });
    }
  }, [id, queryClient, organizationId]);

  useEffect(() => {
    if (project && project.name === "") {
      setIsTitleFocused(true);
    }
  }, [project]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localProject]);

  const handleCloseTaskDrawer = useCallback(() => {
    setIsTaskDrawerOpen(false);
    setSelectedTaskId(null);
  }, []);

  const handleTaskClick = useCallback((task: Task) => {
    setSelectedTaskId(task.id);
    setIsTaskDrawerOpen(true);
  }, []);

  const handleDatePickerOpenChange = useCallback((isOpen: boolean) => {
    setIsDatePickerOpen(isOpen);
  }, []);

  const handleTitleChange = useCallback(() => {
    if (titleRef.current && localProject?.id) {
      const newTitle = titleRef.current.textContent || '';
      setLocalProject(prev => prev ? { ...prev, name: newTitle } : null);
      debouncedUpdateProject(localProject.id, { name: newTitle }, isEditingRef.current);
    }
  }, [localProject, debouncedUpdateProject]);

  useEffect(() => {
    if (titleRef.current && localProject) {
      titleRef.current.textContent = localProject.name;
    }
  }, [localProject]);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleSaveTimeEntry = useCallback(async () => {
    await stopTimer();
    queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    setIsTimeEntryDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, [queryClient, id, organizationId, stopTimer]);

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [queryClient, id, organizationId]);

  const handleCreateTimeEntry = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    const { data, error } = await supabase
      .from('time_entries')
      .insert({ ...timeEntryData, project_id: id, organization_id: organizationId })
      .select()
      .single();

    if (error) throw error;
    queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    return data;
  }, [id, organizationId, queryClient]);

  const handleExpenseSelectionChange = useCallback((selectedIds: string[]) => {
    console.log('Selected expense IDs:', selectedIds);
    // Add any additional logic here if needed
  }, []);

  const {
    isExpenseDrawerOpen,
    setIsExpenseDrawerOpen,
    editingExpense,
    handleEditExpense: handleEditExpenseFromHook,
    handleDeleteExpense,
    handleSaveExpense,
    handleCreateExpense,
  } = useExpenseOperations(id, undefined, organizationId);

  const renderActionButtons = useCallback(() => {
    switch (activeTab) {
      case "invoices":
        return <Button buttonType="secondary" onClick={handleCreateInvoice}>New Invoice</Button>;
      case "expenses":
        return <Button buttonType="secondary" onClick={() => setIsExpenseDrawerOpen(true)}>New Expense</Button>;
      case "tasks":
        return <Button buttonType="secondary" onClick={handleCreateTask}>New Task</Button>;
      case "time-entries":
        return <Button buttonType="secondary" onClick={handleAddNewTimeEntry}>New Time Entry</Button>;
      default:
        return null;
    }
  }, [activeTab, handleCreateInvoice, handleCreateTask, handleAddNewTimeEntry, setIsExpenseDrawerOpen]);

  const taskColumns: ColumnDef<Task>[] = useMemo(
    () => [
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'status_id',
        header: 'Status',
        cell: ({ row }: { row: Row<Task> }) => {
          const status = taskStatuses?.find(s => s.id === row.original.status_id);
          return status ? status.name : 'Unknown';
        },
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        cell: ({ getValue }: { getValue: () => unknown }) => 
          getValue() ? new Date(getValue() as string).toLocaleDateString() : 'No due date',
      },
      {
        accessorKey: 'user_id',
        header: 'Assignee',
        cell: ({ row }: { row: Row<Task> }) => {
          const user = organizationUsers?.find(u => u.id === row.original.user_id);
          return user ? user.full_name || user.email : 'Unassigned';
        },
      },
    ],
    [taskStatuses, organizationUsers]
  );

  const taskEmptyState = useMemo(() => ({
    icon: <Tasks32 />,
    message: "No tasks for this project",
    subMessage: "Create your first task to get started",
    action: {
      label: "New Task",
      onClick: handleCreateTask
    }
  }), [handleCreateTask]);

  const handleInvoiceRowClick = useCallback((invoice: InvoiceData) => {
    setSelectedInvoiceId(invoice.id);
    setSelectedInvoice(invoice);
    setIsInvoiceDrawerOpen(true);
  }, []);

  const handleCloseInvoiceDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsInvoiceDrawerOpen(false);
      setSelectedInvoice(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseInvoiceDrawer();
    }
  }, [handleCloseInvoiceDrawer, isAnyPopoverOpen]);

  const handleDeleteProject = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this project? This action cannot be undone.")) {
      deleteProjectMutation.mutate(id, {
        onSuccess: () => {
          navigate({ to: '/projects' });
        },
        onError: (error) => {
          console.error('Failed to delete project:', error);
          alert('Failed to delete project. Please try again.');
        },
      });
    }
  }, [deleteProjectMutation, id, navigate]);

  const moreOptions = useMemo(() => [
    { id: 'delete', name: 'Delete Project' },
  ], []);

  const handleMoreOptionSelect = useCallback((optionId: string | null) => {
    if (optionId === 'delete') {
      handleDeleteProject();
    }
  }, [handleDeleteProject]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/projects",
      parentName: "Projects",
      title: (
        <>
        <EditableTitleWrapper>
          <EditableTitle
            ref={titleRef}
            contentEditable
            suppressContentEditableWarning
            onInput={handleTitleChange}
            onBlur={handleTitleChange}
            data-placeholder="Enter project name"
            autoFocus={isTitleFocused}
            onFocus={() => setIsTitleFocused(true)}
          />
        </EditableTitleWrapper>
        <EntityPicker
          selectedId={null}
          onChange={handleMoreOptionSelect}
          entities={moreOptions}
          label="More"
          icon={<More12 />}
          placement="bottom-start"
          iconOnly
          enableSearch={false}
        />
        </>
      )
    });
  }, [setPageHeaderProps, localProject, isTitleFocused, handleTitleChange]);

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [selectedEntriesCount, setSelectedEntriesCount] = useState(0);
  const [currentDurations, setCurrentDurations] = useState<{ [id: string]: number }>({});

  // Add this effect to handle running timers
  useEffect(() => {
    const timers: { [id: string]: NodeJS.Timeout } = {};

    timeEntries?.forEach(entry => {
      if (entry.start_time && !entry.end_time) {
        if (!timers[entry.id]) {
          timers[entry.id] = setInterval(() => {
            setCurrentDurations(prev => ({
              ...prev,
              [entry.id]: prev[entry.id] ? prev[entry.id] + 1 : Math.floor((Date.now() - new Date(entry.start_time).getTime()) / 1000),
            }));
          }, 1000);
        }
      } else {
        if (timers[entry.id]) {
          clearInterval(timers[entry.id]);
          delete timers[entry.id];
        }
      }
    });

    return () => {
      Object.values(timers).forEach((timer) => clearInterval(timer));
    };
  }, [timeEntries]);

  // Add these handlers
  const handleSelectionChange = useCallback((selectedIds: string[], count: number) => {
    setSelectedEntries(selectedIds);
    setSelectedEntriesCount(count);
    
    // Actually use the selected entries
    if (count > 0) {
      console.log(`Selected ${count} entries:`, selectedIds);
      // Add logic here to handle selected entries
      // For example, enable bulk actions or update UI state
    }
  }, []);

  const getRowKey = useCallback((entry: TimeEntry) => entry.id, []);

  const updateLocalTimeEntry = useCallback((updatedEntry: Partial<TimeEntry>) => {
    if (!timeEntries) return;
    
    const newEntries = timeEntries.map(entry =>
      entry.id === updatedEntry.id ? { ...entry, ...updatedEntry } : entry
    );
    
    // Update the query cache
    queryClient.setQueryData(['projectTimeEntries', id, organizationId], newEntries);
  }, [timeEntries, queryClient, id, organizationId]);

  const timeEntryEmptyState = useMemo(() => ({
    icon: <Time32 />,
    message: 'No time entries yet',
    subMessage: 'Start tracking time for this project',
    action: {
      label: 'Add Time Entry',
      onClick: () => setIsTimeEntryDrawerOpen(true)
    }
  }), []);

  const timeEntryColumns = useMemo<ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }>[]>(() => [
    {
      id: 'duration',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return row.totalDuration;
        }
        return row.duration;
      },
      header: 'Duration',
      cell: ({ row }: { row: Row<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }> }) => {
        const value = row.getValue('duration') as number;
        return formatDuration(value);
      },
    },
    {
      id: 'description',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.description;
      },
      header: 'Description',
    },
    {
      id: 'client',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.client?.full_name || '';
      },
      header: 'Client',
    },
    {
      id: 'project',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.project?.name || '';
      },
      header: 'Project',
    },
    {
      id: 'user',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.user_full_name || '';
      },
      header: 'User',
    },
    {
      id: 'invoice',
      accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
        if ('isGroupHeader' in row) {
          return null;
        }
        return row.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null;
      },
      header: 'Invoice',
      cell: ({ getValue }: { getValue: () => unknown }) => {
        const invoiceId = getValue() as string | null;
        if (!invoiceId) return 'Not Invoiced';
        return (
          <Link 
            to="/invoice/$id" 
            params={{ id: invoiceId }}
            search={{ from: '/projects' }}
          >
            View Invoice
          </Link>
        );
      },
    },
  ], []);

  // Add this function to group time entries by date
  const groupTimeEntries = useMemo(() => (entries: TimeEntry[]): (GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number })[] => {
    const grouped: { [key: string]: GroupedTimeEntry[] } = {};
    
    entries?.forEach(entry => {
      const groupDate = new Date(entry.start_time).toISOString().split('T')[0];
      if (!grouped[groupDate]) {
        grouped[groupDate] = [];
      }
      grouped[groupDate].push({
        ...entry,
        groupDate,
        user_full_name: organizationUsers?.find(u => u.id === entry.user_id)?.full_name || 'Unknown',
        invoiceId: entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null
      });
    });

    return Object.entries(grouped).flatMap(([date, entries]) => [
      { 
        id: date, 
        isGroupHeader: true, 
        totalDuration: entries.reduce((sum, entry) => sum + entry.duration, 0) 
      },
      ...entries
    ]);
  }, [organizationUsers]);

  // Update the timeEntryTableContent to use groupTimeEntries
  const timeEntryTableContent = (
    <TimeEntryTable
      timeEntries={timeEntries ? groupTimeEntries(timeEntries) : []}
      columns={timeEntryColumns}
      isLoading={isLoadingTimeEntries}
      isInitialLoading={isLoadingTimeEntries}
      onRowClick={(entry) => {
        setSelectedTimeEntryId(entry.id);
        setIsTimeEntryDrawerOpen(true);
      }}
      onImmediateUpdate={updateLocalTimeEntry}
      getRowKey={getRowKey}
      clients={clients || []}
      projects={projects || []}
      organizationUsers={organizationUsers || []}
      onEntriesUpdate={(entries) => {
        queryClient.setQueryData(['projectTimeEntries', id, organizationId], entries);
      }}
      containerRef={containerRef}
      emptyState={timeEntryEmptyState}
      onDeleteEntry={handleDeleteTimeEntry}
      onSelectionChange={handleSelectionChange}
      onInvoiceClick={(invoiceId) => navigate({ to: '/invoice/$id', params: { id: invoiceId } })}
      hasNextPage={false}
      isFetchingNextPage={false}
      fetchNextPage={() => {}}
      timeFormat="decimal"
      currentDurations={currentDurations}
    />
  );

  // Replace the existing time entries tab content with:
  {activeTab === "time-entries" && timeEntryTableContent}

  const renderSummaryCards = useCallback(() => {
    if (!project || !timeEntries || !expenses || !invoices) return null;

    // Calculate invoice totals
    const totalInvoiced = projectInvoices.reduce((total, invoice) => total + (invoice.total || 0), 0);
    const totalPaid = projectInvoices.reduce((total, invoice) => total + (invoice.total - (invoice.amount_due || 0)), 0);
    const outstandingBalance = totalInvoiced - totalPaid;
    
    // Calculate total expenses
    const totalExpenses = expenses.reduce((total, expense) => total + (expense.amount || 0), 0);

    // Calculate total time
    const totalTime = timeEntries.reduce((total, entry) => total + (entry.duration || 0), 0);

    const cards = [
      {
        title: 'Total Invoiced',
        amount: formatCurrency(totalInvoiced),
        onClick: () => setActiveTab('invoices'),
        isSelected: activeTab === 'invoices',
        selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
        selectedForegroundColor: '#FFFFFF'
      },
      {
        title: 'Outstanding Balance',
        amount: formatCurrency(outstandingBalance),
        onClick: () => setActiveTab('invoices'),
        isSelected: activeTab === 'invoices',
        selectedBackgroundColor: outstandingBalance > 0 ? '#CA4B39' : 'rgba(0, 0, 0, 0.8)',
        selectedForegroundColor: '#FFFFFF'
      },
      {
        title: 'Total Expenses',
        amount: formatCurrency(totalExpenses),
        onClick: () => setActiveTab('expenses'),
        isSelected: activeTab === 'expenses',
        selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
        selectedForegroundColor: '#FFFFFF'
      },
      {
        title: 'Total Time',
        amount: formatDuration(totalTime),
        onClick: () => setActiveTab('time-entries'),
        isSelected: activeTab === 'time-entries',
        selectedBackgroundColor: 'rgba(0, 0, 0, 0.8)',
        selectedForegroundColor: '#FFFFFF'
      }
    ];

    return <SummaryCards cards={cards} />;
  }, [project, timeEntries, expenses, projectInvoices, activeTab, setActiveTab]);

  const handleTasksUpdate = useCallback((updatedTasks: Task[]) => {
    const projectTasks = updatedTasks.filter(task => task.project_id === id);
    setLocalTasks(projectTasks);
  }, [id]);

  const handleBulkClientChange = useCallback((clientId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, client_id: clientId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkProjectChange = useCallback((projectId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, project_id: projectId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkDelete = useCallback(() => {
    if (selectedEntries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedEntries.length} time entry(ies)?`)) {
      selectedEntries.forEach(id => handleDeleteTimeEntry(id));
      setSelectedEntries([]);
      setSelectedEntriesCount(0);
    }
  }, [selectedEntries, handleDeleteTimeEntry]);

  return (
    <ProjectPageWrapper ref={containerRef}>
      <ProjectMetaWrapper>
        <InputField>
          <EntityPicker
            selectedId={localProject?.client_id || null}
            onChange={handleClientChange}
            entities={clients.map(client => ({ id: client.id, name: client.full_name || client.email || 'Unknown Client' }))}
            label="Client"
            allowUnassigned={true}
            icon={<Client12 />}
            placement="bottom-start"
          />
        </InputField>
        <InputField>
          <EntityPicker
            selectedId={localProject?.status || null}
            onChange={handleStatusChange}
            entities={projectStatuses}
            label="Status"
            allowUnassigned={true}
            icon={<Status12 />}
            placement="bottom-start"
          />
        </InputField>
        <InputField>
          <DatePicker
            selectedDate={localProject?.due_date ? new Date(localProject.due_date) : null}
            onChange={handleDueDateChange}
            label="Due Date"
            id="project-due-date"
            onOpenChange={handleDatePickerOpenChange}
            icon={<Calendar12 />}
            placement="bottom-start"
            showClearDate={true}
          />
        </InputField>
      </ProjectMetaWrapper>
      {renderSummaryCards()}
      <PageContent>
        <Section>

          <Tabs
            tabs={[
              { id: "overview", label: "Overview" },
              { id: "invoices", label: "Invoices" },
              { id: "expenses", label: "Expenses" },
              { id: "tasks", label: "Tasks" },
              { id: "time-entries", label: "Time" },
            ]}
            activeTab={activeTab}
            onTabChange={(tabId) => setActiveTab(tabId as typeof activeTab)}
            actionButtons={renderActionButtons()}
          />

          <TabContent>
            {activeTab === "overview" && (
              <OverviewContent>
                <InputField>
                  <NotesEditor
                    key={localProject?.id} // This forces re-render when project changes
                    initialContent={localDescription}
                    onUpdate={handleDescriptionChange}
                  />
                </InputField>
              </OverviewContent>
            )}

            {activeTab === "invoices" && (
              <InvoiceList
                invoices={projectInvoices}
                isLoading={isLoadingInvoices}
                onCreateInvoice={handleCreateInvoice}
                containerRef={containerRef}
                onSelectionChange={(selectedIds) => {
                  console.log('Selected invoice IDs in ProjectPage:', selectedIds);
                }}
                onRowClick={handleInvoiceRowClick}
                selectedInvoiceId={selectedInvoice?.id || null}
                sorting={[]}
                onSortingChange={() => {}}
              />
            )}

            {activeTab === "expenses" && (
              <ExpenseList
                expenses={expenses || []}
                isLoading={isLoadingExpenses}
                onCreateExpense={() => setIsExpenseDrawerOpen(true)}
                onEditExpense={handleEditExpenseFromHook}
                containerRef={containerRef}
                onSelectionChange={handleExpenseSelectionChange}
                sorting={[]}
                onSortingChange={() => {}}
              />
            )}

            {activeTab === "tasks" && (
              <TaskTable
                tasks={localTasks}
                columns={taskColumns}
                isLoading={isLoadingTasks || isInitialMount.current}
                onRowClick={handleTaskClick}
                onImmediateUpdate={updateTask}
                getRowKey={(task) => task.id}
                clients={clients || []}
                projects={projects || []}
                organizationUsers={organizationUsers || []}
                taskStatuses={taskStatuses || []}
                selectedTaskId={selectedTaskId}
                onTasksUpdate={handleTasksUpdate}
                containerRef={containerRef}
                emptyState={taskEmptyState}
                onDeleteTask={handleDeleteTask}
              />
            )}

            {activeTab === "time-entries" && timeEntryTableContent}
          </TabContent>
        </Section>
      </PageContent>

      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={setIsExpenseDrawerOpen}
        expense={editingExpense || undefined}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        projectId={id}
        onCreate={handleCreateExpense}
        organizationId={organizationId}
        defaultProjectId={id}
      />

      <TaskDrawer
        isOpen={isTaskDrawerOpen}
        setIsOpen={setIsTaskDrawerOpen}
        title="Task Details"
        selectedTask={localTasks.find(t => t.id === selectedTaskId) || null}
        onOverlayClick={handleCloseTaskDrawer}
      >
        {selectedTaskId && (
          <TaskDetails
            key={selectedTaskId}
            task={localTasks.find(t => t.id === selectedTaskId) || null}
            updateTask={updateTask}
            updateLocalTask={updateLocalTask}
            clients={clients}
            projects={projects || []}
            organizationUsers={organizationUsers || []}
            taskStatuses={taskStatuses || []}
            onPopoverOpenChange={handleDatePickerOpenChange}
            onDeleteTask={handleDeleteTask}
          />
        )}
      </TaskDrawer>

      <AddTimeEntryDrawer
        isOpen={isTimeEntryDrawerOpen}
        setIsOpen={setIsTimeEntryDrawerOpen}
        timeEntry={timeEntries?.find(entry => entry.id === selectedTimeEntryId) || null}
        onSave={handleSaveTimeEntry}
        onDelete={() => selectedTimeEntryId && handleDeleteTimeEntry(selectedTimeEntryId)}
        onCreate={handleCreateTimeEntry}
        organizationId={organizationId}
        defaultProjectId={id}
        defaultClientId={localProject?.client_id || undefined}
        onOverlayClick={() => setIsTimeEntryDrawerOpen(false)}
        stopTimer={stopTimer}
        updateLocalTimeEntry={updateLocalTimeEntry}
        currentTimeEntryId={currentTimeEntryId}
        pauseTimer={pauseTimer}
        resumeTimer={resumeTimer}
      />

      <InvoiceDrawer
        isOpen={isInvoiceDrawerOpen}
        setIsOpen={setIsInvoiceDrawerOpen}
        selectedInvoice={selectedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        title="Invoice Details"
      >
        {selectedInvoice && (
          <InvoiceDetails
            key={selectedInvoice.id}
            invoice={selectedInvoice}
            clients={clients || []}
            navigate={navigate}
            queryClient={queryClient}
            payments={[]} // You might need to fetch payments for the project
            emailTrackingData={emailTrackingData}
          />
        )}
      </InvoiceDrawer>

      <SelectedTimeEntriesOverlay
        selectedCount={selectedEntriesCount}
        clients={clients || []}
        projects={projects || []}
        onClientChange={handleBulkClientChange}
        onProjectChange={handleBulkProjectChange}
        onDelete={handleBulkDelete}
        onClearSelection={() => {
          setSelectedEntries([]);
          setSelectedEntriesCount(0);
        }}
        onCreateInvoice={handleCreateInvoice}
      />
    </ProjectPageWrapper>
  );
};

export default ProjectPage;
