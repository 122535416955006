import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { TimeEntry } from '../types';

export const useCreateTimeEntryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newTimeEntry: Partial<TimeEntry>) => {
      const { data, error } = await supabase
        .from('time_entries')
        .insert(newTimeEntry)
        .select()
        .single();

      if (error) throw error;
      return data as TimeEntry;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['timeEntries', data.id], data);
      queryClient.invalidateQueries({ queryKey: ['timeEntries'] });
    },
    onError: (error) => {
      console.error('Error creating time entry:', error);
    },
  });
};