import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { useClients } from './hooks/useClients';
import { useOrganization } from './hooks/useOrganization';
import { useInvoices } from './hooks/useInvoices';
import NewClientDrawer from './components/Clients/AddClientDrawer';
import Button from './components/Button';
import Tabs from './components/Tabs';
import { useNavigate } from '@tanstack/react-router';
import { Card, CardContent, CardTitle, CardItem } from './components/Card';
import EmptyStateCard from './components/EmptyStateCard';
import { usePageContext } from './hooks/usePageContext';

const ClientListWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 60px);
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 32px 40px;
  
`;

const ClientList: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const [drawerState, setDrawerState] = useState<{ isOpen: boolean; type: 'new' | 'edit' | null }>({ isOpen: false, type: null });
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState<'active' | 'inactive'>('active');
  const navigate = useNavigate();

  const { data: organizationId } = useOrganization();
  const { data: clients, isLoading: isLoadingClients } = useClients();
  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();

  const tabCounts = useMemo(() => {
    if (!clients) return { active: 0, inactive: 0 };
    return clients.reduce((acc, client) => {
      if (client.is_active) {
        acc.active++;
      } else {
        acc.inactive++;
      }
      return acc;
    }, { active: 0, inactive: 0 });
  }, [clients]);

  const tabs = useMemo(() => [
    { id: 'active', label: 'Active', count: tabCounts.active },
    { id: 'inactive', label: 'Inactive', count: tabCounts.inactive },
  ], [tabCounts]);

  const prefetchClientDetails = useCallback((clientId: string) => {
    queryClient.prefetchQuery({
      queryKey: ['client', clientId],
      queryFn: async () => {
        const client = clients?.find(c => c.id === clientId);
        if (client) return client;
        throw new Error('Client not found');
      },
      staleTime: 60000, // 1 minute
    });
  }, [queryClient, clients]);

  const prefetchClientInvoices = useCallback((clientId: string) => {
    queryClient.prefetchQuery({
      queryKey: ['clientInvoices', clientId],
      queryFn: async () => {
        return invoices?.filter(invoice => invoice.client_id === clientId) || [];
      },
      staleTime: 60000, // 1 minute
    });
  }, [queryClient, invoices]);

  const clientsWithInvoices = useMemo(() => {
    if (!clients) return [];
    
    return clients
      .filter(client => activeTab === 'active' ? client.is_active : !client.is_active)
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [clients, activeTab]);

  const handleClientAdded = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['clients', organizationId] });
    setDrawerState({ isOpen: false, type: null });
  }, [queryClient, organizationId]);

  const handleClientClick = useCallback((clientId: string) => {
    prefetchClientDetails(clientId);
    prefetchClientInvoices(clientId);
    navigate({ to: '/client/$id', params: { id: clientId } });
  }, [navigate, prefetchClientDetails, prefetchClientInvoices]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Clients",
      right: (
        <Button buttonType="primary" onClick={() => setDrawerState({ isOpen: true, type: 'new' })}>
          New Client
        </Button>
      ),
    });
  }, [setPageHeaderProps]);

  if (isLoadingClients || isLoadingInvoices) return "";

  return (
    <ClientListWrapper>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={(tabId) => setActiveTab(tabId as 'active' | 'inactive')}
      />
      <PageGrid>
        {clientsWithInvoices.length > 0 ? (
          clientsWithInvoices.map((client) => (
            <Card
              key={client.id}
              onClick={() => handleClientClick(client.id)}
              onMouseEnter={() => {
                prefetchClientDetails(client.id);
                prefetchClientInvoices(client.id);
              }}
            >
              <CardContent>
                <CardTitle>{client.full_name}</CardTitle>
                <CardItem>{client.company}</CardItem>
              </CardContent>
            </Card>
          ))
        ) : (
          <EmptyStateCard
            title="Create a new client"
            description="Add your first client to get started"
            actionText="Click here to create a new client"
            onClick={() => setDrawerState({ isOpen: true, type: 'new' })}
          />
        )}
      </PageGrid>
      <NewClientDrawer
        isOpen={drawerState.isOpen && drawerState.type === 'new'}
        setIsOpen={(isOpen) => setDrawerState({ isOpen, type: isOpen ? 'new' : null })}
        onClientAdded={handleClientAdded}
      />
    </ClientListWrapper>
  );
};

export default ClientList;
