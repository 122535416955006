import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTimeTracking } from '../../hooks/useTimeTracking';
import { useOrganizationClients } from '../../hooks/useOrganizationClients';
import { useOrganizationProjects } from '../../hooks/useOrganizationProjects';
import { useServices } from '../../hooks/useServices';
import TimeTrackerPopover from './TimeTrackerPopover';
import { Play8, Pause8 } from '../Icon';

const TimeTrackerWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 2px;
  color: rgba(0, 0, 0, 0.5);
`;

const StyledButton = styled.button`
  background-color: transparent;
  padding: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  &:active {
    transform: scale(0.95);
  }
`;

const ButtonCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    border: 1.5px solid rgba(0, 0, 0, 0.8);

    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const TimeTracker: React.FC = React.memo(() => {
  const { 
    isRunning, 
    isPaused,
    elapsedTime, 
    startTimer, 
    stopTimer, 
    updateTimer, 
    description, 
    setDescription,
    cancelTimer,
    deleteTimeEntry,
    pauseTimer,
    resumeTimer,
    isBillable,
    setIsBillable,
    currentTimeEntryId,
    resetState
  } = useTimeTracking();
  const { data: clients } = useOrganizationClients();
  const { data: projects } = useOrganizationProjects();
  const { data: services } = useServices();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleQuickAction = useCallback(() => {
    if (!isRunning && !isPaused) {
      startTimer(null, null, null, '', 0, isBillable);
    } else if (isPaused) {
      resumeTimer();
    } else {
      pauseTimer();
    }
  }, [isRunning, isPaused, startTimer, resumeTimer, pauseTimer, isBillable]);

  return (
    <TimeTrackerWrapper>
      <StyledButton onClick={handleQuickAction}>
        <ButtonCircle>
          {!isRunning ? <Play8 /> : <Pause8 />}
        </ButtonCircle>
      </StyledButton>
      <TimeTrackerPopover
        isOpen={isPopoverOpen}
        setIsOpen={setIsPopoverOpen}
        isRunning={isRunning}
        isPaused={isPaused}
        elapsedTime={elapsedTime}
        startTimer={startTimer}
        stopTimer={stopTimer}
        updateTimer={updateTimer}
        cancelTimer={cancelTimer}
        deleteTimeEntry={deleteTimeEntry}
        pauseTimer={pauseTimer}
        resumeTimer={resumeTimer}
        clients={clients || []}
        projects={projects || []}
        services={services || []}
        description={description}
        setDescription={setDescription}
        isBillable={isBillable}
        setIsBillable={setIsBillable}
        currentTimeEntryId={currentTimeEntryId}
        resetState={resetState}
      />
    </TimeTrackerWrapper>
  );
});

export default TimeTracker;
