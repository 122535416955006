import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';
import { Organization, Client } from '../types';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  padding: 56px 80px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
`;

const InvoiceTitle = styled.h1`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const DetailItemHorizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
  height: 24px;
`;

const Input = styled.input`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  height: 27px;
  box-sizing: border-box;
  margin: 0;
  font-size: inherit;
  color: inherit;
  border-radius: 8px;
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  &::placeholder {
    color: inherit;
  }
  &:hover {
    background: rgba(0,0,0,0.05);
  }
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

const Logo = styled.img`
  max-width: 125px;
  max-height: 125px;
  object-fit: contain;
  margin-right: 20px;
`;

const TopLeftItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TopRightItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`;

const BottomLeftItem = styled.div`
  margin-top: 64px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 56px;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  grid-column: 1 / 3;
`;

const OrganizationAddress = styled.div`
  white-space: pre-line;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  margin-left: -8px;
  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalLabel = styled.span`
  margin-right: 4px;
`;

const StyledAnimatedAmount = styled.div`
  margin-left: 8px;
  height: 21px;
  font-size: inherit;
  display: flex;
  align-items: center;
`;

const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  line-height: 16px;
`;

// Update the props interface
interface CompactHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
}

export const CompactHeader: React.FC<CompactHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization, // Add this prop
  showLogo, // Add this prop
  onOpenUpdateOrganizationDrawer // Add this prop
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${width + 24}px`); // Add 8px for padding
    }
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      `${org.city}${org.state ? `, ${org.state}` : ''} ${org.postal_code}`,
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  return (
    <FontWrapper $font={invoice.font || 'Arial'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
          <TopLeftItem>
            <InvoiceTitle>Invoice</InvoiceTitle>
          </TopLeftItem>
          <TopRightItem>
            {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
          </TopRightItem>
          <BottomLeftItem>
            <DetailItem>
              <Label>To</Label>
              <ClientSelectorComponent
                clients={clients}
                selectedClientId={invoice.client_id || null}
                onSelectClient={handleClientSelection}
                disabled={isReadOnly}
                onCreateNewClient={onCreateNewClient}
                headerTextColor={invoice.header_text_color}
              />
            </DetailItem>
            {organization && (
              <DetailItem>
                <Label>From</Label>
                <OrganizationAddress onClick={onOpenUpdateOrganizationDrawer}>
                  {getFormattedOrganizationAddress(organization)}
                </OrganizationAddress>
              </DetailItem>
            )}
            <ItemGroup>
            <Label>Details</Label>
            <DetailsList>
            <DetailItemHorizontal>
              <HorizontalLabel>Amount</HorizontalLabel>
              <StyledAnimatedAmount>
                <AnimatedAmount value={totals.amountDue} />
              </StyledAnimatedAmount>
            </DetailItemHorizontal>
            <DetailItemHorizontal>
              <HorizontalLabel>Invoice</HorizontalLabel>
              <Input
                type="text"
                value={localInvoiceNumber}
                onChange={handleInvoiceNumberChange}
                placeholder="Enter a #"
                readOnly={isReadOnly}
                width={inputWidth}
              />
              <HiddenSpan ref={hiddenSpanRef}>{localInvoiceNumber || 'Invoice #'}</HiddenSpan>
            </DetailItemHorizontal>
            <DetailItemHorizontal>
              <HorizontalLabel>Issued</HorizontalLabel>
              <DatePicker
                id="invoice-date"
                label=""
                selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                onChange={handleInvoiceDateChange}
                disabled={isReadOnly}
                isInvoiceDate={true}
                customFontSize={true}
              />
            </DetailItemHorizontal>
            <DetailItemHorizontal>
              <HorizontalLabel>Due</HorizontalLabel>
              <DatePicker
                id="due-date"
                label=""
                selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
                onChange={handleDueDateChange}
                disabled={isReadOnly}
                onSetDueDate={handleDueDaysChange}
                dueDays={invoice.due_days ?? 0}
                invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                customFontSize={true}
              />
            </DetailItemHorizontal>
            </DetailsList>
            </ItemGroup>
          </BottomLeftItem>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};